import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


export default function NotFoundPage() {
    const navigate = useNavigate();
    const { privileges, role, isFetched } = useSelector((state) => state.privileges);

    const hasSetup = privileges.menu_items.some((item) => 
        item.startsWith("setup:") ||  item.includes("*")
        
    );
    
    
    return (
        <div className='flex justify-center items-center flex-col h-screen'>
            <img src='/Images/404-error-page.svg' alt='icon' loading='lazy' />
            <p className='text-base text-[#29275F] font-semibold pt-8 pb-12 text-center'>
                Sorry, this page is not available. <br/> 
                The link you followed may be broken, or the page may have been removed.</p>
            <button
                onClick={()=>navigate(`${hasSetup ? '/setup' : '/profile'}`)}
                type="button"
                className="bg-[#29275F] rounded-lg text-[#FCFBFE] font-bold text-[18px] w-[250px] py-3 
                hover:bg-[#4B42A3] flex justify-center items-center">
                <img src='/Images/Frame.svg' alt='icon' loading='lazy' className='mr-2.5' />
                {hasSetup ? "Go to setup page" : "Go to profile"}
            </button>
        </div>
    );
}
