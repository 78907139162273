import React from 'react';

export default function Dashboard() {
    return (
        <div className='flex justify-center items-center h-full'>
            <div className="rounded-xl">
                <div className='flex justify-center items-center h-full'>
                    <p className='text-[#000000] font-bold text-[64px]'>Setup</p>
                </div>
            </div>
        </div>
    );
}
