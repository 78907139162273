import React from 'react'
import { Drawer } from "@material-tailwind/react";

export default function DrawerSideBar({isOpen , closeDrawerRight, tittle, children}) {
    return (
        <Drawer
            placement="right"
            open={isOpen}
            onClose={closeDrawerRight}
            className={`p-4 drawer-modal ${isOpen?'show':'close'}`}
        >
            <div className="text-[#2F2E38] text-[24px] font-bold pb-[26px] pt-10 flex justify-between items-start
                             w-full">
                {tittle}
                <img src='/Images/close-icon.svg' alt='icon' loading='lazy' 
                    onClick={()=>closeDrawerRight()} 
                    className='cursor-pointer pt-1' />
            </div>
            {children}
        </Drawer>
    )
}
