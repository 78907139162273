/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import OTPInput from "react-otp-input";
import { RotatingLines } from 'react-loader-spinner'
import GlobalContext from '../../../CommonComponent/context';
import AnimatedPage from '../../../CommonComponent/AnimatedPage';

export default function OtpForm({ setOTP, setOtpError, otpError, submitOtp, setIsOTPResent, isOTPResent, otp, handleSubmit, loader, successLogin }) {

    const { setToastmessage } = useContext(GlobalContext)
    const [timer, setTimer] = useState(10 * 3);

    /* The `useEffect` hook in the provided code snippet is responsible for creating a timer
    functionality for the OTP form component. Here's a breakdown of what it does: */
    useEffect(() => {
        let intervalId;
        if (timer > 0) {
            intervalId = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);
        }
        if (timer === 0) {
            setIsOTPResent(true);
        }
        return () => clearInterval(intervalId);
    }, [timer]);

    return (
        <AnimatedPage>
            <div className="bg-[#FFFFFF]">
                <form onSubmit={(e) => submitOtp(e)} className='w-full'>
                    <h1 className="text-[#2F2E38] text-[32px] pt-6 font-bold">
                        Verify your Account
                    </h1>
                    <p className="text-[16px] text-[#2F2E38] font-semibold mt-4 mb-[24px]">
                        Please enter the OTP sent to your email
                    </p>
                    <div className="flex justify-center flex-col mb-6">
                        <OTPInput
                            value={otp}
                            onChange={(e) => {
                                if (/^[0-9]*$/.test(e) || e === "") {
                                    setOTP(e);
                                    setOtpError("");
                                }
                            }}
                            numInputs={6}
                            isInputNum={true}
                            shouldAutoFocus={true}
                            renderSeparator={<span className="w-5"></span>}
                            renderInput={(props, index) => (
                                <input
                                    className="otp-input"
                                    {...props}
                                    id={`digit-${index + 1}`}
                                />
                            )}
                            inputStyle={{
                                width: "50px",
                                height: "50px",
                                fontSize: "20px",
                                borderRadius: "6px",
                                outline: "0",
                                border:
                                    otpError !== "" ? "2px solid #EA3829" : successLogin ? "2px solid #008F5D" : "2px solid #CCC9E9",
                            }}
                        />
                        {otpError !== '' && <p className="text-[#EA3829] text-base font-normal pt-4 text-center">
                            {otpError}
                        </p>}
                    </div>
                    <button
                        data-testid="submit-otp-btn"
                        type="submit"
                        disabled={otp.length !== 6 || loader}
                        className={`${successLogin ? 'bg-[#B8B8C8]' : 'bg-[#29275F]'} rounded-lg text-[#FCFBFE] font-bold text-[18px] disabled:bg-[#CCC9E9] w-full py-3 hover:bg-[#4B42A3] flex justify-center items-center`}
                    >
                        {successLogin ? 'Verified' : 'Verify'}
                        {loader && (
                            <>
                                <span className='pr-5'></span>
                                <RotatingLines
                                    strokeColor="#FFFFFF"
                                    strokeWidth="5"
                                    animationDuration="0.55"
                                    width="25"
                                    visible={true}
                                />
                            </>
                        )}
                    </button>
                    <div className="flex justify-center items-center pt-[34px]">
                        <button
                            disabled={loader || successLogin}
                            type="button"
                            className={`text-[#29275F] text-[18px] font-bold ${isOTPResent? "cursor-pointer" : "cursor-auto"}`}
                            onClick={isOTPResent ? (e) => {
                                handleSubmit(e); setIsOTPResent(false); setTimer(10 * 3);
                                setToastmessage('OTP resent successfully')
                            } : null}>
                            {!isOTPResent ? <span>
                                <span className='font-normal'>Resend OTP in </span>
                                <span className='font-bold'>00:{timer < 10 ? `0${timer}` : timer}</span>
                            </span> : "Resend OTP"}
                        </button>
                    </div>
                </form>
            </div>
        </AnimatedPage>

    );
}
