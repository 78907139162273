/* eslint-disable max-len */
import Lottie from 'lottie-react';
import React, { useState, useRef, useEffect, useContext } from 'react';
import blockImage from "../../CommonComponent/search_not_found.json";
import GlobalContext from '../../CommonComponent/context';

function CountrySelectDropdown({id, formInput, setFormInput, errorState, setErrorState, isBankCountry, isTaxCountry, countryList, setDefaultCountryId, setCreateLoading, isCreateLoading,setInputFieldLoader,setSelectedOption,setRenderkey,renderkey }) {

    let extractedData = countryList.map(({ country_name, flag, country_id, tax_year_start }) => ({ country_name, flag, country_id, tax_year_start }));
    let sortedData = extractedData.sort((a, b) => a.country_name.localeCompare(b.country_name)); // Sort countries alphabetically

    const [searchText, setSearchText] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [focused, setFocused] = useState(false);
    const [countryFocused, setCountryFocused] = useState(false)
    const dropdownRef = useRef(null);
    const [countryValues, setCountryValues] = useState(sortedData)
    const [filteredCountries, setFilteredCountries] = useState(sortedData)
    const currentYear = new Date().getFullYear();


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
                setCountryFocused(false)
                setRenderkey(true)
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleInputClick = () => {
        setRenderkey(true)   
        setCountryValues(sortedData)
        setFilteredCountries(sortedData)
        setSearchText('')
        setShowDropdown(!showDropdown);
        setCountryFocused(false)
        setFocused(true)
    };

    const handleSearchInputChange = (event) => {
        setSearchText(event.target.value);
        filterCountry(event.target.value)
    };

    const handleCountrySelect = (countryId, countryName, country_tax_year_start) => {
        setRenderkey(false)
        // setInputFieldLoader(true)
        setFormInput(prevState => ({
            ...prevState,
            ['country']: countryId,
            ['country_name']: countryName,
            ['start_date']: `${country_tax_year_start}/${currentYear}`,
            ['tax_method']: "",
            ['tax_method_name']:"",
        }));
        setSelectedOption(prevOptions => ({
            ...prevOptions,
            tax_method: null,
            tax_method_name: null,
        }));
        setDefaultCountryId(countryId)
        setSearchText('');
        setShowDropdown(false);
        setCountryFocused(false)
        if (isBankCountry) {
            setErrorState({
                payroll_name: "",
                payroll_type: "",
                country: "",
                country_name: "",
                no_of_periods: "",
                start_date: "",
                tax_method: "",
                tax_method_name: "",
                currency: "",
                currency_id: ""
            })
        }
        else if (isTaxCountry) {
            setErrorState({
                country: '',
                tax_method_name: '',
                default_tax_method: '',
                paye_equivalent: ''
            })
        }
        else {
            setErrorState({
                country: "",
                holiday_name: "",
                date: '',
                entered_by: ""
            })
        }
        setInputFieldLoader(true)
        setTimeout(() => {
            setInputFieldLoader(false)
            setRenderkey(true)
        }, 1000);
    };

    const filterCountry = (search) => {
        const trimmedSearch = search.trim().toLowerCase();
        let filteredValue = countryValues.filter(country =>
            country.country_name.toLowerCase().includes(trimmedSearch)
        );
        setFilteredCountries(filteredValue);
    };

    const getCountryNameById = (id) => {
        const country = countryList.find((country) => country.country_id == id);
        return country ? country.country_name : '';
    };

    return (
        <div className="country-select-dropdown mb-[30px] w-full" ref={dropdownRef}>
            <div className='relative'>
                <label
                    className={`absolute transition-all left-2 font-normal bg-white
                    ${errorState['country'] !== "" ? "text-[#EA3829]" : "text-[#2F2E38]"}
                    ${formInput.country !== '' ? "top-[-8px] text-xs px-[4px]" : "top-1/2 -translate-y-1/2 text-[14px] mx-[4px]"}`}
                >
                    Country<span className={`${errorState['country'] !== "" ? "text-[#EA3829]" : 'text-[#2F2E38]'}`}>*</span>
                </label>
                <div>
                    <input
                        type="text"
                        id={id}
                        data-testid="country-dropdown"
                        value={getCountryNameById(formInput.country)}
                        onClick={() => handleInputClick()}
                        className={`border text-[14px] font-normal rounded cursor-pointer
                        w-full py-3 px-3 outline-0 focus-visible:outline-0 input-no-text text-[#2F2E38]
                        ${errorState['country'] !== ""
            ? "border-[#EA3829]" :
            formInput['country'] !== '' || focused ? "border-[#5E5C70]" : "border-[#ACABBA]"}
                    `}
                    />
                    <div className={`absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer bg-white py-3`}
                        onClick={() => { handleInputClick() }}>
                        {!showDropdown ?
                            <img src='/Images/chevron-down.svg' alt='icon' loading='lazy' /> :
                            <img src='/Images/chevron-up.svg' alt='icon' loading='lazy' />}
                    </div>
                </div>
            </div>
            <p className="text-[#EA3829] text-xs font-normal pt-[2px] pl-3">
                {errorState['country']}
            </p>
            <div className='relative'>
                <div
                    className={`dropdown-container z-[11] absolute top-0 left-0 bg-white  w-full
                    border-b border-r border-l border-[#D6D5DD] ${showDropdown ? 'open opacity-100' :
            'closed opacity-0 max-h-0 overflow-hidden'}`}>
                    <div className="dropdown-content w-full relative mt-3">
                        <div className={`flex items-center border 
                        ${searchText !== '' || countryFocused ? "border-[#5E5C70]" : "border-[#ACABBA]"}
                            rounded py-2.5 px-3 shadow-s4 mx-3 mb-3 
                        sticky top-0 left-0 w-auto bg-white`}>
                            {searchText || countryFocused ?
                                <img src='/Images/search-hightight.svg' alt='icon' /> :
                                <img src='/Images/search.svg' alt='icon' />}
                            <input
                                type="text"
                                data-testid="add-search"
                                value={searchText}
                                onClick={() => setCountryFocused(true)}
                                onChange={handleSearchInputChange}
                                placeholder="Search"
                                className=' outline-0 w-full ml-2 placeholder:text-[#918FA3] text-[#2F2E38] text-sm font-normal'

                            />
                        </div>
                        <div className='h-[199px] overflow-auto customScrollbar mr-2.5'>
                            {filteredCountries?.length > 0 ? (
                                filteredCountries.map((country, i) => (
                                    <>
                                        <div
                                            key={`key-${i}`}
                                            data-testid={`country-dropdown-${i}`}
                                            onClick={() => handleCountrySelect(country.country_id, country.country_name, country.tax_year_start)}
                                            className="dropdown-item cursor-pointer text-sm font-normal 
                                            text-[#2F2E38] px-3 py-2.5 
                                    hover:bg-[#EEEDF8] flex items-center"
                                        >
                                            <p className='mr-2 w-[29px] h-5'>{country.flag}</p>
                                            {country.country_name}
                                        </div>
                                    </>
                                ))
                            ) : (
                                <div className="error-message h-full flex flex-col items-center justify-center">
                                    <Lottie
                                        animationData={blockImage}
                                        loop={true}
                                        className="w-[90px] mx-auto"
                                    />
                                    <p className='text-sm font-normal text-[#2F2E38] text-center pb-5 pt-3'>
                                        We could not find any matches for your search.</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CountrySelectDropdown;
