import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    user: '',
    loggedIn: false,
    userExist: false
};
const authSlice = createSlice({

    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        login: (state, action) => {
            state.loggedIn = action.payload;
        },
        logout: (state, action) => {
            localStorage.clear();
            state.loggedIn = false;
        },
        setUserExist: (state, action) =>{
            state.userExist = action.payload;
        }
    }
});

export const { setUser, login, logout, setUserExist } = authSlice.actions;
export default authSlice.reducer;
