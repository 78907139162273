/* eslint-disable max-len */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleCurrentPage, handlePagination } from '../Redux/paginationSlice';
import { handleSidebarAnimation } from '../Redux/commonSlice';


const MenuBox = ({ icon, title, description, path }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    let paginationData = {}
    const [isHover, setIsHover] = useState(false);
    return (
        <div
            className='menu-box flex flex-1 flex-grow hover:font-bold'
            onClick={()=>{dispatch(handlePagination(paginationData)), dispatch(handleCurrentPage(1)), dispatch(handleSidebarAnimation(false)),navigate(path)}}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <div className="bg-white rounded-lg p-6 border
             hover:border-[#4B42A3] border-[#918FA3] menu relative cursor-pointer min-h-[195px] 
            flex flex-col flex-grow">
                <div className="flex items-start justify-center flex-col">
                    <img src={`/Images/${icon}`} alt='icon' loading='lazy' className={`${isHover ? 'icon-active-image' : ''}`}/>
                    <h3 className={`text-base font-normal ${isHover ? 'text-[#3B347F]' : 'text-[#2F2E38]'} 
                    pt-2.5 pb-1`}>{title}</h3>
                </div>
                <p className="text-[#75738C] text-[12px] font-normal">{description}</p>
            </div>
        </div>
    );
};
export default MenuBox;
  
