import React, { useContext, useState } from 'react'
import InputWithLabel from '../../CommonComponent/inputField';
import { RotatingLines } from 'react-loader-spinner';
import DrawerSideBar from '../../CommonComponent/DrawerSideBar';
import { getValueTrim } from '../../CommonComponent/InputValidation';
import { useEffect } from 'react';
import Button from '../../CommonComponent/Button';
import { dataService } from '../../services/dataService';
import GlobalContext from '../../CommonComponent/context';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { useDispatch } from 'react-redux';
import { setUser } from '../auth/authSlice';
import endPoints from '../../services/endpoints';

export default function UpdateProfile({isUpdate, setIsUpdate, viewData, setViewLoading}) {
    const { setToastmessage,setToastErrorMessage } = useContext(GlobalContext)

    const InputItems = {
        first_name : {
            label : 'First name',
            required : true
        },
        last_name: {
            label : 'Last name',
            required : false
        }
    }
    const initialStates = {
        first_name: "",
        last_name : ""
    }
    const [formInput, setFormInput] = useState(initialStates)
    const [errorState, setErrorState] = useState(initialStates)
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        setFormInput({
            first_name: viewData.first_name,
            last_name : viewData.last_name
        })
        setErrorState(initialStates)
    }, [viewData, isUpdate])

    const handleChange  = (event) => {
        const {id, value} = event.target;
        setFormInput(prevState => ({
            ...prevState,
            [id] : getValueTrim(id, value),
        }));
        setErrorState((prevState) => ({
            ...prevState,
            [id] : "",
        }));
    }
    const getButtonDisabled = () => {
        return ((viewData.first_name !== formInput.first_name || viewData.last_name !== formInput.last_name) && !loader) ? 
            false : true
    }

    const handleUpdateButton = async () => {
        setLoader(true)
        if(formInput.first_name === "") {
            setErrorState((prevState) => ({
                ...prevState,
                first_name : "This field is mandatory",
            }));
            setLoader(false)
            return
        }
        try {
            const res = await dataService.patchAPI(`${endPoints.UserProfile}`, formInput)
            setViewLoading(true)
            setIsUpdate(false)
            setLoader(false)
            const userAttributes = await fetchUserAttributes();
            dispatch(setUser(userAttributes));
            setToastmessage('Profile updated successfully')

        } catch (error) {
            setLoader(false)
            setIsUpdate(false)
            setToastErrorMessage(error.message || 'Something went wrong.')
        }
    }
    return (
        <DrawerSideBar tittle={'Update Profile'} isOpen={isUpdate} closeDrawerRight={() => setIsUpdate(false)}>
            <form autoComplete='off'>
                {Object.keys(InputItems).map((item) => (
                    <div className='w-[309px] mr-6'key={item}>
                        <InputWithLabel
                            id={item}
                            label={InputItems[item].label}
                            type="text"
                            autocomplete="off"
                            required={InputItems[item].required}
                            // handlePasswordFocus={null}
                            handleChange={handleChange}
                            formInput={formInput}
                            errorState={errorState}
                            showPassword={true}
                            maxLength={30}
                        />
                    </div>))}
                <Button 
                    dataTestID={'update'}
                    disabled={getButtonDisabled()}
                    handleButtonClick={handleUpdateButton}
                    ButtonName={'Update'}
                    loader={loader}
                />
            </form>
        </DrawerSideBar>      
    )
}
