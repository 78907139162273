/* eslint-disable max-len */
import React  from 'react';
import InputWithLabel from '../../../CommonComponent/inputField';
import { RotatingLines } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom';
import AnimatedPage from '../../../CommonComponent/AnimatedPage';


export default function LoginForm({ handleSubmit, loader, setShowPassword,
    errorState, formInput, handleChange, showPassword, isFlip, handlePasswordFocus, handleRecaptchaChange, handlePassCaptcha }) {
    const navigate = useNavigate()


    return (
        <AnimatedPage>
            <div className="bg-[#FFFFFF]">
                <h1 className="text-[#2F2E38] text-[32px] pt-6 font-bold">Login</h1>
                <span className='w-[10px]' onClick={() => handlePassCaptcha()} id='recaptcha' data-test-id="recaptcha">     </span>
                <p className="text-[16px] text-[#2F2E38] font-semibold mt-5 mb-[34px]">
                    Please enter your login information
                </p>
                <form className='w-[400px]' autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
                    <InputWithLabel
                        id="email"
                        label="Email address"
                        type="text"
                        handleChange={handleChange}
                        formInput={formInput}
                        errorState={errorState}
                        required={true}
                        handlePasswordFocus={handlePasswordFocus}
                        showPassword={true}
                        
                    />
                    <div className="relative">
                        <InputWithLabel
                            id="password"
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            handleChange={handleChange}
                            formInput={formInput}
                            required={true}
                            errorState={errorState}
                            handlePasswordFocus={handlePasswordFocus}
                            showPassword={showPassword}
                        />
                        <img
                            src={
                                formInput.password.length == 0 ?
                                    (!showPassword ? '/Images/eye-open-inactive.svg' : '/Images/closed-eye-inactive.svg') :
                                    !showPassword
                                        ? "/Images/eye-open-active.svg"
                                        : "/Images/eye-off-active.svg"
                            }
                            alt="icon"
                            className="absolute top-[15px] right-2.5 cursor-pointer w-5"
                            onClick={() => setShowPassword(!showPassword)}
                            loading="lazy"
                        />
                    </div>
                    <div className="flex justify-end pb-6">
                        <button
                            disabled={loader}
                            data-testid='forgot-password-link'
                            onClick={() => navigate('/forgot-password')}
                            type="button"
                            className="text-base text-[#2F2E38] font-normal"
                        >
                            Forgot password?
                        </button>
                    </div>
                    {/* <div className='pb-[24px]'>
                        <ReCAPTCHA
                            sitekey="6LeVFeApAAAAACg2RaQTzpIlxGh09vgBYbEx0gTu"
                            onChange={handleRecaptchaChange}
                        />
                        {errorState.isRecaptcha !== '' &&
                            <p className="text-[#EA3829] text-sm font-normal pt-[2px]">
                                reCAPTCHA is mandatory
                            </p>}
                    </div> */}
                    <button
                        data-testid="login-btn"
                        type="submit"
                        disabled={loader}
                        className="bg-[#29275F] rounded-lg text-[#FCFBFE] font-bold text-[18px] w-full py-3 hover:bg-[#4B42A3] flex justify-center items-center disabled:bg-[#CCC9E9]"
                    >
                        Login
                        {loader && (
                            <>
                                <span className='pr-5'></span>
                                <RotatingLines
                                    strokeColor="#FFFFFF"
                                    strokeWidth="5"
                                    animationDuration="0.55"
                                    width="25"
                                    visible={true}
                                />
                            </>
                        )}
                    </button>
                </form>
            </div>
        </AnimatedPage>
    );
}
