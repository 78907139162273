/* eslint-disable security/detect-object-injection */
/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import { Suspense } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate, } from "react-router-dom";
import { fetchUserAttributes } from "aws-amplify/auth";
import { login, setUser, setUserExist } from "../Pages/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import CommonLoader from "../CommonComponent/commonLoader";
import NotFoundPage from "../CommonComponent/404Page";
import Layout from "../components/layout";
import Login from "../Pages/auth/LoginForm";
import ForgotPassword from "../Pages/auth/ForgotPassword";
import ResetPassword from "../Pages/auth/ResetPassword";
import ForceResetPassword from "../Pages/auth/ForceResetPassword ";
import privilegeAccess from "./PrivilegeRouteComponent";
import { dataService } from "../services/dataService";

export default function NavigationRoutes(props, awsConfig) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useSelector((state) => state.auth);
    const [userAccess, setUserAccess] = useState({})
    const [userRole, setUserRole] = useState({})
    const { userExist } = auth;
    const { loggedIn } = auth;
    const { privileges, role, isFetched } = useSelector((state) => state.privileges);
    const [pageLoading, setPageLoading] = useState(true);

    /**
     * The function `checkLoggedInUser` asynchronously fetches user attributes, updates the user state
     * and login status based on the response, and handles errors by updating the user state and logging
     * out.
     */
    const checkLoggedInUser = async () => {
        setPageLoading(true)
        try {
            const userAttributes = await fetchUserAttributes();
            setUserRole(userAttributes['custom:user_type'])
            const response = await dataService.getAPI('user-management/privileges/');
            setUserAccess(response.data.data)
            dispatch(setUserExist(true))
            setPageLoading(false);
            if (userAttributes["custom:is_reset_password"] !== 'false') {
                dispatch(setUser(userAttributes));
                dispatch(login(true));
            }
        } catch (error) {
            dispatch(setUserExist(false))
            setPageLoading(false);
            dispatch(setUser(''));
        }
    };


    /* The `useEffect` hook in the code snippet is responsible for executing the `checkLoggedInUser`
      function when the `loggedIn` state changes. */
    useEffect(() => {
        if (awsConfig) {
            checkLoggedInUser();
        }
    }, [loggedIn, awsConfig]);

    /* The `useEffect` hook you provided is responsible for handling navigation based on certain
      conditions. Here's a breakdown of what it does: */
    useEffect(() => {
        if (!pageLoading && !loggedIn && (window.location.pathname !== '/forgot-password' &&
            window.location.pathname !== '/reset-password' && window.location.pathname !== '/force-reset-password')) {
            navigate('/');
        }
        else if (!pageLoading && loggedIn && window.location.pathname === '/') {
            navigate('/setup');
        }
        else if (!userExist && window.location.pathname == '/force-reset-password' && loggedIn) {
            navigate('/')
        }
        else if (window.location.pathname == '/force-reset-password' && loggedIn) {
            navigate('/page-not-found')
        }
    }, [pageLoading]);



    return (
        <Suspense fallback={<div></div>}>
            <Routes location={location} key={location.pathname}>
                {pageLoading
                    ? <Route path="*" element={<CommonLoader />} />
                    :
                    <>
                        {!loggedIn ?
                            <>
                                <Route path="/" element={
                                    <Login />
                                } />
                                <Route path="/forgot-password" element={<ForgotPassword />} />
                            </>
                            :
                            <>
                                <Route element={<Layout {...props} />}>
                                    {/* {privilageAccess()} */}
                                    {privilegeAccess(userRole, userAccess).map((routElement) => (
                                        <>
                                            <Route path={routElement.path} element={routElement.element} key={routElement.path} />
                                        </>
                                    ))}
                                    {/* <Route path="/profile" element={<Profile />} />
                                    <Route path="/setup" element={<Setup />} />
                                    <Route path="/change-password" element={<ChangePassword />} />
                                    <Route path="/user-management/users" element={<UserManagement />} />
                                    <Route path="/user-management/users/add" element={<AddUser />} />
                                    <Route path="/user-management/users/edit/:id" element={<AddUser />} />
                                    <Route path="/user-group" element={<UserManagement />} />
                                    <Route path="/user-group/add" element={<AddUserGroup />} />
                                    <Route path="/user-group/edit/:id" element={<AddUserGroup />} />
                                    <Route path="/payrolls" element={<PayrollsListing />} />
                                    <Route path="/create-payroll" element={<CreatePayroll />} /> */}
                                    {/* <Route path="/setup/currencies/:tab" element={
                                        <PrivilegeRoute
                                            element={<Currencies />}
                                            requiredPrivilege="setup:currency_conversion"
                                        />
                                    } /> */}
                                </Route>
                                <Route path="*" element={<NotFoundPage />} />
                            </>
                        }
                        <Route path="/force-reset-password" element={
                            localStorage.getItem('custom:is_reset_password') === "false" ? (
                                <ForceResetPassword />
                            ) : (
                                <Navigate to="/" replace />
                            )} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                    </>
                }
            </Routes>
        </Suspense>
    );
}