/* eslint-disable max-len */
import React from 'react';


const UserGroupCheckboxes = ({ items, setFormInput, formInput, setToastWarningMessage}) => {
    const handleCheckboxChange = (itemId) => {
        const itemKey = `${itemId}`;

        setFormInput(prevState => {
            let newGroups;
            if (prevState.groups.includes(itemKey)) {
                newGroups = prevState.groups.filter(item => item !== itemKey);
            } else {
                newGroups = [...prevState.groups, itemKey];
            }
        
            if (newGroups.length <= 20) {
                return {
                    ...prevState,
                    groups: newGroups,
                };
            } else {
                setToastWarningMessage('Maximum of 20 user groups allowed.')
                return prevState; // Return the previous state if the condition is not met
            }
        });
        
    };

    const handleParentCheckboxChange = (checked) => {
        setFormInput(prevState => ({
            ...prevState,
            groups: checked ? items.map(item => `${item.group_id}`) : [],
        }));
    };

    const isAllSelected = formInput.groups.length === items.length &&
        items.every(item => formInput.groups.includes(`${item.group_id}`));

    return (
        <>
            <div className="grid grid-cols-3 grid-auto-rows-min-content customScrollbar overflow-auto h-[calc(100vh-580px)]" style={{ gridAutoRows: 'min-content' }}>
                {
                    items.map(item => (
                        <div key={item.group_id} className="checkbox relative p-2 flex">
                            <label htmlFor={`${item.group_id}`} className="pl-[25px] cursor-pointer text-[#2F2E38] 
                            text-base font-normal w-full break-words">
                                {item.group_name}
                                <input
                                    data-testid={`${item.group_name}`}
                                    className="checkbox-input custom-checkbox-input"
                                    type="checkbox"
                                    id={`${item.group_id}`}
                                    checked={formInput.groups.includes(`${item.group_id}`)}
                                    onChange={() => handleCheckboxChange(item.group_id)}
                                />
                            </label>
                        </div>
                    ))}
            </div>
        </>
    );
};

export default UserGroupCheckboxes;
