/* eslint-disable max-len */
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useLocation, useNavigate } from 'react-router-dom';

export default function UserGroupTable({ userGroupList, setIsCloneOpen, setSelectedUserGroup, handleDeleteModal }) {
    const navigate = useNavigate()
    const location = useLocation()

    const [isHoverClone, setIsHoverClone] = useState({
        index: '',
        hover: false
    })
    const [isHoverDelete, setIsHoverDelete] = useState({
        index: '',
        hover: false
    })
    const [isHoverEdit, setIsHoverEdit] = useState({
        index: '',
        hover: false
    })

    const animations = {
        initial: { opacity: 0, x: -10 },
        animate: { opacity: 2, x: 0 },
        exit: { opacity: 0, x: 100 }
    }
    return (
        <tbody className='border-l border-r border-[#F1F1F4]'>
            {userGroupList.length > 0 && userGroupList?.map((userGroup, index) =>
                <motion.tr
                    key={index}
                    id="list-items" variants={animations}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 1 }}
                    className="border-b border-[#F1F1F4] h-[45px]">
                    <td data-testid={`group_name${index}`} id={`group_name${index}`} className="px-6 text-[14px] leading-5 text-[#2F2E38] font-normal">
                        {userGroup.group_name}
                    </td>
                    <td className=" px-6 text-[14px] leading-5 text-[#2F2E38] font-normal break-all max-w-[180px]">
                        {userGroup.group_description}
                    </td>
                    <td className=" text-[14px] leading-5 text-[#2F2E38] font-normal min-w-[200px]">
                        <div className='flex items-center justify-center'>
                            <div className='mr-5 relative'
                                onMouseOver={() => setIsHoverEdit({
                                    index: index,
                                    hover: true
                                })}
                                onMouseLeave={() => setIsHoverEdit({
                                    index: index,
                                    hover: false
                                })}

                            >
                                <img
                                    src={`${isHoverEdit.index == index &&
                                        isHoverEdit.hover ? '/Images/edit-action.svg' : '/Images/Icon.svg'}`}
                                    alt='icon' loading='lazy' data-tooltip-id="edit"
                                    data-testid={`edit-btn-${index}`}
                                    className='cursor-pointer' onClick={() => navigate(`/user-group/edit/${userGroup.group_id}`, { state: { from: location } })} />
                                <ReactTooltip
                                    id="edit"
                                    place="left"
                                    variant="info"
                                    content="Update"
                                    noArrow={true}
                                    style={{
                                        backgroundColor: "#5E5C70",
                                        color: "#F1F1F4",
                                        height: '26px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderRadius: '4px',
                                        zIndex: '99'
                                    }}
                                />
                            </div>
                            <div className='mr-5 relative'
                                onMouseOver={() => setIsHoverDelete({
                                    index: index,
                                    hover: true
                                })}
                                onMouseLeave={() => setIsHoverDelete({
                                    index: index,
                                    hover: false
                                })}
                                onClick={() => handleDeleteModal(userGroup)}>
                                <img 
                                    src={`${isHoverDelete.index == index &&
                                    isHoverDelete.hover ? '/Images/delete-action.svg' : '/Images/trash-2.svg'}`}
                                    alt='icon' loading='lazy' data-tooltip-id="delete"
                                    className='cursor-pointer' data-testid={`delete-btn-${index}`}
                                />
                                <ReactTooltip
                                    id="delete"
                                    place="left"
                                    variant="info"
                                    noArrow={true}
                                    content="Delete"
                                    style={{
                                        backgroundColor: "#5E5C70",
                                        color: "#F1F1F4",
                                        height: '26px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderRadius: '4px'
                                    }}
                                />
                            </div>
                            <div className='relative'
                                onMouseOver={() => setIsHoverClone({
                                    index: index,
                                    hover: true
                                })}
                                onMouseLeave={() => setIsHoverClone({
                                    index: index,
                                    hover: false
                                })}>
                                <img 
                                    src={`${isHoverClone.index == index &&
                                    isHoverClone.hover ? '/Images/clone.svg' : '/Images/copy.svg'}`}
                                    alt='icon' loading='lazy' data-tooltip-id="clone"
                                    className='cursor-pointer' data-testid={`clone${index}`}
                                    onClick={() => {
                                        setSelectedUserGroup({ id: userGroup.group_id, name: userGroup.group_name });
                                        setIsCloneOpen(true)
                                    }} />
                                <ReactTooltip
                                    id="clone"
                                    place="left"
                                    variant="info"
                                    content="Clone"
                                    noArrow={true}
                                    style={{
                                        backgroundColor: "#5E5C70",
                                        color: "#F1F1F4",
                                        height: '26px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderRadius: '4px'
                                    }}
                                />
                            </div>
                        </div>
                    </td>
                </motion.tr>
            )}
        </tbody>
    );
}
