/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PasswordInputWithLabel from '../../../CommonComponent/passwordFields';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { motion } from 'framer-motion'
import { RotatingLines } from 'react-loader-spinner';
import { fetchUserAttributes } from 'aws-amplify/auth';


export default function ForceConfirmPassword({ clickHandler,
    passwordError, password, confirmPassword, showPassword, showConfirmPassword, showFormate,
    loader, setShowPassword, setShowConfirmPassword, handlePasswordChange, passwordValid, showCurrentPassword, setShowCurrentPassword, currentPassword, setCurrentPassword }) {

    let animationProps = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { duration: 0.5 } },
    };

    const [attributes, setAttributes] = useState({})

    async function getUserAttributes() {
        try {
            const userAttributes = await fetchUserAttributes();
            setAttributes(userAttributes)
            // Use the userAttributes here
        } catch (error) {
            // Handle any errors here
            console.error('Error fetching user attributes:', error);
        }
    }


    useEffect(() => {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const token = params.get('token');
        if (!token) {
            getUserAttributes();
        }
    }, [])


    return (
        <>
            <div className='w-full flex'>
                <div className="bg-[url(/Images/login-bg.svg)] bg-cover bg-no-repeat h-screen xl:w-[52%] w-1/2">
                    <img src='/Images/logo-withName.svg' alt='icon' className="p-[32px]" loading='lazy'/>
                </div>
                <div className="w-1/2 flex justify-center items-center">
                    <motion.div {...animationProps} className="bg-[#FFFFFF] w-[400px]">
                        <h1 className="text-[#2F2E38] text-[32px] pt-6 font-bold">Reset Password</h1>
                        <p className="text-[16px] text-[#2F2E38] font-semibold mt-5 mb-[34px]">
                            Please reset your password
                        </p>
                        <form autoComplete="off">
                            <div className={`relative ${currentPassword?'mb-10':'mb-[30px]'}`}>
                                <PasswordInputWithLabel
                                    id="current_password"
                                    label="Temporary password"
                                    type={showCurrentPassword ? "text" : "password"}
                                    handlePasswordChange={handlePasswordChange}
                                    password={currentPassword}
                                    passwordError={passwordError == 'This field is mandatory' && currentPassword.length > 0 || passwordError == 'Current and new passwords must differ' || passwordError == 'Passwords do not match' ? null : passwordError}
                                    errorState={((passwordError == 'This field is mandatory' && currentPassword.length == 0) || passwordError == 'Current and new passwords must differ' || passwordError === 'Incorrect password') ? true : false}
                                    showPassword={showCurrentPassword}
                                />
                                <img
                                    src={
                                        currentPassword.length == 0 ?
                                            (!showCurrentPassword ? '/Images/eye-open-inactive.svg' : '/Images/closed-eye-inactive.svg') :
                                            !showCurrentPassword
                                                ? "/Images/eye-open-active.svg"
                                                : "/Images/eye-off-active.svg"
                                    }
                                    alt="icon"
                                    loading='lazy'
                                    className="absolute top-[15px] right-2.5 cursor-pointer w-5"
                                    onClick={() => setShowCurrentPassword(!showCurrentPassword)} />
                            </div>
                            <div className={`relative ${password?'mb-10':'mb-[30px]'}`}>
                                <PasswordInputWithLabel
                                    id="new_password"
                                    data-testid="new_password"
                                    label="New password"
                                    type={showPassword ? "text" : "password"}
                                    handlePasswordChange={handlePasswordChange}
                                    password={password}
                                    passwordError={passwordError == 'This field is mandatory' && password.length > 0 ||
                                        passwordError == 'Passwords do not match' || passwordError == 'Incorrect password' ? null : passwordError}
                                    errorState={((passwordError == 'This field is mandatory' && password.length == 0) ||
                                        passwordError == 'Passwords do not match') ? true : false}
                                    showPassword={showPassword}
                                />
                                <img
                                    src={
                                        password.length == 0 ?
                                            (!showPassword ? '/Images/eye-open-inactive.svg' : '/Images/closed-eye-inactive.svg') :
                                            !showPassword
                                                ? "/Images/eye-open-active.svg"
                                                : "/Images/eye-off-active.svg"
                                    }
                                    alt="icon"
                                    loading='lazy'
                                    className="absolute top-[15px] right-2.5 cursor-pointer w-5"
                                    onClick={() => setShowPassword(!showPassword)} />
                                {password.length > 0 && showFormate &&
                                    <motion.div initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{
                                            ease: "linear",
                                            duration: 0.5,
                                            x: { duration: 1 },
                                            delay: 0.1
                                        }}
                                        className="error-condition pt-3">
                                        <p className='text-base text-[#2F2E38] font-normal'>Password must contain:</p>
                                        <div className="flex mt-2">
                                            <img src={passwordValid.length ? "/Images/check.svg" : "/Images/small-x.svg"}
                                                alt="img" loading="lazy" />
                                            <p data-testid='Minimum 8 characters' className={`text-base font-normal pl-1.5 
                                ${passwordValid.length ? 'text-[#008F5D]' : 'text-[#EA3829]'}`}>Minimum 8 characters</p>
                                        </div>
                                        <div className="flex mt-1.5">
                                            <img
                                                src={passwordValid.uppercase ? "/Images/check.svg" : "/Images/small-x.svg"}
                                                alt="img" loading="lazy" />
                                            <p data-testid='At least one uppercase letter' className={`text-base font-normal pl-1.5 
                                ${passwordValid.uppercase ? 'text-[#008F5D]' : 'text-[#EA3829]'}`}>
                                                At least one uppercase letter</p>
                                        </div>
                                        <div className="flex mt-1.5">
                                            <img
                                                src={passwordValid.lowercase ? "/Images/check.svg" : "/Images/small-x.svg"}
                                                alt="img" loading="lazy" />
                                            <p data-testid='At least one lowercase letter' className={`text-base font-normal pl-1.5 
                                ${passwordValid.lowercase ? 'text-[#008F5D]' : 'text-[#EA3829]'}`}>
                                                At least one lowercase letter
                                            </p>
                                        </div>
                                        <div className="flex mt-1.5">
                                            <img src={passwordValid.number ? "/Images/check.svg" : "/Images/small-x.svg"}
                                                alt="img" loading="lazy" />
                                            <p data-testid='At least one number' className={`text-base font-normal pl-1.5 
                                    ${passwordValid.number ? 'text-[#008F5D]' : 'text-[#EA3829]'}`}>
                                                At least one number
                                            </p>
                                        </div>
                                        <div className="flex mt-1.5">
                                            <img src={passwordValid.symbol ? "/Images/check.svg" : "/Images/small-x.svg"}
                                                alt="img" loading="lazy" />
                                            <p data-testid='At least one special character' className={`text-base font-normal pl-1.5 
                                ${passwordValid.symbol ? 'text-[#008F5D]' : 'text-[#EA3829]'}`}>At least one special character</p>
                                        </div>
                                    </motion.div>}
                            </div>
                            <div className='relative mb-6'>
                                <PasswordInputWithLabel
                                    id="confirm_password"
                                    data-testid="confirm_password"
                                    label="Confirm password"
                                    type={showConfirmPassword ? "text" : "password"}
                                    handlePasswordChange={handlePasswordChange}
                                    password={confirmPassword}
                                    passwordError={passwordError == 'This field is mandatory' && confirmPassword.length > 0 || passwordError == 'Temporary and new passwords must differ' || passwordError == 'Incorrect password' ? null : passwordError}
                                    errorState={((passwordError == 'This field is mandatory' && confirmPassword.length == 0) || passwordError == 'Passwords do not match') ? true : false}
                                    showPassword={showConfirmPassword}
                                />
                                <img
                                    src={
                                        confirmPassword.length == 0 ?
                                            (!showConfirmPassword ? '/Images/eye-open-inactive.svg' : '/Images/closed-eye-inactive.svg') :
                                            !showConfirmPassword
                                                ? "/Images/eye-open-active.svg"
                                                : "/Images/eye-off-active.svg"
                                    }
                                    alt="icon"
                                    loading='lazy'
                                    className="absolute top-[15px] right-2.5 cursor-pointer w-5"
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                            </div>
                            <button
                                onClick={(e) => clickHandler(e)} loader={loader}
                                data-testid="reset-password-form"
                                type="submit"
                                disabled={loader}
                                className="bg-[#29275F] rounded-lg text-[#FCFBFE] font-bold text-[18px] 
                                    w-full py-3 hover:bg-[#4B42A3] flex justify-center items-center disabled:bg-[#CCC9E9]"
                            >
                                Reset password
                                {loader && (
                                    <>
                                        <span className='pr-5'></span>
                                        <RotatingLines
                                            strokeColor="#FFFFFF"
                                            strokeWidth="5"
                                            animationDuration="0.55"
                                            width="25"
                                            visible={true}
                                        />
                                    </>
                                )}
                            </button>
                        </form>
                    </motion.div>
                </div>
            </div>
        </>
    );
}
