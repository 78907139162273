import React, { useEffect } from 'react';

const FunctionCheckboxes = ({ items, setFormInput, formInput, Increaseheight }) => {
    const handleCheckboxChange = (itemId) => {
        const itemKey = `${itemId}`;
        const isChecked = formInput?.payroll_functions?.includes(itemKey) || false;
        setFormInput(prevState => ({
            ...prevState,
            payroll_functions: isChecked
                ? prevState?.payroll_functions?.filter(item => item !== itemKey) || []
                : [...(prevState?.payroll_functions || []), itemKey],
        }));
    };

    const isEmployeeMasterSelected = formInput?.menu_items?.includes('employee_master') || false;
    const isAllSelected = (formInput?.payroll_functions?.length || 0) === (items?.length || 0) + 1;

    useEffect(() => {
        if (!isEmployeeMasterSelected) {
            setFormInput(prevState => ({
                ...prevState,
                payroll_functions: prevState?.payroll_functions?.filter(item => 
                    item === 'create_payroll' || item === 'update_payroll'
                ) || []
            }));
        }
    }, [isEmployeeMasterSelected, setFormInput]);

    const filteredItems = isEmployeeMasterSelected || isAllSelected
        ? items
        : items.filter(item => item.id === 'create_payroll' || item.id === 'update_payroll');

    return (
        <div className={Increaseheight ? 'h-[calc(100vh-550px)]' : 'h-[calc(100vh-469px)]'}>
            <div className="grid grid-cols-3">
                {filteredItems.length > 0 &&
                    filteredItems.map(item => (
                        <div key={item.id} className="checkbox relative p-2">
                            <label htmlFor={`checkbox-${item.id}`} 
                                className="pl-[25px] cursor-pointer text-[#2F2E38] text-base font-normal w-fit">
                                {item.label}
                                <input
                                    data-testid={`${item.label}`}
                                    className="checkbox-input custom-checkbox-input"
                                    type="checkbox"
                                    id={`checkbox-${item.id}`}
                                    checked={formInput?.payroll_functions?.includes(`${item.id}`) || false}
                                    onChange={() => handleCheckboxChange(item.id)}
                                />
                            </label>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default FunctionCheckboxes;