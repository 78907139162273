/* eslint-disable max-len */
/* eslint-disable security/detect-object-injection */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dataService } from '../../../services/dataService';
import { handleCurrentPage, handlePagination } from '../../../Redux/paginationSlice';
import Debounce from '../../../CommonComponent/debounce';
import PageNavigation from '../../../CommonComponent/pageNavigation';
import GlobalContext from '../../../CommonComponent/context';
import { SearchBar } from '../../../CommonComponent/Searchbar';
import ActionModal from '../../../CommonComponent/actionModal';
import DropdownFilter from '../../../CommonComponent/Filters/DropdownFilter';
import TableHeader from '../../../CommonComponent/Table/TableHeader';
import AddOrUpdateCurrencyConversion from './AddOrUpdateCurrencyConversion';
import endPoints from '../../../services/endpoints';

export default function CurrencyConversionList() {
    const { setToastmessage, setToastErrorMessage,isSwitchPayroll, setIsSwitchPayroll } = useContext(GlobalContext)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams({ limit: 10});
    const [searchValue, setSearchValue] = useState(searchParams.get('keyword') !== null ?
        decodeURIComponent(searchParams.get('keyword')) : '')
    const [loading, setLoading] = useState(true);
    const [userList, setUserList] = useState({})
    const [nextPage, setNextPage] = useState(true)
    const [prevPageExist, setPrevPage] = useState(false)
    const currentPage = useSelector((state) => state.paginationConfig.currentPage);
    const [actionModal, setActionModal] = useState('')
    const dispatch = useDispatch();
    const paginationData = useSelector((state) => state.paginationConfig.paginationData);
    const [selectedUser, setSelectedUser] = useState({})
    const [spinnerLoader, setSpinnerLoader] = useState(false)
    const [searchExist, setSearchExist] = useState(true)
    const [noRecord, setNoRecord] = useState(false)

    // filter related state
    const [filterData, setFilterData] = useState({
        pay_period_id: searchParams.get('pay_period_id') !== null ? decodeURIComponent(searchParams.get('pay_period_id')) : '',
        period_text: searchParams.get('period_text') !== null ? decodeURIComponent(searchParams.get('period_text')) : ''
    });
    const [isSideModelOpen, setIsSideModelOpen] = useState(false)
    const [editData, setEditData] = useState({})
    const [formInput, setFormInput] = useState({
        currency_name: "",
        symbol: '',
        currency_code: '',
        conversion_rate: '',
        period_text: '',
        currency_id:''
    });
    const handleAdd = (data, key) => {
        if(key === 'Update') {
            setEditData(prevData => ({ 
                ...prevData, 
                ...data,
                'conversion_rate' : parseFloat(data.conversion_rate).toFixed(2),
            }));
            setFormInput(prevData => ({ 
                ...prevData, 
                ...data,
                'conversion_rate' : parseFloat(data.conversion_rate).toFixed(2),
            }))
            setIsSideModelOpen(true)
        }else if(key === 'Delete') {
            setActionModal(key);
            setEditData(data)
        } else {
            setIsSideModelOpen(true)
            setEditData({})
        }
    }
    const [isFilterOpen, setIsfilteropen] = useState(false)
    const filterRef = useRef(null);
    const [selectedFilterParameter, setSelectedFilterParameter] = useState('period')
    const [payRollData, setPayrollData] = useState({})

    /* The above code is a React function component that defines a function `getUserList` using
    the `useCallback` hook. This function is an asynchronous function that fetches user group data
    based on certain search parameters. */
    const getUserList = useCallback(async () => {
        setLoading(true);
        let endpoint = `?limit=10`;

        if (searchParams.get('keyword') !== null) {
            endpoint += `&search=${searchParams.get('keyword')}`;
        }
        if (searchParams.get('last_element') !== null) {
            endpoint += `&last_element=${searchParams.get('last_element')}`;
        }
        if (searchParams.get('pay_period_id') !== null) {
            endpoint += `&pay_period_id=${searchParams.get('pay_period_id')}`;
        }
        const storedOption = JSON.parse(localStorage.getItem('selectedOption'));        
        setPayrollData({
            'payroll_id': storedOption.payroll_id,
            'period_text' : storedOption.current_period,
            'pay_period_id': storedOption.current_period_id
        });
        setFormInput(prevData => ({ 
            ...prevData, 
            'period_text' : storedOption.current_period,
            'pay_period_id': storedOption.current_period_id
        }));
        setIsSwitchPayroll(false)
        const data = await dataService.getAPI(`${endPoints.CurrencyConversion}/${storedOption.payroll_id}/${endpoint}`);
        if (data.data.success_status === true) {
            if (data.data.status == "NO_DATA") {
                setNoRecord(true);
                setUserList(data.data.data);
            } else {
                setNoRecord(false);
                setUserList(data.data.data);
            }
            if (data.data.status == 'NO_MATCH') {
                setSearchExist(false);
                setUserList(data.data.data);
            } else {
                setSearchExist(true);
                setUserList(data.data.data);
            }
            if (searchValue == '' && data.data.data.length == 0) {
                setNextPage(false);
            } else {
                setUserList(data.data.data);
                setNextPage(true);
            }
            if (data.data.last_element === undefined || data.data.last_element === null) {
                setNextPage(false);
            } else {
                setNextPage(true);
                dispatch(handlePagination({
                    ...paginationData,
                    [currentPage + 1]: data.data.last_element,
                }));
            }
            setTimeout(() => {
                setLoading(false);
            }, 300);
        } else if (data.data.success_status === false) {
            let params = Object.fromEntries(searchParams);
            if (Object.keys(paginationData).length > 2) {
                dispatch(handleCurrentPage(currentPage - 1));
                const lastKey = await paginationData[currentPage - 1];
                params["last_element"] = lastKey;
                setSearchParams({ ...params });
            } else {
                dispatch(handlePagination({}));
                dispatch(handleCurrentPage(1));
                setNextPage(false)
                setPrevPage(false)
                delete params.last_element;
                setSearchParams({ ...params })
            }
            if (data.data.message.sort !== undefined && data.data.message?.sort[0]?.includes('Invalid sort value') ||
                data.data.message.includes('Please provide valid input') ||
                data.data.message.includes('Invalid last_element token')) {
                navigate('/page-not-found')
            }
        }
    }, [searchParams, isSwitchPayroll]);

    useEffect(() => {
        getUserList()
    }, [getUserList])

    /**
     * The function `handleChange` handles input changes by debouncing the search function
     * `handleSearch` with a delay of 300 milliseconds.
     */
    const handleChange = (e, value) => {
        handleSearch(e, "keyword", value);
    };
    const changeHandler = (e) => {
        setSearchValue(e.target.value);
    };
    const optimizedFn = useCallback(
        Debounce((e, value) => handleChange(e, value)),
        [searchParams]
    );
    const handleSearch = (e, key, value) => {
        e.preventDefault();
        setNextPage(true)
        setPrevPage(false)
        dispatch(handleCurrentPage(1))
        let params = Object.fromEntries(searchParams);
        delete params.last_element;
        if (key === 'keyword') {
            params[key] = encodeURIComponent(value);
        } else {
            params[key] = value
        }
        if (params['keyword'] === '') delete params.keyword
        setSearchParams({ ...params })

    }

    /**
     * The above functions handle pagination by updating the current page and search parameters for
     * fetching data in a React application.
     */
    const handlePrevPage = async () => {
        setNextPage(true)
        let params = Object.fromEntries(searchParams);
        if (currentPage === 2) {
            setPrevPage(false)
            dispatch(handleCurrentPage(currentPage - 1))
            delete params.last_element
            setSearchParams({ ...params })
        }
        else {
            dispatch(handleCurrentPage(currentPage - 1))
            setPrevPage(true)
            const lastKey = paginationData[currentPage - 1]
            if (lastKey) {
                params['last_element'] = lastKey
                setSearchParams({ ...params })
            }
        }
    }
    /**
    * The function `handleNextPage` increments the current page number, sets a flag for the previous
    * page if the current page is greater than or equal to 1, and updates search parameters with
    * pagination data for the next page.
    */
    const handleNextPage = async () => {
        let params = Object.fromEntries(searchParams);
        if (currentPage >= 1) {
            setPrevPage(true)
        }
        dispatch(handleCurrentPage(currentPage + 1))
        params['last_element'] = paginationData[currentPage + 1]
        setSearchParams({ ...params })
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setIsfilteropen(false);
                if (searchParams.get('role') === null) {
                    setFilterData((prevState) => ({
                        ...prevState,
                        role: "",
                    }));
                } else {
                    setFilterData((prevState) => ({
                        ...prevState,
                        role: searchParams.get('role'),
                    }));

                } if (searchParams.get('status') === null) {
                    setFilterData((prevState) => ({
                        ...prevState,
                        status: "",
                    }));
                } else {
                    setFilterData((prevState) => ({
                        ...prevState,
                        status: searchParams.get('status'),
                    }));
                }
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchParams]);


    const handleFilter = (id) => {
        setNextPage(true);
        setPrevPage(false);
        dispatch(handleCurrentPage(1));
        let params = Object.fromEntries(searchParams);
        delete params.last_element;
        params['page'] = 1;
        params['filter'] = 'true';
        params[id] = filterData[id];
        if(id === 'period_text') {
            params.pay_period_id = filterData.pay_period_id
        }
        setSearchParams({ ...params, page: 1 });

        // Check if filter data is empty
        if (filterData.period_text === '' ) {
            delete params.filter;
        } else {
            params['filter'] = 'true';
        }

        if (filterData.period_text !== '') {
            params['period_text'] = filterData.period_text;
            params['pay_period_id'] = filterData.pay_period_id;
        } else {
            delete params.period_text;
            delete params.pay_period_id
        }

        setIsfilteropen(false);
        setSearchParams({ ...params });
    };


    const clearFilters = () => {
        let params = Object.fromEntries(searchParams);
        setFilterData({
            period_text: '',
            pay_period_id: ''
        });
        delete params.period_text;
        delete params.pay_period_id;
        delete params.filter;
        setIsfilteropen(false);
        setSearchParams({ ...params });
    }

    const handleDelete = async () => {
        try {
            setSpinnerLoader(true);
            
            if (editData.is_assigned) {
                setActionModal('');
                setSpinnerLoader(false);
                setEditData({});
                return;
            }
            const response = await dataService.deleteAPI(`${endPoints.CurrencyConversion}/${payRollData.payroll_id}/${editData?.currency_conversion_id}`);
            if (response.error === false) {
                setSpinnerLoader(false);
                setActionModal('');
                getUserList();
                setToastmessage('Currency conversion deleted successfully');
            } else {
                setSpinnerLoader(false);
                setActionModal('');
                setEditData({})
                setToastErrorMessage(response.data.data.message ||'Something went wrong!');
            }
        } catch (error) {
            setSpinnerLoader(false);
            setActionModal('');
            setToastErrorMessage(error.message || 'An error occurred!');
        }
    };
    
    const getModalText = () => {
        if(editData.is_assigned) {
            return 'Currency conversions cannot be deleted while in use.'
        } else {
            return <>
                <p>
                    {`Are you sure you want to delete the Currency conversion`}
                    <strong> {editData.currency_name}</strong>?
                </p>
                <p>This action cannot be undone.</p>
            </>
        }
    }

    const tableHederData = {
        currency_name: {
            headerName : 'Currency name',
            key : 'currency_name',
            dataTestId: 'currency_name',
            isSort: false,
            thClass : 'min-w-[215px]',
            spanClass : 'items-center'
        },
        symbol : {
            headerName : 'Symbol',
            key : 'symbol',
            dataTestId: 'symbol',
            isSort: false,
            thClass : 'min-w-[150px]',
            spanClass : 'items-center'
        },
        currency_code: {
            headerName : 'Currency code',
            key : 'currency_code',
            dataTestId: 'currency_code',
            isSort: false,
            thClass : 'min-w-[170px]',
            spanClass : 'items-center'
        },
        conversion_rate: {
            headerName : 'Conversion rate',
            key : 'conversion_rate',
            dataTestId: 'conversion_rate',
            isSort: false,
            thClass : 'min-w-[180px]',
            spanClass : 'items-center'
        },
        period_text: {
            headerName : 'Period',
            key : 'period',
            dataTestId: 'period',
            isSort: false,
            thClass : 'min-w-[140px]',
            spanClass : 'items-center'
        },
        actions: {
            headerName : 'Actions',
            key : 'actions',
            dataTestId: 'actions',
            isSort: false,
            thClass : 'min-w-[119px] bg-[#F1F1F4]',
            spanClass : 'items-center justify-center pr-1.5',
            actionsList : ['Update', 'Delete'],
            sticky : false
        }
    }

    return (
        <>
            {!noRecord &&
                <div className='flex justify-between items-end'>
                    <SearchBar
                        searchValue={searchValue}
                        optimizedFn={optimizedFn}
                        changeHandler={changeHandler}
                        placeholder={'Search by currency name'}
                    />

                    <div className='flex items-center'>
                        <div className="relative">
                            <DropdownFilter
                                filterRef={filterRef}
                                setIsfilteropen={setIsfilteropen}
                                isFilterOpen={isFilterOpen}
                                isFilterApplied={searchParams.get('period_text') ? true : false}
                                clearFilters={clearFilters}
                                handleFilter={handleFilter}
                                selectedFilterParameter={selectedFilterParameter}
                                setSelectedFilterParameter={setSelectedFilterParameter}
                                formInput={filterData}
                                setFormInput={setFilterData}
                                payRollData={payRollData}
                            />
                        </div>
                        <button
                            className='flex justify-center items-center py-2.5 px-5 w-[110px] h-10
                                        rounded-lg bg-[#29275F] text-base text-[#FCFBFE] font-bold hover:bg-[#4B42A3] disabled:bg-[#CCC9E9]'
                            data-testid="aadd"
                            onClick={handleAdd}
                        >
                            <img src='/Images/plus.svg' alt='icon' className='mr-[9px]' loading='lazy' />
                            Add
                        </button>
                    </div>

                </div>
            }
            <AddOrUpdateCurrencyConversion
                isSideModelOpen={isSideModelOpen}
                setIsSideModelOpen={setIsSideModelOpen}
                getUserList={getUserList}
                editData={editData}
                formInput={formInput}
                setFormInput={setFormInput}
                isEdit={Object.keys(editData).length !== 0}
                setEditData={setEditData}
                payRollData={payRollData}
            />
            <TableHeader
                noRecord={noRecord}
                loading={loading}
                isFilterApplied={searchParams.get('period_text') ? true : false}
                searchExist={searchExist}
                userList={userList}
                handleAction={handleAdd}
                searchParams={searchParams}
                tableHederData={tableHederData}
                handleAdd={handleAdd}
                searchValue={searchValue}
                EmptyScreenTittle={'currency conversion'}

            />
            {(!loading && ((nextPage || prevPageExist || currentPage > 1) && searchExist)) &&
                <PageNavigation
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                    prevPageExist={prevPageExist}
                    nextPage={nextPage}
                    currentPage={currentPage}
                    loading={loading}
                />
            }
            {actionModal !== '' &&
                <ActionModal
                    isOpen={actionModal !== ''}
                    setIsOpen={setActionModal}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    mainHeading={'Delete Currency Conversion'}
                    message={getModalText()}
                    ctaButton={`${editData.is_assigned ? "Go Back" : "Delete"}`}
                    cancelButton={editData.is_assigned ? false : 'Cancel'}
                    handleClickFunction={handleDelete}
                    loader={spinnerLoader}
                    setLoader={setSpinnerLoader}
                />
            }
        </>
    );
}
