/* eslint-disable max-len */
import React from 'react'
import ItemShimmer from '../itemShimmer'
import EmptyScreen from '../EmptyScreen'
import Lottie from 'lottie-react';
import blockImage from "../search_not_found.json"
import { TableBody } from './TableBody';

export default function TableHeader({
    noRecord,
    handleAdd,sort,
    emptyScreenTittle, tableHeight,
    loading, handleSort, isFilterApplied, searchExist, userList, handleAction, searchParams, tableHederData, searchValue, EmptyScreenTittle,
}) {
    return (
        <div className={`flex table-content mt-[25px] overflow-auto items-start ${tableHeight ? tableHeight : 'h-[calc(100vh-400px)]'} customScrollbar`}>
            <table aria-label="Outside Table" className="w-full table-auto overflow-auto customScrollbar pb-2">
                {!noRecord && !loading &&
                    <thead>
                        <tr className="bg-[#F1F1F4] h-[50px] w-full sticky top-0 left-0 z-10">
                            {Object.values(tableHederData).map((item) => (
                                <th className={`px-6 py-3 ${item.thClass ? item.thClass : 'min-w-[180px]'}`} key={item.key}>
                                    <span className={`flex ${item.spanClass ? item.spanClass : 'items-center'} 
                                text-base leading-5 text-[#2F2E38] font-semibold`}>
                                        {item.headerName}
                                        {item.isSort &&
                                            <button type='button' 
                                                className="pl-[14px]" 
                                                data-testid={item.dataTestId}
                                                onClick={(e) => { handleSort(e, item.key) }}
                                            >
                                                {sort === `${item.key}+asc` ?
                                                    <img decoding="async" loading="lazy" id="sort"
                                                        src="/Images/sort-icon.svg" alt="icon" /> :
                                                    <img decoding="async" loading="lazy" id="sort"
                                                        src="/Images/sort-icon.svg" alt="icon" className='rotate-180' />}
                                            </button>}
                                    </span>
                                </th>))}
                        </tr>
                    </thead>}
                {loading &&
                    !noRecord ? <tbody>
                        {Array(11).fill().map((_, index) => (
                            <tr key={index}>
                                {Array(6).fill(null).map((_, index) => (<td key={index}><ItemShimmer /></td>))}
                            </tr>
                        ))}
                    </tbody> :
                    <>
                        <tbody>
                            <td colSpan={7}>
                                {noRecord ?
                                    <EmptyScreen EmptyScreenTittle={EmptyScreenTittle} handleAdd={handleAdd} />
                                    :
                                    searchExist === false &&
                                    <div className="error-message">
                                        <Lottie
                                            animationData={blockImage}
                                            loop={true}
                                            className="w-[240px] mx-auto mt-8"
                                        />
                                        <p data-testid="empty_screen"
                                            className='text-[18px] font-semibold text-[#2F2E38] text-center pb-5 pt-12'>
                                            We could not find any {' '}

                                            {isFilterApplied && searchValue !== '' ?
                                                'matches or results based your search or chosen filters' :
                                                isFilterApplied ?
                                                    'results based on your chosen filters' : 'matches for your search'}.
                                        </p>
                                    </div>
                                }
                            </td>
                        </tbody>
                        {!noRecord && searchExist &&
                            <TableBody
                                data={userList}
                                handleAction={handleAction}
                                tableHederData={tableHederData}
                            />
                        }
                    </>
                }
            </table>
        </div>
    )
}
