import React from 'react'
import { RotatingLines } from 'react-loader-spinner'

export default function Button({disabled, ButtonName, loader, handleButtonClick, dataTestID}) {
    return (
        <button 
            type='button' 
            data-testid={dataTestID}
            disabled={disabled}
            className={` ${loader ? 'bg-[#CCC9E9]' : 'bg-[#29275F]'}
                text-[#FCFBFE] text-base 
                                font-bold py-2.5 px-5 w-full rounded-lg  hover:bg-[#4B42A3] 
                                flex justify-center items-center disabled:bg-[#CCC9E9]`}
            onClick={handleButtonClick}
        >{ButtonName}
            {loader && (
                <>
                    <span className='pr-5'></span>
                    <RotatingLines
                        strokeColor="#FFFFFF"
                        strokeWidth="5"
                        animationDuration="0.55"
                        width="25"
                        visible={true}
                    />
                </>
            )}
        </button>
    )
}
