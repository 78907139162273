/* eslint-disable max-len */
import React, {useState, useCallback} from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip";
import CommonDropdown from '../CommonDropdown';
import { dataService } from '../../services/dataService';
import Debounce from '../debounce';

const DropdownFilter = ({formInput, payRollData,
    setFormInput, filterRef, setIsfilteropen, isFilterOpen, isFilterApplied, clearFilters, handleFilter, selectedFilterParameter, setSelectedFilterParameter }) => {

    const [loadingData, setLoadingData] = useState(true);

    const [errorState, setErrorState] = useState({
        period_text: '',
    });
    const [options, setOptions] = useState({period_text : []})
    const handleChange = (e) => {
        const { id, value } = e.target;
    
        // For other input fields, handle the value normally
        let formValue = getValueTrim(id, value);
        setFormInput(prevData => ({ 
            ...prevData, 
            [id]: formValue 
        }));
    
        // Clear any previous error for this field
        setErrorState((prevState) => ({
            ...prevState,
            [id]: "",
        }));
    };
    const [dropdownStates, setDropdownStates] = useState({
        period_text: false,
    });
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchText, setSearchText] = useState('');
    const handleSearchInputChange = (e) => {
        const value = e.target.value;
        setSearchText(value);
        setIsAPICalled({currency_name : false, period_text : false})
        debouncedFetchCurrencyList(e.target.id, value)
    };
    const getOptionList = async (id , value) => {
        setLoadingData(true);
        try {
            const res = await dataService.getAPI(
                `currency-management/${id === 'currency_name' ?'currency-type' : 'pay-period'}/${payRollData.payroll_id}${value ? `?search=${value}` : ''}`
            );
            setOptions((prevState) => ({
                ...prevState,
                [id]: res.data.data,
            }));
        } catch (error) {
            console.error("Error fetching currency list:", error);
            // Optionally handle the error, such as setting an error state or displaying a message
        } finally {
            setLoadingData(false); // Ensure loading state is updated regardless of success or failure
        }
    };  
    const debouncedFetchCurrencyList = useCallback(
        Debounce((id, value) => getOptionList(id, value), 300), // Adjust debounce delay as needed
        [getOptionList]
    );
    const handleOptionClick = (option, id) => {

        // Update form input (with currency_id logic if id is 'currency')
        setFormInput(prevInput => ({
            ...prevInput,
            [id]: option.value || option.Name || option.currency_name, // Adjust based on your option structure
            ...(id === 'currency_name' && { currency_code: option.currency_code, symbol: option.symbol, currency_id: option.currency_id }), // Conditionally add currency_id if the id is 'currency'
            ...(id === 'period_text' && { period_text: option.period_text, pay_period_id: option.pay_period_id}) // Conditionally add tax_method_id if id is 'tax_method'
        }));

        // Close dropdown after selection
        setDropdownStates(prevStates => ({
            ...prevStates,
            [id]: false
        }));
    };

    const [isAPICalled, setIsAPICalled] = useState({period_text : false});

    const handleInputClick = async(id) => {
        // setSearchText('')
        if(!isAPICalled[id]) {
            setIsAPICalled((prevState) => ({
                ...prevState,
                [id]: true,
            }));
            getOptionList(id);
        }
        setDropdownStates((prev) => {
            const newDropdownStates = { ...prev };
            // Toggle the selected dropdown and close others
            Object.keys(newDropdownStates).forEach((key) => {
                newDropdownStates[key] = key === id ? !prev[id] : false;
            });
            return newDropdownStates;
        });
        setErrorState((prevState) => ({
            ...prevState,
            [id]: "",
        }));
    };
    return (
        <div ref={filterRef}>
            <button type='button' data-testid="filter-btn" data-tooltip-id="filter"
                className={`w-10 h-10 justify-center rounded-full flex items-center hover:bg-[#CCC9E9] mr-6 relative
                                ${isFilterOpen ? 'bg-[#CCC9E9]' : ''}`}
                onClick={() => { setIsfilteropen(!isFilterOpen) }}>
                <img src='/Images/filter-list.svg' alt='icon' loading='lazy' />
                {isFilterApplied &&
                    <span className='w-2 h-2 rounded-full bg-[#655CBD] absolute top-2 right-1.5'></span>}
            </button>
            <ReactTooltip
                id="filter"
                place="top"
                variant="info"
                content="Filter"
                arrowColor="white"
                style={{
                    backgroundColor: "#5E5C70",
                    color: "#F1F1F4",
                    height: '28px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '2px'
                }}
            />
            <div className={`dropdown-container absolute z-20 w-[473px] top-[50px] h-[150px] mr-[12px] rounded-lg py-3.5 px-4 bg-white 
                        right-2.5 shadow-s4 border 
                        border-[#CCC9E9] ${isFilterOpen ? 'open opacity-100' : 'closed opacity-0 max-h-0 overflow-hidden'}`}>
                <div className='flex'>
                    <div className={`border-r border-[#57A8DC] mr-4 text-base text-[#29275F]`}>
                        <div>
                            <p className={`w-[111px] cursor-pointer pt-[5px] ${selectedFilterParameter === 'period' ? 'font-bold' : 'font-normal'}`} onClick={() => setSelectedFilterParameter('period')} data-testid="period" >Period</p>
                        </div>
                    </div>
                    <div className='w-[309px] my-1 pb-1'>
                        {selectedFilterParameter === 'period' &&
                            <CommonDropdown
                                id="period_text"
                                label="Period"
                                type="text"
                                autocomplete="off"
                                required={false}
                                handleChange={handleChange}
                                formInput={formInput}
                                errorState={errorState}
                                options={options.period_text}
                                handleOptionClick={handleOptionClick}
                                showDropdown={showDropdown.period_text}
                                setShowDropdown={setShowDropdown}
                                setDropdownStates={setDropdownStates}
                                handleInputClick={handleInputClick}
                                setSearchText={setSearchText}
                                displayKey={"period_text"}
                                dropdownStates={dropdownStates}
                                handleSearchInputChange={handleSearchInputChange}
                                searchText={searchText}
                                searchbar={false}
                                dropdwonHeight = {"h-[199px]"}
                                loadingData={loadingData}
                                lastElement
                                dropdownDataTestId={'dropdown-period-text'}
                                dropdownDisable={false}
                            />
                        }
                        <div className='flex mt-[32px]'>
                            <button type='button' data-testid="reset-btn"
                                className='py-2.5 px-5 border border-[#29275F] h-[37px]
                                        hover:border-[#4B42A3] text-base text-[#29275F] hover:text-[#4B42A3] 
                                        font-bold rounded-lg flex items-center w-[100px] mr-3.5 justify-center'
                                onClick={() => clearFilters()}>
                                Reset
                            </button>
                            <button
                                className='flex justify-center items-center py-2.5 px-5 w-[100px] h-[37px]
                    rounded-lg bg-[#29275F] text-base text-[#FCFBFE] font-bold hover:bg-[#4B42A3]' data-testid="apply-btn"
                                onClick={() => handleFilter('period_text', formInput.period_text)}>
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DropdownFilter;
