import React from 'react';
import { motion } from 'framer-motion';
import MenuBox from '../../CommonComponent/MenuBox';
import { useSelector } from 'react-redux';

export default function Setup() {
    const { privileges, role } = useSelector((state) => state.privileges);

    // Define the container animation with a 2ms stagger
    const container = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3,
            },
        },
    };

    // Define the individual item animation strictly from left to right
    const item = {
        hidden: { opacity: 0, x: -50, y: 0 }, // Ensure movement only on x-axis (left to right)
        visible: { opacity: 1, x: 0, y: 0, transition: { duration: 0.3 } }, // Animate to full opacity with a faster 0.3s duration
    };

    const hasPrivilege = (requiredPrivilege) => {
        if (role === 'admin' || role === 'super admin') return true;
        
        if (Array.isArray(requiredPrivilege)) {
            return requiredPrivilege.some(priv => privileges?.menu_items.includes(priv));
        }
        
        return privileges?.menu_items.includes(requiredPrivilege);
    };

    const menuItems = [
        {
            icon: 'time-period-icon.svg',
            title: 'Periods',
            description: 'Stores the transactions, separately, for each period',
            path: '/setup/periods',
            privilege: ["setup:pay_periods","setup:accounting_periods"],
        },
        {
            icon: 'Units.svg',
            title: 'Units',
            description: 'Processes transactions with unit values for any unit type',
            path: '/setup/units',
            privilege: "setup:units",
        },
        // {
        //     icon: 'transaction-code.svg,
        //     title: 'Transaction codes',
        //     description: 'Processes various payroll transactions for employees.',
        //     path: '/setup/transaction-codes'
        // },
        {
            icon: 'Currencies.svg',
            title: 'Currencies',
            description: 'Setup the currencies & exchange rates for the payroll.',
            path: '/setup/currencies/currency-types',
            privilege: ["setup:currency_conversion", "setup:coinage", "setup:conversion"],
        },
        {
            icon: 'company.svg',
            title: 'Company details',
            description: 'Organisation address for reports & bank details for automated payments.',
            path: '/setup/company',
            privilege: "setup:company_details",

        },
        {
            icon: 'third-party.svg',
            title: 'Third parties',
            description: 'Setup bank & address details for third-party payments',
            path: '/setup/third-party',
            privilege: "setup:third_party",

        },
        {
            icon: 'other-leaves.svg',
            title: 'Other leaves',
            description: 'Setup other leave types & the rules that apply to them.',
            path: '/setup/other-leaves',
            privilege: "setup:other_leaves",

        },
        {
            icon: 'Ranges.svg',
            title: 'Ranges',
            description: 'Setup age & wage rate ranges for reporting',
            path: '/setup/ranges',
            privilege: ["setup:age_distribution","setup:wage_distribution"],

        }
    ];

    const filteredMenuItems = role === 'user' ? menuItems.filter(item => hasPrivilege(item.privilege)) : menuItems;


    return (
        <div className='bg-white rounded-tl-lg h-[calc(100vh-51px)]'>
            <motion.div
                initial="hidden" 
                animate="visible"
                variants={container} 
                className="px-6 pt-[72px]"
            >
                <h1 className="text-[#2F2E38] text-[32px] font-bold pb-[42px]">Setup menu</h1>
                <div className="grid grid-cols-4 gap-4 max-h-[calc(75vh-100px)] overflow-auto overflow-x-hidden customScrollbar">
                    {filteredMenuItems.map((itemData, index) => (
                        <motion.div key={index} variants={item}>
                            <MenuBox
                                icon={itemData.icon}
                                title={itemData.title}
                                description={itemData.description}
                                path={itemData.path}
                            />
                        </motion.div>
                    ))}
                </div>
            </motion.div>
        </div>
    );
}
