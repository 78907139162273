/* eslint-disable max-len */
/* eslint-disable security/detect-object-injection */
import React, { useState, useRef, useEffect } from 'react';
import Lottie from 'lottie-react';
import blockImage from "../CommonComponent/search_not_found.json"
import SearchInputLoadingShimmer from './SearchInputLoadingShimmer';
import HoveringTooltip from './HoveringTooltip';

function CommonDropdown({
    id, formInput, errorState, options, label,lastElement,dropdownDataTestId,dropdownDisable,required,customDataTestId,
    selectedOption, handleOptionClick, handleInputClick, dropdownStates, displayKey, handleSearchInputChange, searchText, searchbar, dropdwonHeight,
    setDropdownStates, inputFieldLoader, loadingData
}) {
    const [focused, setFocused] = useState(false);
    const [countryFocused, setCountryFocused] = useState(false);
    const dropdownRef = useRef(null);

    // Close dropdown if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownStates((prev) => ({
                    ...prev,
                    [id]: false, // Close only the current dropdown
                }));
                // setTaxMethodSearchText('')
                // setSearchText('')
                setCountryFocused(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [id, setDropdownStates]);

    return (
        <div className={`${lastElement ? '' : 'mb-[30px]'} country-select-dropdown w-full`} ref={dropdownRef}>
            <div className="relative" data-tooltip-id={`${label}-tooltip`}>
                {/* Input field with label */}
                <div className="relative">
                    <label
                        className={`absolute transition-all left-2 font-normal bg-white pointer-events-none ${
                            errorState[id] !== "" ? "text-[#EA3829]" : "text-[#2F2E38]"
                        } ${
                            formInput[id] !== "" || focused
                                ? "top-[-8px] text-xs px-[4px]"
                                : "top-1/2 -translate-y-1/2 text-[14px] mx-[4px]"
                        }`}
                    >
                        {label}
                        {required && <span className={`${errorState[id] !== "" ? "text-[#EA3829]" : "text-[#2F2E38]"}`}>*</span>}
                    </label>
                    <input
                        type="text"
                        data-testid={dropdownDataTestId || "dropdown"}
                        value={inputFieldLoader ? '' : selectedOption ? selectedOption[id] : formInput[id]}
                        onClick={() => handleInputClick(id)}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        readOnly
                        disabled={dropdownDisable}
                        className={`pr-10 border text-[14px] font-normal rounded w-full py-3 px-3 outline-0 focus-visible:outline-0 input-no-text text-[#2F2E38] ${dropdownDisable ? '' : 'cursor-pointer'}  ${
                            errorState[id] !== "" ? "border-[#EA3829]" : dropdownDisable ? 'border-[#ACABBA] text-[#ACABBA]': formInput[id] !== '' || focused
                                ? "border-[#5E5C70]"
                                : "border-[#ACABBA]"
                        }`}
                        style={{ textAlign: inputFieldLoader ? 'center' : 'left' }}
                    />
                    {dropdownDisable &&
                    <HoveringTooltip place={"bottom"} Name={'Non editable'} id={`${label}-tooltip`} className={'tooltip-bottom-left tooltip-class'}/>
                    }
                    {/* Loader inside input field */}
                    {inputFieldLoader && (
                        <div className="absolute inset-0 flex justify-center items-center">
                            <div className='w-[270px] h-[25px] rounded bg-[#dbd9d9f3] animate-pulse'></div>
                        </div>
                    )}
                    {/* Chevron for dropdown */}
                    {!inputFieldLoader && (
                        <div className={`absolute top-1/2 -translate-y-1/2 right-3 ${dropdownDisable ? '' : 'cursor-pointer'}`} onClick={() => handleInputClick(id)}>
                            {!dropdownStates[id] ?
                                <img src='/Images/chevron-down.svg' alt='icon' loading='lazy' className={dropdownDisable ? 'opacity-25': ''}/> :
                                <img src='/Images/chevron-up.svg' alt='icon' loading='lazy' />}
                        </div>
                    )}
                </div>
                {/* Error message */}
                <p className="text-[#EA3829] text-xs font-normal pt-[2px] pl-3">
                    {errorState[id]}
                </p>
                {/* Dropdown content */}
                <div className="relative">
                    <div
                        className={`dropdown-container z-10 absolute top-0 left-0 bg-white w-full 
                            border-b border-r border-l border-[#D6D5DD] ${
        dropdownStates[id] ? 'open opacity-100' : 'closed opacity-0 max-h-0 overflow-hidden'
        }`}
                    >
                        <div className="dropdown-content w-full relative mt-3">
                            {searchbar && (
                                <div className={`flex items-center border ${
                                    searchText !== '' || countryFocused ? "border-[#5E5C70]" : "border-[#ACABBA]"
                                } rounded py-2.5 px-3 shadow-s4 mx-3 mb-3 sticky top-0 left-0 w-auto bg-white`}>
                                    {searchText || countryFocused
                                        ? <img src='/Images/search-hightight.svg' alt='icon' />
                                        : <img src='/Images/search.svg' alt='icon' />}
                                    <input
                                        type="text"
                                        data-testid="add-search"
                                        value={searchText}
                                        onFocus={() => setCountryFocused(true)}
                                        onBlur={() => setCountryFocused(false)}
                                        onChange={(e) => handleSearchInputChange(e)}
                                        placeholder="Search"
                                        className='outline-0 w-full ml-2 placeholder:text-[#918FA3] text-[#2F2E38] text-sm font-normal'
                                    />
                                </div>
                            )}
                            {/* Dropdown options */}
                            <div className={`${dropdwonHeight ? dropdwonHeight : "h-[95px]"} overflow-auto customScrollbar`}>
                                {loadingData ? (
                                // Shimmer effect when data is loading
                                    <SearchInputLoadingShimmer length={5}/>
                                ) : options?.length > 0 ? (
                                    options.map((option, i) => (
                                        <div
                                            key={`key-${i}`}
                                            data-testid={customDataTestId !== '' ? `dropdown-${option[customDataTestId]}` : `dropdown-${option.dataTestId}`}
                                            data-test-id={`${id}-${i}`}
                                            onClick={() => handleOptionClick(option, id)}
                                            className={`dropdown-item cursor-pointer text-sm font-normal text-[#2F2E38] px-3 py-2.5 hover:bg-[#EEEDF8] flex items-center ${
                                                selectedOption === option ? 'bg-[#EEEDF8]' : ''
                                            }`}
                                        >
                                            <p className='mr-2 w-fit '>{displayKey ? option[displayKey] : option.value}</p>
                                        </div>
                                    ))
                                ) : (
                                    <div className="error-message h-full flex flex-col items-center justify-center">
                                        <Lottie
                                            animationData={blockImage}
                                            loop={true}
                                            className="w-[90px] mx-auto"
                                        />
                                        <p className='text-sm font-normal text-[#2F2E38] text-center pb-5 pt-3'>
                                            We could not find any matches for your search.
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CommonDropdown;
