/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";

const NestedCheckboxesAccordion = ({ items, organizationalItems, setFormInput, formInput, params, isFullAccess }) => {
    const [openTabs, setOpenTabs] = useState([]);

    /**
     * The function `handleCheckboxChange` toggles the presence of an item key in an array based on its
     * current state.
     */
    const handleCheckboxChange = (accordionType, itemId) => {
        const itemKey = `${accordionType}:${itemId}`;
        setFormInput(prevState => ({
            ...prevState,
            menu_items: prevState.menu_items.includes(itemKey)
                ? prevState.menu_items.filter(item => item !== itemKey)
                : [...prevState.menu_items, itemKey],
        }));
    };

    /**
     * The `toggleTab` function toggles the visibility of a tab by adding or removing its ID from the
     * list of open tabs.
     */
    const toggleTab = (id) => {
        setOpenTabs(prevOpenTabs => 
            prevOpenTabs.includes(id) 
                ? prevOpenTabs.filter(tabId => tabId !== id)
                : [...prevOpenTabs, id]
        );
    };

    /* The `useEffect` hook in the provided code snippet is responsible for updating the state variable
    `openTabs` based on changes to `params.id` and `formInput.menu_items`. Here's a breakdown of
    what it does: */
    useEffect(() => {
        if (params.id !== undefined) {
            const newOpenTabs = [];
            if (formInput.menu_items.some(item => item.startsWith('setup:'))) {
                newOpenTabs.push('setup');
            }
            if (formInput.menu_items.some(item => item.startsWith('organisational:'))) {
                newOpenTabs.push('organisational');
            }
            setOpenTabs(newOpenTabs);
        }
    }, [params.id, formInput.menu_items]);

    /**
     * The function `handleParentCheckboxChange` updates menu items and payroll functions based on the
     * accordion type and checked status.
     */
    const handleParentCheckboxChange = (accordionType, checked) => {
        setFormInput(prevState => {
            // Ensure payroll_functions is an array
            let updatedPayrollFunctions = Array.isArray(prevState.payroll_functions) ? [...prevState.payroll_functions] : [];
    
            let updatedMenuItems;
            if (accordionType === 'employee_master') {
                updatedMenuItems = checked
                    ? [...prevState.menu_items, accordionType]
                    : prevState.menu_items.filter(item => item !== accordionType);
    
                if (!checked) {
                    const allowedFunctions = ['create_payroll', 'update_payroll'];
                    updatedPayrollFunctions = updatedPayrollFunctions.filter(func => {
                        const cleanFunc = func.replace('functions:', '');
                        return allowedFunctions.includes(cleanFunc);
                    });
                }
            } else {
                const accordionItems = accordionType === 'setup' ? items : organizationalItems;
                const accordionItemKeys = accordionItems.map(item => `${accordionType}:${item.id}`);
                updatedMenuItems = checked
                    ? [...new Set([...prevState.menu_items, ...accordionItemKeys])]
                    : prevState.menu_items.filter(item => !accordionItemKeys.includes(item));
            }
    
            return {
                ...prevState,
                menu_items: updatedMenuItems,
                payroll_functions: updatedPayrollFunctions,
            };
        });
    };
    

    // UseEffect to handle isFullAccess and automatically check all items
    useEffect(() => {
        if (isFullAccess) {
            const allItems = [
                ...items.map(item => `setup:${item.id}`),
                ...organizationalItems.map(item => `organisational:${item.id}`),
                'employee_master'
            ];
            setFormInput(prevState => ({
                ...prevState,
                menu_items: allItems
            }));
        }
    }, [isFullAccess, items, organizationalItems, setFormInput]);

    function Icon({ id }) {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className={`${openTabs.includes(id) ? "rotate-180" : ""} h-5 w-5 transition-transform cursor-pointer`}
                onClick={() => toggleTab(id)}
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
        );
    }

    const renderAccordion = (id, label, accordionType, accordionItems) => (
        <>
            <Accordion open={openTabs.includes(id)} key={id} icon={accordionType !== 'employee_master' ? <Icon id={id} /> : null} >
                <AccordionHeader className="px-0 border-b border-[#F1F1F4] py-0.5">
                    <div className="checkbox relative">
                        <label htmlFor={`${id}Checkbox`} className="pl-[33px] cursor-pointer text-[#2F2E38] text-base font-normal flex items-center p-2 w-max">
                            {label}
                            <input
                                data-testid={`${label}`}
                                className="checkbox-input custom-checkbox-input"
                                type="checkbox"
                                id={`${id}Checkbox`}
                                checked={
                                    accordionType === 'employee_master'
                                        ? formInput.menu_items.includes(accordionType)
                                        : accordionItems.every((item) => formInput.menu_items.includes(`${accordionType}:${item.id}`))
                                }
                                onChange={(e) => {
                                    handleParentCheckboxChange(accordionType, e.target.checked);
                                    if (e.target.checked && !openTabs.includes(id)) {
                                        toggleTab(id);
                                    }
                                }}
                            />
                        </label>
                    </div>
                    <div onClick={() => toggleTab(id)} className='w-full h-10'></div>
                </AccordionHeader>
                {accordionType !== 'employee_master' && <AccordionBody className="border-b border-[#F1F1F4] pb-3 px-0 pt-0">
                    <div className="grid grid-cols-3">
                        {accordionItems.map((item) => (
                            <div key={item.id} className="checkbox relative p-2">
                                <label htmlFor={`checkbox-${item.id}`} className="pl-[25px] cursor-pointer text-[#2F2E38] text-base font-normal w-fit">
                                    {item.label}
                                    <input
                                        data-testid={`${item.label}`}
                                        className="checkbox-input custom-checkbox-input"
                                        type="checkbox"
                                        id={`checkbox-${item.id}`}
                                        checked={formInput.menu_items.includes(`${accordionType}:${item.id}`)}
                                        onChange={() => handleCheckboxChange(accordionType, item.id)}
                                    />
                                </label>
                            </div>
                        ))}
                    </div>
                </AccordionBody>}
            </Accordion>
        </>
    );

    return (
        <div>
            {renderAccordion('setup', 'Setup', 'setup', items)}
            {renderAccordion('organisational', 'Organisational', 'organisational', organizationalItems)}
            {renderAccordion('employee_master', 'Employee Master', 'employee_master', [])}
        </div>
    );
};

export default NestedCheckboxesAccordion;
