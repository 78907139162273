/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';


export default function InvalidToken() {
    const navigate = useNavigate();
    const handleNavigate = () => {
        signOut();
        navigate('/forgot-password')
    }

    return (
        <div className='flex justify-center items-center flex-col h-screen'>
            <img src='/Images/Forgot password-bro.svg' alt='icon' loading='lazy' />
            <p className='text-base text-[#29275F] font-semibold pt-8 pb-12 text-center'>
                It appears that the password reset link you have used is either invalid or has expired. <br />
                To rectify this, kindly initiate the 'Forgot password' process once more to receive a <br /> fresh link.</p>
            <button
                onClick={() => handleNavigate()}
                type="button"
                className="bg-[#29275F] rounded-lg text-[#FCFBFE] font-bold text-[18px] w-[250px] py-3 
                hover:bg-[#4B42A3] flex justify-center items-center">
                <img src='/Images/arrow-left.svg' alt='icon' loading='lazy' className='mr-2.5' />
                Forgot password
            </button>
        </div>
    );
}
