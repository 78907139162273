/* eslint-disable security/detect-object-injection */
/* eslint-disable max-len */
import React, { useCallback, useState, useContext, useEffect } from 'react'
import DrawerSideBar from '../../../CommonComponent/DrawerSideBar'
import CommonDropdown from '../../../CommonComponent/CommonDropdown'
import { getValueTrim } from '../../../CommonComponent/InputValidation';
import { dataService } from '../../../services/dataService';
import Debounce from '../../../CommonComponent/debounce';
import InputWithLabel from '../../../CommonComponent/inputField';
import Button from '../../../CommonComponent/Button';
import { FelidValidation } from '../../../CommonComponent/FelidValidation';
import GlobalContext from '../../../CommonComponent/context';
import endPoints from '../../../services/endpoints';
import deepEqual from '../../../CommonComponent/objectComparison';

export default function UnitsAddOrUpdateModel({ formInput, setEditData, payRollData,
    setFormInput, isSideModelOpen, setIsSideModelOpen, getUserList, isEdit, editData }) {

    const { setToastmessage, setToastErrorMessage } = useContext(GlobalContext)

    const InputItems = {
        unit_name: {
            label: 'Unit name',
            required: true,
            disabled: false,
            maxLength: 30
        },
        short_name: {
            label: 'Short name',
            required: true,
            disabled: false,
            maxLength: 30
        },

    }
    const [errorState, setErrorState] = useState({
        unit_name: "",
        short_name: '',
        applies_to: '',
    });
    const [searchText, setSearchText] = useState('');
    const [loader, setLoader] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [appliestoOptions, setAppliestoOptions] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [isAPICalled, setIsAPICalled] = useState({ currency_name: false, period_text: false });

    const [showDropdown, setShowDropdown] = useState(false);

    const [dropdownStates, setDropdownStates] = useState({
        period_text: false,
        currency_name: false,
    });

    const fetchStaticData = async () => {
        const appliestoresponse = await dataService.StaticData('applies_to.json');
        if(appliestoresponse.error === false){
            setAppliestoOptions(appliestoresponse.data.data.AppliesTo)
            setLoadingData(false)
        }
    }


    const handleInputClick = async (id) => {
        setSearchText('')
        setDropdownStates((prev) => {
            const newDropdownStates = { ...prev };
            Object.keys(newDropdownStates).forEach((key) => {
                newDropdownStates[key] = key === id ? !prev[id] : false;
            });
            return newDropdownStates;
        });
        setErrorState((prevState) => ({
            ...prevState,
            [id]: "",
        }));
    };

    const handleOptionClick = (option, id) => {
        setFormInput(prevInput => ({
            ...prevInput,
            [id]: option.value || option.Name || option.currency_name,
        }));
        setDropdownStates(prevStates => ({
            ...prevStates,
            [id]: false
        }));
    };


    const handleChange = (e) => {
        const { id, value } = e.target;
        let formValue = getValueTrim(id, value);
        setFormInput(prevData => ({
            ...prevData,
            [id]: formValue
        }));
        setErrorState((prevState) => ({
            ...prevState,
            [id]: "",
        }));
    };


    
    const getButtonDisabled = () => {
        return (deepEqual(editData, formInput)) ? true : false;
    };
    

    const handleCloseModel = () => {
        setIsSideModelOpen(false)
        setFormInput({
            unit_name: "",
            short_name: '',
            applies_to: '',
        })
        setErrorState({
            unit_name: "",
            short_name: '',
            applies_to: '',
        })
    }

    let seletedPayroll = JSON.parse(localStorage.getItem('selectedOption'))

    const handleUpdateButton = async () => {
        setLoader(true)
        if (FelidValidation(['unit_name', 'short_name'], setErrorState, formInput)) {
            setLoader(false)
            return
        }
        try {
            const body = 
            {
                "unit_name": (isEdit && editData.unit_name === formInput.unit_name) ? undefined : formInput.unit_name,
                "short_name": (isEdit && editData.short_name === formInput.short_name) ? undefined : formInput.short_name,
            }
            if (formInput.applies_to !== '') {
                body["applies_to"] = formInput.applies_to;
            }
            if (!isEdit) {
                body["payroll_id"] = seletedPayroll?.payroll_id;
            }
            let res = {}
            if (isEdit) {
                res = await dataService.UpdateUnit(editData.id, body, seletedPayroll?.payroll_id)
            } else {
                res = await dataService.CreateUnit(body)
            }
            setLoader(false)
            if (isEdit) {
                if (res.error === false) {
                    getUserList();
                    setToastmessage('Unit updated successfully');
                    handleCloseModel()}
                else {
                    switch (res.data.message) {
                    case 'Unit name already exists':
                        setErrorState((prevState) => ({
                            ...prevState,
                            unit_name: "Unit name already exists",
                        }));
                        break;
                    default:
                        setToastErrorMessage(res.data.message || 'Something went wrong.')
                        handleCloseModel()
                        break;
                    }}
            } else {
                if (res.data.success_status) {
                    getUserList();
                    setToastmessage(res.data.message)
                    handleCloseModel()
                } else {
                    switch (res.data.message) {
                    case 'Unit name already exists':
                        setErrorState((prevState) => ({
                            ...prevState,
                            unit_name: "Unit name already exists",
                        }));
                        break;
                    default:
                        setToastErrorMessage(res.data.message || 'Something went wrong.')
                        handleCloseModel()
                        break;
                    }
                }
            }

        } catch (error) {
            setLoader(false)
            handleCloseModel()
            setToastErrorMessage(error.message || 'Something went wrong.')
        }
    }

    useEffect(() => {
        fetchStaticData()
    }, [])
    
    console.log(isSideModelOpen, 'isSideModelOpen')

    return (
        <DrawerSideBar
            tittle={`${isEdit ? 'Update' : 'Add'} Unit`}
            isOpen={isSideModelOpen}
            closeDrawerRight={handleCloseModel}
        >

            {Object.keys(InputItems).map((item) => (
                <div className='w-[309px] mr-6' key={item}>
                    <InputWithLabel
                        id={item}
                        label={InputItems[item].label}
                        type="text"
                        autocomplete="off"
                        required={InputItems[item].required}
                        // handlePasswordFocus={null}
                        handleChange={handleChange}
                        formInput={formInput}
                        errorState={errorState}
                        showPassword={true}
                        maxLength={InputItems[item].maxLength || 30}
                        inputDisable={InputItems[item].disabled}
                    />
                </div>))}

            <div className='w-[306px] mr-6'>
                <CommonDropdown
                    id="applies_to"
                    label="Applies to"
                    type="text"
                    autocomplete="off"
                    required={false}
                    handleChange={handleChange}
                    formInput={formInput}
                    errorState={errorState}
                    options={appliestoOptions}
                    handleOptionClick={handleOptionClick}
                    showDropdown={showDropdown.currency_name}
                    setShowDropdown={setShowDropdown}
                    setDropdownStates={setDropdownStates}
                    handleInputClick={handleInputClick}
                    setSearchText={setSearchText}
                    displayKey={"Name"}
                    dropdownStates={dropdownStates}
                    searchText={searchText}
                    searchbar={false}
                    dropdwonHeight={"h-[199px]"}
                    loadingData={loadingData}
                    dropdownDisable={false}
                    customDataTestId={'Name'}
                />
            </div>
            <Button
                dataTestID={isEdit ? 'Update' : 'Add'}
                disabled={loader || !isSideModelOpen|| getButtonDisabled()}
                handleButtonClick={handleUpdateButton}
                ButtonName={isEdit ? 'Update' : 'Add'}
                loader={loader}
            />
        </DrawerSideBar>
    )
}
