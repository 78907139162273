import Lottie from 'lottie-react';
import React, { useState, useRef, useEffect, useContext } from 'react';
import blockImage from "./search_not_found.json";
import GlobalContext from './context';

function CountryFilter({formInput, setFormInput, errorState, setErrorState, getCountryNameById, sortedData}) {
    // Sort countries alphabetically
    const [searchText, setSearchText] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [focused, setFocused] = useState(false);
    const [countryFocused,setCountryFocused] = useState(false)
    const countryRef = useRef(null);
    const [countryValues, setCountryValues] = useState(sortedData)
    const [filteredCountries, setFilteredCountries] = useState(sortedData)

    /* The `useEffect` hook in the provided code snippet is used to handle the functionality of closing
    the dropdown menu when a user clicks outside of it. Here's a breakdown of what the `useEffect`
    is doing: */
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setShowDropdown(false);
                setCountryFocused(false)
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            setFormInput((prevState) => ({
                ...prevState,
                ['country']: '',
            }))
        };
    }, []);

    /**
     * The handleInputClick function toggles the visibility of a dropdown and sets focus to true.
     */
    const handleInputClick = () => {
        setCountryValues(sortedData)
        setFilteredCountries(sortedData)
        setSearchText('')
        setShowDropdown(!showDropdown);
        setFocused(true)
    };

    /**
     * The function `handleSearchInputChange` updates the search text based on the input value from an
     * event.
     */
    const handleSearchInputChange = (event) => {
        setSearchText(event.target.value);
        filterCountry(event.target.value)
    };

    /**
     * The function `handleCountrySelect` updates the form input state with a selected country name,
     * resets error states for country-related fields, clears search text, and hides the dropdown menu.
     */
    const handleCountrySelect = (countryId) => {
        setFormInput((prevState) => ({
            ...prevState,
            ['country']: countryId,
        }));
        setErrorState((prevState) => ({
            ...prevState,
            ['country']: "",
        }));
        setSearchText('');
        setShowDropdown(false);
    };
    const filterCountry = (search)=>{
        let filteredValue = countryValues.filter(country =>
            country.country_name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredCountries(filteredValue)
    }

    return (
        <div className="country-select-dropdown mb-[43px] w-full" ref={countryRef}>
            <div className='relative'>
                <label
                    className={`absolute transition-all left-3 bg-white font-normal 
                    ${errorState['country'] !== "" ? "text-[#EA3829]" : "text-[#2F2E38]"}
                    ${formInput.country !== '' ? "top-[-8px] text-xs" : "top-1/2 -translate-y-1/2 text-[14px]"}`}
                >
                    Country
                </label>
                <div>
                    <input
                        type="text"
                        data-testid="filter-country-dropdown"
                        value={getCountryNameById(formInput.country)}
                        onClick={()=>handleInputClick()}
                        className={`text-[#2F2E38] border rounded text-[14px] font-normal 
                        w-full py-3.5 px-3 outline-0 focus-visible:outline-0 
                    input-no-text
                        ${errorState['country'] !== ""
            ? "border-[#EA3829]":
            formInput['country']!=='' ? "border-[#5E5C70]" : "border-[#ACABBA]"}
                    `}
                    />
                    <div className='absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer' onClick={()=>handleInputClick()}>
                        {!showDropdown?
                            <img src='/Images/chevron-down.svg' alt='icon' loading='lazy'/>:
                            <img src='/Images/chevron-up.svg' alt='icon' loading='lazy'/>}
                    </div>
                </div>
                <p className="text-[#EA3829] text-xs font-normal pt-[2px] pl-3">
                    {errorState['country']}
                </p>
            </div>
            <div className='relative'>
                <div 
                    className={`dropdown-container z-[11] absolute top-0 left-0 pt-3 bg-white  w-full
                    border rounded border-[#ACABBA] ${showDropdown ? 'open opacity-100' : 
            'closed opacity-0 max-h-0 overflow-hidden'}`}>
                    <div className="dropdown-content w-full relative">
                        <div className={`flex items-center border 
                        ${searchText!==''|| countryFocused ? "border-[#5E5C70]" : "border-[#ACABBA]"} 
                        py-2.5 rounded px-3 shadow-s4 mx-3 mb-3 
                        sticky top-0 left-0 w-auto bg-white`}>
                            {searchText || countryFocused?
                                <img src='/Images/search-hightight.svg' alt='icon' />:
                                <img src='/Images/search.svg' alt='icon' />}
                            <input
                                type="text"
                                data-testid="filter-search"
                                value={searchText}
                                onClick={()=>setCountryFocused(true)}
                                onChange={handleSearchInputChange}
                                placeholder="Search"
                                className=' outline-0 w-full ml-2 placeholder:text-[#918FA3] text-[#2F2E38] text-sm font-normal'

                            />
                        </div>
                        <div className='h-[199px] overflow-auto customScrollbar mr-2.5'>
                            {filteredCountries.length > 0 ? (
                                filteredCountries.map((country,i) => (
                                    <>
                                        <div
                                            key={country.name}
                                            data-testid={`filter-country-dropdown-${i}`}
                                            onClick={() => handleCountrySelect(country.country_id)}
                                            className="dropdown-item cursor-pointer text-sm font-normal 
                                            text-[#2F2E38] px-3 py-2.5 
                                    hover:bg-[#EEEDF8] flex items-center"
                                        >
                                            <p className='mr-2 w-[29px] h-5'>{country.flag}</p> 
                                            {country.country_name}
                                        </div>
                                    </>
                                ))
                            ) : (
                                <div className="error-message h-full flex flex-col items-center justify-center">
                                    <Lottie
                                        animationData={blockImage}
                                        loop={true}
                                        className="w-[90px] mx-auto"
                                    />
                                    <p className='text-sm font-normal text-[#2F2E38] text-center pb-5 pt-3'>
                                        We could not find any matches for your search.</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CountryFilter;
