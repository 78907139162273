import React from 'react';

function ItemShimmer() {
    const shimmerRows = [];
    return (
        <div className="shimmer">
            <div className="shimmer-line"></div>
            <div className="shimmer-line"></div>
            <div className="shimmer-line"></div>
        </div>
    );
}

export default ItemShimmer;