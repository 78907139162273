// aws-exports.js
const endPoints = {
    UserProfile: 'user-management/user-profile',
    CurrencyConversion: 'currency-management/currency-conversion',
    payroll: 'payroll-management',
    user: 'user-management',
    userResetPassword: 'user-management/reset-password',
    CurrencyType : 'currency-management/currency-type'
};

export default endPoints;