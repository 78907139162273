import React from "react";

export default function Loader({
    height
}) {
    return <div className={`loader-container ${height}`}>
        <div className="spinner"></div>
        <p className="loading-text">Loading...</p>
    </div>;
}
