/* eslint-disable security/detect-possible-timing-attacks */
import React, { useContext, useEffect, useState } from 'react';
import ChangePasswordForm from './changePasswordForm';
import { useNavigate } from 'react-router';
import GlobalContext from '../../CommonComponent/context';
import { dataService } from '../../services/dataService';
import { signOut } from '@aws-amplify/auth';
import { logout } from '../auth/authSlice';
import { useDispatch } from 'react-redux';
import { fetchAuthSession } from 'aws-amplify/auth';

export default function ChangePassword() {
    const { setToastmessage } = useContext(GlobalContext)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [currentPassword, setCurrentPassword] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState(null);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [passwordValid, setPasswordValid] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        symbol: false,
    });
    const [loader, setLoader] = useState(false)
    const [showFormate, setShowFormate] = useState(true);
    const [loading, setLoading] = useState(true)
    const [accessToken, setAccessToken] = useState(null);

    /* The `useEffect` hook in the provided code snippet is responsible for updating the `showFormate`
    state based on the values of `password`, `confirmPassword`, and `passwordValid` whenever there
    is a change in these dependencies. */
    useEffect(() => {
        if (password.length === 0) {
            setShowFormate(false);
        }
        else if (passwordValid.length === false || passwordValid.uppercase === false || passwordValid.lowercase === false
            || passwordValid.number === false || passwordValid.symbol === false) {
            setShowFormate(true);
        }
        if (passwordValid.length === true && passwordValid.lowercase === true && passwordValid.number === true &&
            passwordValid.symbol === true && passwordValid.uppercase === true) {
            setTimeout(() => {
                setShowFormate(false);
            }, 1500);
        } if (password.length === 0 && confirmPassword.length === 0) {
            setPasswordError(null);
        }
    }, [password, confirmPassword])

    useEffect(() => {
        const getAccessToken = async () => {
            try {
                const session = await fetchAuthSession();
                setAccessToken(session.tokens?.accessToken?.toString());
            } catch (error) {
                console.error('Error fetching access token:', error);
            }
        };

        getAccessToken();
    }, []);

    /**
     * The clickHandler function is used to handle a click event, validate a password, encrypt it, and
     * then confirm the password.
     */
    const clickHandler = async (e) => {
        e.preventDefault();
        setLoader(true)
        if (currentPassword.length !== 0 && password.length !== 0 && confirmPassword.length !== 0) {
            let valid = true;
            if (password !== confirmPassword) {
                setPasswordError('Passwords do not match');
                valid = false
                setLoader(false)
            }
            if (valid) {
                const body = {
                    "current_password": currentPassword,
                    "new_password": password,
                    "confirm_password": confirmPassword,
                    "reset_type": "update",
                    "access_token": accessToken
                }
                const data = await dataService.UpdatePassword(body)
                if (data.data.success_status === false) {
                    setPasswordError(data.data.message);
                    setLoader(false)
                }
                else {
                    setPassword('')
                    setCurrentPassword('')
                    setConfirmPassword('')
                    await signOut();
                    navigate('/')
                    setToastmessage('Password updated successfully')
                    setLoader(false)
                    dispatch(logout());
                }
            }
        } else {
            setPasswordError('This field is mandatory');
            setLoader(false)
        }
    }

    /**
    * The function `handlePasswordChange` is used to handle changes in password input fields,
    * validating the new password based on length, uppercase, lowercase, number, and symbol
    * requirements.
    */
    const handlePasswordChange = (e) => {
        const { id, value } = e.target;
        let formValue = value.replace(/\s/g, '');
        setPasswordError(null);
        if (id == 'current_password') {
            setCurrentPassword(formValue.trim());
        }
        else if (id == 'new_password') {
            setPasswordValid({
                length: formValue.length >= 8,
                uppercase: /[A-Z]/.test(formValue),
                lowercase: /[a-z]/.test(formValue),
                number: /\d/.test(formValue),
                symbol: /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-|='"`']/g.test(formValue),
            });
            setPassword(formValue.trim());
        } else {
            setConfirmPassword(formValue.trim());
        }
    }

    return (
        <div className='bg-white  rounded-tl-lg'>
            <div className='flex justify-center items-center h-[calc(100vh-51px)]'>
                <ChangePasswordForm clickHandler={clickHandler}
                    passwordError={passwordError} password={password}
                    confirmPassword={confirmPassword} showPassword={showPassword}
                    showConfirmPassword={showConfirmPassword} showFormate={showFormate}
                    loader={loader} setShowPassword={setShowPassword}
                    setShowConfirmPassword={setShowConfirmPassword} handlePasswordChange={handlePasswordChange}
                    passwordValid={passwordValid}
                    currentPassword={currentPassword} setCurrentPassword={setCurrentPassword}
                    showCurrentPassword={showCurrentPassword} setShowCurrentPassword={setShowCurrentPassword} />
            </div>
        </div>
    );
}
