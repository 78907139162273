
export const payrolls = [
    { id: 'payPro', label: 'PayPro' },
    { id: 'payroll_partners', label: 'Payroll Partners' },
    { id: 'payroll_prodigy', label: 'Payroll Prodigy' },
    { id: 'cashFlow_payroll', label: 'CashFlow Payroll' },
    { id: 'salary_solutions', label: 'Salary Solutions' },
    { id: 'salaryPro', label: 'SalaryPro' },
    { id: 'payMaster', label: 'PayMaster' },
    { id: 'salarySync', label: 'SalarySync' },
    { id: 'paySystem', label: 'PaySystem' },
    { id: 'payPro', label: 'PayPro' },
    { id: 'payroll_partners', label: 'Payroll Partners' },
    { id: 'payroll_prodigy', label: 'Payroll Prodigy' },
    { id: 'cashFlow_payroll', label: 'CashFlow Payroll' },
    { id: 'salary_solutions', label: 'Salary Solutions' },
    { id: 'salaryPro', label: 'SalaryPro' },
    { id: 'payMaster', label: 'PayMaster' },
    { id: 'salarySync', label: 'SalarySync' },
    { id: 'paySystem', label: 'PaySystem' },
    { id: 'payPro', label: 'PayPro' },
    { id: 'payroll_partners', label: 'Payroll Partners' },
    { id: 'payroll_prodigy', label: 'Payroll Prodigy' },
    { id: 'cashFlow_payroll', label: 'CashFlow Payroll' },
    { id: 'salary_solutions', label: 'Salary Solutions' },
    { id: 'salaryPro', label: 'SalaryPro' },
    { id: 'payMaster', label: 'PayMaster' },
    { id: 'salarySync', label: 'SalarySync' },
    { id: 'paySystem', label: 'PaySystem' },
    { id: 'payPro', label: 'PayPro' },
    { id: 'payroll_partners', label: 'Payroll Partners' },
    { id: 'payroll_prodigy', label: 'Payroll Prodigy' },
    { id: 'cashFlow_payroll', label: 'CashFlow Payroll' },
    { id: 'salary_solutions', label: 'Salary Solutions' },
    { id: 'salaryPro', label: 'SalaryPro' },
    { id: 'payMaster', label: 'PayMaster' },
    { id: 'salarySync', label: 'SalarySync' },
    { id: 'paySystem', label: 'PaySystem' },
    { id: 'payPro', label: 'PayPro' },
    { id: 'payroll_partners', label: 'Payroll Partners' },
    { id: 'payroll_prodigy', label: 'Payroll Prodigy' },
    { id: 'cashFlow_payroll', label: 'CashFlow Payroll' },
    { id: 'salary_solutions', label: 'Salary Solutions' },
    { id: 'salaryPro', label: 'SalaryPro' },
    { id: 'payMaster', label: 'PayMaster' },
    { id: 'salarySync', label: 'SalarySync' },
    { id: 'paySystem', label: 'PaySystem' },
    { id: 'payPro', label: 'PayPro' },
    { id: 'payroll_partners', label: 'Payroll Partners' },
    { id: 'payroll_prodigy', label: 'Payroll Prodigy' },
    { id: 'cashFlow_payroll', label: 'CashFlow Payroll' },
    { id: 'salary_solutions', label: 'Salary Solutions' },
    { id: 'salaryPro', label: 'SalaryPro' },
    { id: 'payMaster', label: 'PayMaster' },
    { id: 'salarySync', label: 'SalarySync' },
    { id: 'paySystem', label: 'PaySystem' },
];

export const items = [
    { id: 'units', label: 'Units' },
    { id: 'currency_conversion', label: 'Currency conversion' },
    { id: 'coinage', label: 'Coinage' },
    { id: 'other_leaves', label: 'Other leaves' },
    { id: 'age_distribution', label: 'Age distribution' },
    { id: 'wage_distribution', label: 'Wage distribution' },
    { id: 'service_awards', label: 'Service awards' },
    { id: 'gratuity_ranges', label: 'Gratuity ranges' },
    { id: 'accounting_periods', label: 'Accounting Periods' },
    { id: 'pay_periods', label: 'Pay periods' },
];

export const organizationalItems = [
    { id: 'cost_centres', label: 'Cost centres' },
    { id: 'departments', label: 'Departments' },
    { id: 'internal_grades', label: 'Internal grades' },
    { id: 'discharge_reasons', label: 'Discharge reasons' },
    { id: 'nationality', label: 'Nationality' },
    { id: 'nec_grades', label: 'NEC grades' },
    { id: 'industries', label: 'Industries' },
    { id: 'occupations', label: 'Occupations' },
    { id: 'pay_points', label: 'Pay points' },
    { id: 'positions', label: 'Positions' },
    { id: 'funeral_policies', label: 'Funeral policies' },
    { id: 'general_ledger', label: 'General ledger' },
    { id: 'medical_aid_schemes', label: 'Medical aid schemes' },
];
export const functions = [
    { id: 'create_payroll', label: 'Create Payroll' },
    { id: 'update_payroll', label: 'Update Payroll' },
    { id: 'add_employee', label: 'Add Employee' },
    { id: 'discharge_employee', label: 'Discharge Employee' },
    { id: 'update_employee', label: 'Update Employee' },
    { id: 'suspend_employee', label: 'Suspend Employee' },
    { id: 'activate_employee', label: 'Activate Employee' },
    { id: 'delete_employee', label: 'Delete Employee' },
];
