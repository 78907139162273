/* eslint-disable max-len */
import React, { useState, useContext } from 'react'
import DrawerSideBar from '../../../CommonComponent/DrawerSideBar'
import { getValueTrim } from '../../../CommonComponent/InputValidation';
import { dataService } from '../../../services/dataService';
import InputWithLabel from '../../../CommonComponent/inputField';
import Button from '../../../CommonComponent/Button';
import { FelidValidation } from '../../../CommonComponent/FelidValidation';
import GlobalContext from '../../../CommonComponent/context';
import endPoints from '../../../services/endpoints';

export default function AddOrUpdateCurrencyType({formInput,payRollData,
    setFormInput, isSideModelOpen, setIsSideModelOpen, getUserList, isEdit, editData}) {
    const { setToastmessage,setToastErrorMessage } = useContext(GlobalContext)
    
    const InputItems = {
        currency_name: {
            label : 'Currency name',
            required : true,
            maxLength: 30
        },
        symbol : {
            label : 'Symbol',
            required : true,
            maxLength: 3
        },
        currency_code: {
            label : 'Currency code',
            required : true,
            maxLength : 4
        },

    }
    const [errorState, setErrorState] = useState({
        currency_name: "",
        symbol: '',
        currency_code: ''
    });
    const [loader, setLoader] = useState(false)

    const handleChange = (e) => {
        const { id, value } = e.target;
    
        let formValue = getValueTrim(id, value);
        setFormInput(prevData => ({ 
            ...prevData, 
            [id]: formValue 
        }));
    
        // Clear any previous error for this field
        setErrorState((prevState) => ({
            ...prevState,
            [id]: "",
        }));
    };
    
    
    const getButtonDisabled = () => {
        return ((editData.currency_name !== formInput.currency_name || editData.symbol !== formInput.symbol || editData.currency_code !== formInput.currency_code) && !loader) ? 
            false : true
    }
    const handleCloseModel = () => {
        setIsSideModelOpen(false)
        setFormInput({
            currency_name: "",
            symbol: '',
            currency_code: '',
            conversion_rate: '',
            period_text: payRollData.period_text,
            currency_id:'',
            pay_period_id: payRollData.pay_period_id
        })
        setErrorState({
            currency_name: "",
            symbol: '',
            currency_code: '',
            conversion_rate: '',
            period_text: ''
        })
    }
    const handleUpdateButton = async () => {
        setLoader(true)
        if(FelidValidation(['currency_name','symbol','currency_code'], setErrorState, formInput)) {
            setLoader(false)
            return
        }
        try {
            const body = {
                "payroll_id": isEdit ? undefined : payRollData.payroll_id,
                "currency_name": (isEdit && editData.currency_name === formInput.currency_name) ? undefined : formInput.currency_name,
                "currency_code": (isEdit && editData.currency_code === formInput.currency_code) ? undefined : formInput.currency_code,
                "symbol": (isEdit && editData.symbol === formInput.symbol) ? undefined :  formInput.symbol
            }
            let res = {}
            if(isEdit) {
                res = await dataService.patchAPI(`${endPoints.CurrencyType}/${payRollData?.payroll_id}/${editData?.currency_id}`, 
                    body)
            } else {
                res = await dataService.postAPI(`${endPoints.CurrencyType}`, body)
            }
            setLoader(false)
            if(isEdit && !res.error) {
                getUserList();
                setToastmessage('Currency type updated successfully');
                handleCloseModel()
            } else {
                if(res.data.status) {
                    getUserList();
                    setToastmessage(res.data.message)
                    handleCloseModel()
                } else {
                    if(res.data.error === 'ConflictError') {
                        setErrorState((prevState) => ({
                            ...prevState,
                            'currency_code' : res.data.message.currency_code === undefined  ? '' :  res.data.message.currency_code,
                            'currency_name' : res.data.message.currency_name === undefined ? '' : res.data.message.currency_name
                        }));
                    } else {
                        setToastErrorMessage(res.data.message || 'Something went wrong.')
                    }
                }
            }
        } catch (error) {
            setLoader(false)
            handleCloseModel()
            setToastErrorMessage(error.message || 'Something went wrong.')
        }
    }
    
    return (
        <DrawerSideBar 
            tittle={`${isEdit ? 'Update' : 'Add'} Currency Type`} 
            isOpen={isSideModelOpen} 
            closeDrawerRight={handleCloseModel}
        >
            {Object.keys(InputItems).map((item) => (
                <div className='w-[309px] mr-6'key={item}>
                    <InputWithLabel
                        id={item}
                        label={InputItems[item].label}
                        type="text"
                        autocomplete="off"
                        required={InputItems[item].required}
                        // handlePasswordFocus={null}
                        handleChange={handleChange}
                        formInput={formInput}
                        errorState={errorState}
                        showPassword={true}
                        maxLength={InputItems[item].maxLength || 30}
                        inputDisable={InputItems[item].disabled}
                    />
                </div>))}
            <Button
                dataTestID={isEdit ? 'Update' : 'Add'}
                disabled={getButtonDisabled()}
                handleButtonClick={handleUpdateButton}
                ButtonName={isEdit? 'Update' : 'Add'}
                loader={loader}
            />
        </DrawerSideBar>
    )
}
