import React from 'react';
import Lottie from "lottie-react";
import blockImage from "../../../CommonComponent/Animation - 1710320861221.json";
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

export default function AccountBlock({ setIsBlocked, isBlocked, setIsOtpSent }) {
    const navigate = useNavigate('')
    let animationProps = {
        initial: { rotateY: 90 },
        animate: { rotateY: 0, transition: { duration: 0.5 } },
    };
    return (
        <motion.div {...animationProps} className=" custom-width:w-[540px] w-[490px] 
        bg-[#FFFFFF] px-[42px] custom-width:py-[40px] py-8 ">
            <Lottie
                animationData={blockImage}
                loop={true}
                className="w-[250px] mx-auto"
            />
            <h2 className="text-[#2F2E38] text-[24px] font-bold text-center">
                Account temporarily blocked
            </h2>
            <p className="text-[16px] text-[#2F2E38] font-normal mt-3.5 mb-[34px] text-center">
                Your account has been temporarily blocked due to multiple failed attempts.
                Please log in after {isBlocked.match(/\d+/)} minutes.
            </p>
            <p className="text-[16px] text-[#2F2E38] font-normal mt-3.5 mb-[34px] text-center">
                For assistance, contact us at{" "} <br />
                <a href="#" className="text-[#3892F3]">
                    support@belinamail.com
                </a>{" "}
                or{" "}
                <a href="#" className="text-[#3892F3]">
                    08677 799799
                </a>
            </p>
            <div className="flex justify-center">
                <button
                    data-testid='back-btn'
                    type="button"
                    onClick={() => { setIsBlocked(''); setIsOtpSent(false); navigate('/') }}
                    className="text-base text-[#29275F] font-bold hover:text-[#4B42A3]"
                >
                    Back to Login
                </button>
            </div>
        </motion.div>
    );
}
