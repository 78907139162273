import React from 'react';

const StatusBadge = ({ status }) => {
    const getStatusStylesAndText = (status) => {
        switch (status) {
        case 'active':
            return {
                containerClass: 'bg-[#DEEDE1] text-[#008F5D]',
                dotClass: 'bg-[#008F5D]',
                text: 'Active',
            };
        case 'inactive':
            return {
                containerClass: 'bg-[#FED7DD] text-[#EA3829]',
                dotClass: 'bg-[#EA3829]',
                text: 'Inactive',
            };
        case 'creating':
            return {
                containerClass: 'bg-[#D6D5DD] text-[#5E5C70]',
                dotClass: 'bg-[#5E5C70]',
                text: 'In Progress',
            };
        default:
            return {
                containerClass: 'bg-gray-200 text-gray-500', // Default styles
                dotClass: 'bg-gray-500',
                text: 'Unknown',
            };
        }
    };

    const { containerClass, dotClass, text } = getStatusStylesAndText(status);

    return (
        <div className={`px-[8px] py-1 text-[14px] leading-5 font-normal break-all 
        w-max rounded-full flex items-center justify-center h-fit ${containerClass}`}>
            <span
                id='status'
                className={`inline-block w-[6px] h-[6px] rounded-full mr-2 ${dotClass}`}
            ></span>
            {text}
        </div>
    );
};

export default StatusBadge;
