import React from 'react'
import { motion } from 'framer-motion';

export default function EmptyScreen({EmptyScreenTittle, handleAdd}) {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{
                opacity: 1,
            }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            className='flex flex-col items-center justify-start pt-[30px]'>
            <img src='/Images/recordNotFound.svg' alt='icon' loading='lazy' />
            <p className='text-[#2F2E38] text-[18px] font-semibold pb-1 pt-[30px]'>
                Sorry, there are no {EmptyScreenTittle}s available at the moment.</p>
            <p className='text-[#2F2E38] text-[18px] font-semibold pb-6'>
                To include a {EmptyScreenTittle}, click the button below.</p>
            <button type='button' onClick={handleAdd}
                className='bg-[#29275F] rounded-lg px-5 py-2.5 text-[#FCFBFE] 
                text-base font-bold w-[110px] hover:bg-[#4B42A3] disabled:bg-[#CCC9E9] flex justify-center items-center'
                data-testid="aadd">
                <img src='/Images/plus-add-icon.svg' alt='icon' className='mr-2.5' loading='lazy'/>
                Add</button>
        </motion.div>
    )
}
