/* eslint-disable max-len */
/* eslint-disable security/detect-object-injection */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dataService } from '../../../services/dataService';
import { handleCurrentPage, handlePagination } from '../../../Redux/paginationSlice';
import Debounce from '../../../CommonComponent/debounce';
import PageNavigation from '../../../CommonComponent/pageNavigation';
import GlobalContext from '../../../CommonComponent/context';
import ActionModal from '../../../CommonComponent/actionModal';
import TableHeader from '../../../CommonComponent/Table/TableHeader';
import endPoints from '../../../services/endpoints';
import UnitsAddOrUpdateModel from './UnitsAddOrUpdateModel';

export default function UnitsListing() {
    const { setToastmessage, setToastErrorMessage,isSwitchPayroll,setIsSwitchPayroll } = useContext(GlobalContext)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams({ limit: 10, sort: 'true', key: 'unit_name', order: 'asc' });
    const [searchValue, setSearchValue] = useState(searchParams.get('keyword') !== null ?
        decodeURIComponent(searchParams.get('keyword')) : '')
    const [sort, setSort] = useState(`${searchParams.get('key') !== null ? decodeURIComponent(searchParams.get('key')) : ''}+${searchParams.get('order') !== null ? decodeURIComponent(searchParams.get('order')) : ''}`)
    const [loading, setLoading] = useState(true);
    const [userList, setUserList] = useState({})
    const [nextPage, setNextPage] = useState(true)
    const [prevPageExist, setPrevPage] = useState(false)
    const currentPage = useSelector((state) => state.paginationConfig.currentPage);
    const [actionModal, setActionModal] = useState('')
    const [editactionModal, setEditActionModal] = useState('')
    const dispatch = useDispatch();
    const paginationData = useSelector((state) => state.paginationConfig.paginationData);
    const [selectedUnit, setSelectedUnit] = useState({})
    const [spinnerLoader, setSpinnerLoader] = useState(false)
    const [searchExist, setSearchExist] = useState(true)
    const [noRecord, setNoRecord] = useState(false)


    const [isSideModelOpen, setIsSideModelOpen] = useState(false)
    const [editData, setEditData] = useState({})
    const [formInput, setFormInput] = useState({
        unit_name: "",
        short_name: '',
        applies_to: '',
    });

    let seletedPayroll = JSON.parse(localStorage.getItem('selectedOption'))

    /* The above code is a React function component that defines a function `getUserList` using
    the `useCallback` hook. This function is an asynchronous function that fetches user group data
    based on certain search parameters. */
    const getUserList = useCallback(async () => {
        setLoading(true);
        let endpoint = `?limit=10`;
        if (searchParams.get('key') !== null) {
            endpoint += `&sort=${encodeURIComponent(sort)}`;
        }
        if (searchParams.get('keyword') !== null) {
            endpoint += `&search=${searchParams.get('keyword')}`;
        }
        if (searchParams.get('last_element') !== null) {
            endpoint += `&last_element=${searchParams.get('last_element')}`;
        }
        if (searchParams.get('pay_period_id') !== null) {
            endpoint += `&pay_period_id=${searchParams.get('pay_period_id')}`;
        }
        setIsSwitchPayroll(false)
        const data = await dataService.ListUnits(`${endpoint}`, seletedPayroll?.payroll_id);
        if (data.data.success_status === true) {
            if (data.data.status == "NO_DATA") {
                setNoRecord(true);
                setUserList(data.data.data);
            } else {
                setNoRecord(false);
                setUserList(data.data.data);
            }
            if (data.data.status == 'NO_MATCH') {
                setSearchExist(false);
                setUserList(data.data.data);
            } else {
                setSearchExist(true);
                setUserList(data.data.data);
            }
            if (searchValue == '' && data.data.data.length == 0) {
                setNextPage(false);
            } else {
                setUserList(data.data.data);
                setNextPage(true);
            }
            if (data.data.last_element === undefined || data.data.last_element === null) {
                setNextPage(false);
            } else {
                setNextPage(true);
                dispatch(handlePagination({
                    ...paginationData,
                    [currentPage + 1]: data.data.last_element,
                }));
            }
            setTimeout(() => {
                setLoading(false);
            }, 300)
        }
        else if (data.data.success_status === false) {
            let params = Object.fromEntries(searchParams);
            if (Object.keys(paginationData).length > 2) {
                dispatch(handleCurrentPage(currentPage - 1));
                const lastKey = await paginationData[currentPage - 1];
                params["last_element"] = lastKey;
                setSearchParams({ ...params });
            } else {
                dispatch(handlePagination({}));
                dispatch(handleCurrentPage(1));
                setNextPage(false)
                setPrevPage(false)
                delete params.last_element;
                setSearchParams({ ...params })
            }
        } else {
            if (data.data.message.sort !== undefined && data.data.message?.sort[0]?.includes('Invalid sort value') ||
                data.data.message.includes('Please provide valid input') || data.data.message.includes("Must be one of: unit_name+desc, unit_name+asc.") ||
                data.data.message.includes("Invalid last_element token") || data.data.message.includes("Role must be 'user', 'admin'.") || data.data.message.includes("User not found")) {
                navigate('/page-not-found');
            }
        }
    }, [searchParams,isSwitchPayroll]);

    useEffect(() => {
        getUserList()
    }, [getUserList])

    const handleAdd = (data, key) => {
        if (key === 'Update') {
            if (data?.system_generated === false) {
                setEditData(prevData => ({
                    ...prevData,
                    ...data,
                    applies_to: data.applies_to === null ? '' : data.applies_to 
                }));
                setFormInput(prevData => ({
                    ...prevData,
                    ...data,
                    applies_to: data.applies_to === null ? '' : data.applies_to 
                }))
                setIsSideModelOpen(true)
            } else {
                setEditData(prevData => ({
                    ...prevData,
                    ...data,
                    applies_to: data.applies_to === null ? '' : data.applies_to 
                }));
                setEditActionModal(key);
            }
        } else if (key === 'Delete') {
            setActionModal(key);
            setEditData(data)
        } else {
            setIsSideModelOpen(true)
            setEditData({})
        }
    }


    /**
     * The above functions handle pagination by updating the current page and search parameters for
     * fetching data in a React application.
     */
    const handlePrevPage = async () => {
        setNextPage(true)
        let params = Object.fromEntries(searchParams);
        if (currentPage === 2) {
            setPrevPage(false)
            dispatch(handleCurrentPage(currentPage - 1))
            delete params.last_element
            setSearchParams({ ...params })
        }
        else {
            dispatch(handleCurrentPage(currentPage - 1))
            setPrevPage(true)
            const lastKey = paginationData[currentPage - 1]
            if (lastKey) {
                params['last_element'] = lastKey
                setSearchParams({ ...params })
            }
        }
    }
    /**
    * The function `handleNextPage` increments the current page number, sets a flag for the previous
    * page if the current page is greater than or equal to 1, and updates search parameters with
    * pagination data for the next page.
    */
    const handleNextPage = async () => {
        let params = Object.fromEntries(searchParams);
        if (currentPage >= 1) {
            setPrevPage(true)
        }
        dispatch(handleCurrentPage(currentPage + 1))
        params['last_element'] = paginationData[currentPage + 1]
        setSearchParams({ ...params })
    }


    const handleDelete = async () => {
        if (editData.system_generated === true) {
            setActionModal('');
        } else {
            try {
                setSpinnerLoader(true);
                if (editData.is_assigned) {
                    setActionModal('');
                    setSpinnerLoader(false);
                    setEditData({});
                    return;
                }
                const response = await dataService.DeleteUnit(seletedPayroll?.payroll_id, editData.id);
                if (response.error === false) {
                    setSpinnerLoader(false);
                    setActionModal('');
                    getUserList();
                    setToastmessage('Unit deleted successfully');
                } else {
                    setSpinnerLoader(false);
                    setActionModal('');
                    setEditData({})
                    setToastErrorMessage(response.data.data.message || 'Something went wrong!');
                }
            } catch (error) {
                setSpinnerLoader(false);
                setActionModal('');
                setToastErrorMessage(error.message || 'An error occurred!');
            }
        }

    };

    const handleEditDelete = () => {
        setEditActionModal('');
    }

    const getModalText = () => {
        if (editData.system_generated && actionModal === 'Delete') {
            return `${editData.unit_name} cannot be deleted as it is generated by the system.`
        } else if (editData.system_generated && actionModal === 'Update') {
            return `${editData.unit_name} cannot be updated as it is generated by the system.`
        }
        else {
            return <>
                <p>
                    {`Are you sure you want to delete the Unit`}
                    <strong> {editData.unit_name}</strong>?
                </p>
                <p>This action cannot be undone.</p>
            </>
        }
    }

    const getEditModalText = () => {
        return `${editData.unit_name} cannot be updated as it is generated by the system.`
    }

    


    const handleSort = (e, key) => {
        setNextPage(true)
        setPrevPage(false)
        dispatch(handleCurrentPage(1))
        let params = Object.fromEntries(searchParams);
        delete params.last_element;
        if (searchParams.get('sort') === false || searchParams.get('sort') === null) {
            params['sort'] = 'true'
            params['key'] = `${key}`
            params['order'] = `asc`
            setSort(key + '+asc')
        } else {
            if (searchParams.get('key').includes(key)) {
                if (searchParams.get('order') === 'asc') {
                    params['order'] = `desc`
                    setSort(key + '+desc')
                } else {
                    params['order'] = `asc`
                    setSort(key + '+asc')
                }
            }
            else {
                params['key'] = key
                params['order'] = `asc`
                setSort(key + '+asc')
            }
        }
        setSearchParams({ ...params })
    }

    const tableHederData = {
        unit_name: {
            headerName: 'Unit name',
            key: 'unit_name',
            dataTestId: 'listing_unit_name',
            isSort: true,
            thClass: 'min-w-[215px]',
            spanClass: 'items-center'
        },
        short_name: {
            headerName: 'Short name',
            key: 'short_name',
            dataTestId: 'short_name',
            isSort: false,
            thClass: 'min-w-[150px]',
            spanClass: 'items-center'
        },
        applies_to: {
            headerName: 'Applies to',
            key: 'applies_to',
            dataTestId: 'applies_to',
            isSort: false,
            thClass: 'min-w-[170px]',
            spanClass: 'items-center'
        },
        actions: {
            headerName: 'Actions',
            key: 'actions',
            dataTestId: 'actions',
            isSort: false,
            thClass: 'min-w-[119px] bg-[#F1F1F4]',
            spanClass: 'items-center justify-center pr-1.5',
            actionsList: ['Update', 'Delete'],
            sticky: false
        }
    }

    console.log(editData, 'kkkkk')

    return (
        <>
            {!noRecord &&
                <div className='flex items-center justify-end'>
                    <button
                        className='flex justify-center items-center py-2.5 px-5 w-[110px] h-10
                                        rounded-lg bg-[#29275F] text-base text-[#FCFBFE] font-bold hover:bg-[#4B42A3] disabled:bg-[#CCC9E9]'
                        data-testid="aadd"
                        onClick={handleAdd}
                    >
                        <img src='/Images/plus.svg' alt='icon' className='mr-[9px]' loading='lazy' />
                        Add
                    </button>
                </div>
            }
            <UnitsAddOrUpdateModel
                isSideModelOpen={isSideModelOpen}
                setIsSideModelOpen={setIsSideModelOpen}
                getUserList={getUserList}
                editData={editData}
                formInput={formInput}
                setFormInput={setFormInput}
                isEdit={Object.keys(editData).length !== 0}
                setEditData={setEditData}
            />
            <TableHeader
                noRecord={noRecord}
                loading={loading}
                isFilterApplied={searchParams.get('period_text') ? true : false}
                searchExist={searchExist}
                userList={userList}
                handleAction={handleAdd}
                searchParams={searchParams}
                tableHederData={tableHederData}
                handleAdd={handleAdd}
                searchValue={searchValue}
                EmptyScreenTittle={'unit'}
                handleSort={handleSort}
                tableHeight={'h-[calc(100vh-305px)]'}
                sort={sort}

            />
            {(!loading && ((nextPage || prevPageExist || currentPage > 1) && searchExist)) &&
                <PageNavigation
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                    prevPageExist={prevPageExist}
                    nextPage={nextPage}
                    currentPage={currentPage}
                    loading={loading}
                />
            }
            {actionModal !== '' &&
                <ActionModal
                    isOpen={actionModal !== ''}
                    setIsOpen={setActionModal}
                    setSelectedUnit={setSelectedUnit}
                    mainHeading={'Delete Unit'}
                    message={getModalText()}
                    ctaButton={`${editData.system_generated ? "Go Back" : "Delete"}`}
                    cancelButton={editData.system_generated ? false : 'Cancel'}
                    handleClickFunction={handleDelete}
                    loader={spinnerLoader}
                    setLoader={setSpinnerLoader}
                />
            }
            {editactionModal !== "" &&
            <ActionModal
                isOpen={editactionModal !== ''}
                setIsOpen={setEditActionModal}
                setSelectedUnit={setSelectedUnit}
                mainHeading={'Update Unit'}
                message={getEditModalText()}
                ctaButton={"Go Back"}
                cancelButton={false}
                handleClickFunction={handleEditDelete}
                loader={spinnerLoader}
                setLoader={setSpinnerLoader}
            />
            }
        </>
    );
}
