import React from 'react';
import Dashboard from '../Pages/Dashboard';
import Profile from '../Pages/Profile';
import Setup from '../Pages/Setup';
import ChangePassword from '../Pages/ChangePassword';
import UserManagement from '../Pages/UserManagement';
import AddUser from '../Pages/UserManagement/Users/addUser';
import AddUserGroup from '../Pages/UserManagement/AddUserGroup';
import PayrollsListing from '../Pages/Payroll';
import CreatePayroll from '../Pages/Payroll/CreatePayroll';
import Currencies from '../Pages/Setup/Currencies';
import Units from '../Pages/Setup/Units';

const privilegeAccess = (role, userAccess) => {
    console.log(userAccess?.user_privilege?.payrolls, 'ddddd')

    switch (role) {
    case 'admin':
    case 'super admin':
        return [
            {
                path: '/dashboard',
                element: <Dashboard />
            },
            {
                path: '/profile',
                element: <Profile />
            },
            {
                path: '/setup',
                element: <Setup />
            },
            {
                path: '/change-password',
                element: <ChangePassword />
            },
            {
                path: '/user-management/users',
                element: <UserManagement />
            },
            {
                path: '/user-management/users/add',
                element: <AddUser />
            },
            {
                path: '/user-management/users/edit/:id',
                element: <AddUser />
            },
            {
                path: '/user-group',
                element: <UserManagement />
            },
            {
                path: '/user-group/add',
                element: <AddUserGroup />
            },
            {
                path: '/user-group/edit/:id',
                element: <AddUserGroup />
            },
            {
                path: '/payrolls',
                element: <PayrollsListing />
            },
            {
                path: '/create-payroll',
                element: <CreatePayroll />
            },
            {
                path: '/setup/currencies/:tab',
                element: <Currencies />
            },
            {
                path: '/setup/units',
                element: <Units />
            }
        ];

    case 'user':
        let privileges = [...userAccess.user_privilege.menu_items, ...userAccess.user_privilege.payroll_functions];
        let role_access = [
            {
                path: '/profile',
                element: <Profile />
            },
            {
                path: '/change-password',
                element: <ChangePassword />
            }
        ]

        let hasCurrencyAccess = false;
        let hasPayrollAccess = userAccess?.user_privilege?.payrolls.length > 0
        let setUpCount = 0;

        privileges.forEach((element) => {
            if (element.includes('setup:') && setUpCount === 0) {
                role_access.push({
                    path: '/setup',
                    element: <Setup />
                });
                setUpCount = setUpCount + 1
            }
            if (['setup:coinage', 'setup:currency', 'setup:conversion', 'setup:currency_conversion'].includes(element)) {
                hasCurrencyAccess = true;
            }
            switch (element) {
            case 'setup:currency_conversion' || 'setup:coinage':
                role_access.push({
                    path: '/setup/currencies/:tab',
                    element: <Currencies />
                });
                break;
            case 'setup:units':
                role_access.push({
                    path: '/setup/units',
                    element: <Units />
                });
                break;
            case 'create_payroll':
                role_access.push({
                    path: '/create-payroll',
                    element: <CreatePayroll />
                });
                break;

                // You can add more cases here as needed for other privileges        
            default:
                break;
            }
        });
        if (hasCurrencyAccess) {
            role_access.push({
                path: '/setup/currencies/:tab',
                element: <Currencies />
            });
        }
        if (hasPayrollAccess) {
            role_access.push({
                path: '/payrolls',
                element: <PayrollsListing />
            })
        }
        return role_access
    default:
        return []; // Return empty array for roles without access
    }
};

export default privilegeAccess;
