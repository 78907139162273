// src/amplifyConfig.js

import { Amplify } from "aws-amplify";
import { dataService } from "../../services/dataService";
const configureAmplify = async (setAwsConfig) => {
    try {
        const configData = await dataService.getEnvs();
        if (!configData.error) {
            Amplify.configure(
                {
                    Auth: {
                        Cognito: {
                            userPoolId: configData.data.data.user_pool_id,
                            userPoolClientId: configData.data.data.client_id,
                            identityPoolId: configData.data.data.identity_pool_id,
                            allowGuestAccess: false,
                        }
                    },
                }
            )
            setAwsConfig(true);
        } else {
            console.error('Error fetching environment variables:', configData.data.data.message);
            setAwsConfig(false);
        }
    } catch (error) {
        console.error('Network error while fetching environment variables:', error);
    }
};

export default configureAmplify;
