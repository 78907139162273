import React from 'react'

const InnerPageCommonLoader = () => {
    return (
        <div className="loader-container h-[calc(100vh-50px)]">
            <div className="spinner"></div>
            <p className="loading-text">Loading...</p>
        </div>
    )
}

export default InnerPageCommonLoader

