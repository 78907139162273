/* eslint-disable security/detect-unsafe-regex */
/* The `ValidateEmailId` function is a JavaScript arrow function that takes an `email` parameter and
	validates whether the email address provided is in a correct format using a regular expression
	(regex). */
export const ValidateEmailId = (email) => {
    const re =
			/^[a-zA-Z0-9.+]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		
    // Check regex match
    if (!re.test(String(email).toLowerCase())) {
        return false;
    }
	
    // Additional check to prevent .com.com or other repeated TLDs
    const domainPart = email.split('@')[1];
    const tld = domainPart.split('.');
    const uniqueTLDs = new Set(tld);
    return uniqueTLDs.size === tld.length;
};