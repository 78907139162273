/* eslint-disable max-len */
import React from 'react'
import Lottie from 'lottie-react';
import blockImage from './block-image.json';

export default function CommonLoader({ message }) {
    return (
        <>
            <div className={`flex ${message && message !== '' ? 'flex-col h-[calc(100vh-51px)]' : 'h-screen'} justify-center items-center`}>
                <Lottie animationData={blockImage} loop={true} />
                {message && message !== '' && (
                    <div className="mt-4 text-center">
                        <p className='text-[18px] leading-[25px] font-semibold text-[#2F2E38]'>
                            {message}
                        </p>
                    </div>
                )}
            </div>
        </>
    );
}
