/* eslint-disable max-len */
import React, { useState, useRef, useEffect, useContext } from 'react';

function Dropdown({ formInput, setFormInput, errorState, setErrorState, isBankCountry, isTaxCountry, options, label,setSelectedOption,selectedOption,handleOptionClick,showDropdown,setShowDropdown,handleInputClick }) {

    const [searchText, setSearchText] = useState('');
    const [focused, setFocused] = useState(false);
    const [countryFocused, setCountryFocused] = useState(false)
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
                setCountryFocused(false)
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

   


    return (
        <div className="country-select-dropdown mb-[30px] w-full" ref={dropdownRef}>
            <div className='relative'>
                <label
                    className={`absolute transition-all left-2 font-normal bg-white ${errorState['role'] !== "" ? "text-[#EA3829]" : "text-[#2F2E38]"} ${formInput.role !== '' ? "top-[-8px] text-xs px-[4px]" : "top-1/2 -translate-y-1/2 text-[14px] mx-[4px]"}`}
                >
                    {label}<span className={`${errorState['role'] !== "" ? "text-[#EA3829]" : 'text-[#2F2E38]'}`}>*</span>
                </label>
                <div>
                    <input
                        type="text"
                        data-testid="dropdown"
                        value={(formInput.role)}
                        onClick={() => handleInputClick()}
                        className={`border text-[14px] font-normal rounded 
                        w-full py-3 px-3 outline-0 focus-visible:outline-0 input-no-text text-[#2F2E38] cursor-pointer
                        ${errorState['role'] !== "" ? "border-[#EA3829]" : formInput['role'] !== '' || focused ? "border-[#5E5C70]" : "border-[#ACABBA]"}`}
                    />
                    <div className={`absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer`}
                        onClick={() => { handleInputClick() }}>
                        {!showDropdown ?
                            <img src='/Images/chevron-down.svg' alt='icon' loading='lazy' /> :
                            <img src='/Images/chevron-up.svg' alt='icon' loading='lazy' />}
                    </div>
                </div>
            </div>
            <p className="text-[#EA3829] text-xs font-normal pt-[2px] pl-3">
                {errorState['role']}
            </p>
            <div className='relative'>
                <div
                    className={`dropdown-container z-10 absolute top-0 left-0 bg-white  w-full h-[95px]
                    border-b border-r border-l border-[#D6D5DD] ${showDropdown ? 'open opacity-100' : 'closed opacity-0 max-h-0 overflow-hidden'}`}
                >
                    <div className="dropdown-content w-full relative mt-3">
                        <div className='h-[95px] overflow-auto customScrollbar'>
                            {options?.length > 0 && (
                                options.map((option, i) => (
                                    <>
                                        <div
                                            key={`key-${i}`}
                                            data-testid={`dropdown-${option.dataTestId}`}
                                            onClick={() => handleOptionClick(option)}
                                            className="dropdown-item cursor-pointer text-sm font-normal text-[#2F2E38] px-3 py-2.5  hover:bg-[#EEEDF8] flex items-center"
                                        >
                                            <p className='mr-2 w-[29px] h-5'>{option.value}</p>
                                        </div>
                                    </>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dropdown;

