/* eslint-disable max-len */
import React, { useState } from 'react';
import CustomDatePicker from '../../CommonComponent/datePicker';

export default function DateField({
    label,
    formInput,
    handleChange,
    errorState,
    disabled,
    required,
    setFormInput,
    setErrorState,
    setRenderkey,
    renderkey,
    loading,
    ...rest
}) 
{
    const [focused, setFocused] = useState(false);
    return (
        <div className={`${label == "Password" ? "mb-2.5" : "mb-[40px]"}`}>
            <div className="relative custom-date rounded">
                {!renderkey ? (
                    <>
                        <div className="relative">
                            <label
                                className={`absolute transition-all left-2 font-normal bg-white pointer-events-none`}
                            >
                            </label>
                            <input
                                type="text"
                                data-testid="dropdown"
                                // value={!renderkey ? '' : selectedOption ? selectedOption[id] : formInput[id]}
                                // onClick={() => handleInputClick(id)}
                                // onFocus={() => setFocused(true)}
                                // onBlur={() => setFocused(false)}
                                readOnly
                                className={`border border-[#5E5C70] text-[14px] font-normal rounded w-full py-3 px-3 outline-0 focus-visible:outline-0 input-no-text text-[#2F2E38] cursor-pointer`}
                                style={{ textAlign: !renderkey ? 'center' : 'left' }} />
                            {/* Loader inside input field */}
                           
                            <div className="absolute inset-0 flex justify-center items-center">
                                <div className='w-[270px] h-[25px] rounded bg-[#dbd9d9f3] animate-pulse'></div>
                            </div>
                            
                          
                        </div>
                    </>
                ): 
                    <>
                        <label
                            className={`absolute transition-all left-2 font-normal bg-white z-10 ${
                                errorState[rest.id] !== "" ? "text-[#EA3829]" : "text-[#2F2E38]"
                            } duration-300 ${
                                focused || formInput[rest.id] ? "top-[-8px] text-xs px-[4px]" : "top-1/2 -translate-y-1/2 text-[14px] mx-[4px]"
                            }`}
                            htmlFor={rest.id}
                        >
                            {label}
                            {required==true && 
                                <span className={`${errorState[rest.id] !== "" ? "text-[#EA3829]":'text-[#2F2E38]'}`}>*</span>}
                        </label>
                
                        <CustomDatePicker 
                            formInput={formInput} 
                            setFormInput={setFormInput} 
                            setErrorState={setErrorState} 
                            errorState={errorState} 
                            label={label} 
                            disabled={disabled} 
                            rest={rest} 
                            focused={focused} 
                            dateKey='start_date' 
                            setFocused={setFocused} 
                            loading={loading} 
                            setRenderkey={setRenderkey}
                            renderkey={renderkey}
                        />
                    </>
                }
            </div>
            <p className="text-[#EA3829] text-xs font-normal pt-[2px] pl-3">
                {errorState[rest.id]}
            </p>
        </div>
    );
}
