import React, { useState } from "react";

export const SearchBar = ({searchValue, optimizedFn, changeHandler, placeholder})=>{
    const [focused, setFocused] = useState(false);

    return (
        <div className={`flex border rounded ${focused || searchValue
            ? "border-[#29275F]":"border-[#ACABBA]"} w-[309px] py-2.5 pr-3`}>
            <label></label>
            {searchValue || focused?
                <img src='/Images/search-hightight.svg' alt='icon' className='mr-2 ml-3 w-5 h-5' loading='lazy' />:
                <img src='/Images/search.svg' alt='icon' className='mr-2 ml-3 w-5 h-5' loading='lazy'/>}
            <input type='text' 
                value={searchValue} 
                onFocus={(e) => {setFocused(true)}}
                onBlur={() => {setFocused(false)}}                
                placeholder={placeholder} 
                data-testid="search"
                maxLength={200}
                id="search-value"
                className='w-full outline-0 
                placeholder:text-[#918FA3] text-[#2F2E38] font-normal text-sm' 
                autoComplete="off"
                onChange={(e)=>{
                    e.preventDefault();
                    optimizedFn(e, e.target.value);
                    changeHandler(e)}} />
        </div>
    )
}