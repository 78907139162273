/* eslint-disable max-len */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { useLocation, useNavigate } from 'react-router';
import LogoutModal from './logoutModal';
import { motion, AnimatePresence } from 'framer-motion'
import { handleSidebarAnimation } from '../../Redux/commonSlice';
import { handleCurrentPage, handlePagination } from '../../Redux/paginationSlice';
import { dataService } from '../../services/dataService';
import Lottie from 'lottie-react';
import blockImage from "../../CommonComponent/search_not_found.json"
import SearchInputLoadingShimmer from '../../CommonComponent/SearchInputLoadingShimmer';
import GlobalContext from '../../CommonComponent/context';
import HoveringTooltip from '../../CommonComponent/HoveringTooltip';


export default function Sidebar() {
    const dropdownRef = useRef(null);
    const {currentSelectedpayroll, setCurrentSelectedPayroll} = useContext(GlobalContext)
    const navigate = useNavigate()
    const path = useLocation()
    const userData = useSelector((state) => state.auth)
    const [isRefreshed, setIsRefreshed] = useState(true);
    const { setIsSwitchPayroll } = useContext(GlobalContext)

    const [showDropdown, setshowDropdown] = useState(false);
    const [payrollList, setPayrollList] = useState([])
    const [searchText, setSearchText] = useState('')
    const [focused, setFocused] = useState(false);
    const [loadingData, setLoadingData] = useState(false)
    const [debouncedSearchText, setDebouncedSearchText] = useState(''); 
    const [hasFetchedPayrolls, setHasFetchedPayrolls] = useState(false);

    const isAnimating = useSelector((state) => state?.commonData?.isAnimating)
    const { privileges, role, isFetched } = useSelector((state) => state.privileges);
    const dispatch = useDispatch()
    let paginationData = {}


    // Function to fetch payrolls
    const fetchPayrolls = async (search) => {
        setLoadingData(true)
        let endpoint = `?payroll_status=active`;
        if (search) {
            endpoint += `&search=${search}`;
        }
        const data = await dataService.ListPayrolls(encodeURI(endpoint));
        if (data.error === false) {
            setPayrollList(data.data.data);
            setLoadingData(false)
        } else{
            setLoadingData(false)
        }
    };

    const handleSearchInputChange = (e) => {
        setSearchText(e.target.value);
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchText(searchText);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [searchText]);

    useEffect(() => {
        if (hasFetchedPayrolls) {
            fetchPayrolls(debouncedSearchText);
        }
    }, [debouncedSearchText, hasFetchedPayrolls]);

    useEffect(() => {
        if (showDropdown && !hasFetchedPayrolls) {
            fetchPayrolls();
            setHasFetchedPayrolls(true); // Ensure it's only called once when dropdown is first opened
        }
    }, [showDropdown, hasFetchedPayrolls]);


    const handleOptionClick = (option) => {
        // Store the option in localStorage
        localStorage.setItem('selectedOption', JSON.stringify(option));
        setIsSwitchPayroll(true);
        setCurrentSelectedPayroll(option)
        setshowDropdown(false)
    
        // Log the option to the console
        console.log(option);
    }


    // Function to check if a privilege exists in the user's privileges
    const hasPrivilege = (requiredPrivilege) => {
        return privileges?.menu_items.includes(requiredPrivilege) || role === 'admin' || role === 'superadmin';
    };

    const hasSetupPrivilege = role === "admin" || role === "super admin"
        ? true
        : privileges?.menu_items?.some(privilege => privilege.includes('setup'));

    const hasOrganisationalPrivilege = role === "admin" || role === "super admin"
        ? true
        : privileges?.menu_items.some(privilege => privilege.includes('organisational'));



    // Function to check role-based rendering
    const canViewMenu = (menuPrivilege) => {
        return role === 'admin' || role === 'superadmin' || hasPrivilege(menuPrivilege);
    };

    useEffect(() => {
        setIsRefreshed(false);
    }, []);

    let animationProps;
    if (isAnimating) {
        animationProps = {
            initial: { opacity: 0, scale: 0.6 },
            animate: { opacity: 1, scale: 1 },
            transition: {
                duration: 0.3,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01],
            }
        };
    }
    else {
        animationProps = {
            initial: { opacity: 1, scale: 1 },
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setshowDropdown(false);
                setSearchText('')
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        // Retrieve the stored option from localStorage
        const storedOption = JSON.parse(localStorage.getItem('selectedOption'));        
        if (storedOption) {
            setCurrentSelectedPayroll(storedOption);
        }
    }, []); 

    return (
        <AnimatePresence>
            <div className='max-w-[256px] min-w-[256px] bg-[#29275F] pt-7 px-[17px] pb-8 sidebar-container'>
                <nav className='flex flex-col justify-between h-full'>
                    <ul>
                        <li className='pb-6'>
                            <img src='/Images/logo-withName.svg' alt='icon' loading='lazy' />
                        </li>
                        <li onClick={() => setshowDropdown(!showDropdown)} className='cursor-pointer flex items-center px-2 py-1.5 bg-[#29275F] border border-[#4B42A3] rounded justify-between mb-3'>
                            <div className='flex flex-col'>
                                <div className='flex flex-row items-center'>
                                    <p className='text-sm text-[#FFFFFF] font-normal'>Payroll: </p>
                                    <p data-tooltip-id={`payroll-hover-tooltip`} className='text-[#FFFFFF] text-xs font-semibold pl-[4px] overflow-hidden text-ellipsis whitespace-nowrap max-w-[130px]'> {currentSelectedpayroll?.payroll_name}</p>
                                    {currentSelectedpayroll?.payroll_name.length > 20 && 
                                    <HoveringTooltip place={"left"} Name={currentSelectedpayroll?.payroll_name || 'Not available'} id={`payroll-hover-tooltip`} className={'tooltip-class-payroll'}/>}
                                </div>
                                <div className='flex flex-row items-center'>
                                    <p className='text-sm text-[#FFFFFF] font-normal'>Period: </p>
                                    <p className='text-[#FFFFFF] text-xs font-semibold pl-[4px]'>{currentSelectedpayroll?.current_period}</p>

                                </div>
                            </div>

                            <div className='cursor-pointer'>
                                <img src='/Images/chevron-down-white.svg' alt='icon' loading='lazy' />
                            </div>
                        </li>
                        <div className='relative' ref={dropdownRef}>
                            <div
                                className={`dropdown-container z-10 absolute top-0 left-0 bg-white rounded w-full 
                                border-b border-r border-l border-[#D6D5DD] ${showDropdown ? 'open opacity-100 min-h-[365px]' : 'h-[395px] closed opacity-0 max-h-0 overflow-hidden'}`}
                            >
                                <div className='flex items-center px-[13px] py-[9px] border-b border-[#33333326]'>
                                    <img className='cursor-pointer' src='/Images/close-dropdown.svg' alt="icon" onClick={() => {setshowDropdown(false), setSearchText('')}} />
                                    <div className='w-[80%]'>
                                        <p className='text-[#333333] text-sm font-normal text-center'>Payrolls</p>
                                    </div>
                                </div>
                                <div className="dropdown-content w-full relative mt-3">
                                    <div className={`flex items-center border ${searchText !== '' || focused ? "border-[#5E5C70]" : "border-[#ACABBA]"
                                    } rounded py-2.5 px-3 shadow-s4 mx-3 mb-3 sticky top-0 left-0 w-auto bg-white`}>
                                        {searchText || focused
                                            ? <img src='/Images/search-hightight.svg' alt='icon' />
                                            : <img src='/Images/search.svg' alt='icon' />}
                                        <input
                                            type="text"
                                            data-testid="add-search"
                                            value={searchText}
                                            onFocus={() => setFocused(true)}
                                            onBlur={() => setFocused(false)}
                                            onChange={(e) => handleSearchInputChange(e)}
                                            placeholder="Search"
                                            className='outline-0 w-full ml-2 placeholder:text-[#918FA3] text-[#2F2E38] text-sm font-normal'
                                        />
                                    </div>
                                    <div className='max-h-[240px] overflow-auto customScrollbar px-3'>

                                        {loadingData ? (
                                        // Show shimmer effect when data is loading
                                            <SearchInputLoadingShimmer length={5} />
                                        ) : (
                                        // Show "No results found" or the list of payrolls based on the searchText and payrollList
                                            searchText !== '' && payrollList?.length === 0 ? (
                                                <div className="error-message h-full flex flex-col items-center justify-center">
                                                    <Lottie
                                                        animationData={blockImage}
                                                        loop={true}
                                                        className="w-[90px] mx-auto"
                                                    />
                                                    <p className='text-sm font-normal text-[#2F2E38] text-center pb-5 pt-3'>
                                                        We could not find any matches for your search.
                                                    </p>
                                                </div>
                                            ) : (
                                                payrollList?.length > 0 && (
                                                    payrollList.map((option, i) => (
                                                        <div
                                                            key={`key-${i}`}
                                                            data-testid={`dropdown-${option.dataTestId}`}
                                                            onClick={() => handleOptionClick(option)}
                                                            className="dropdown-item cursor-pointer text-sm font-normal text-[#2F2E38] px-3 py-2.5 hover:bg-[#EEEDF8] hover:rounded flex items-center"
                                                        >
                                                            <p className='mr-2 w-fit'>{option.payroll_name}</p>
                                                        </div>
                                                    ))
                                                )
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {hasSetupPrivilege && (
                            <li
                                className={
                                    `flex items-center text-sm font-normal mb-1 py-[13px] px-3  rounded-lg custom-li side-menu 
                                ${path.pathname.includes('/setup') ? 'bg-[#FFFFFF] text-[#29275F]' : 'text-[#EEEDF8] hover:bg-[#4B42A3] cursor-pointer'}`}
                                onClick={() => {
                                    dispatch(handleSidebarAnimation(false));
                                    path.pathname.includes('/setup') ? null
                                        : navigate('/setup')
                                }}
                            >
                                {!path.pathname.includes('/setup') ?
                                    <motion.img {...animationProps} src='/Images/setup-icon.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                    <motion.img {...animationProps} src='/Images/setup-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                                <motion.img {...animationProps} src='/Images/setup-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                                Setup
                            </li>
                        )}
                        {(role === 'admin' || role === 'super admin') &&
                            <li
                                className={
                                    `flex items-center text-sm font-normal mb-1 py-[13px] px-3 rounded-lg custom-li side-menu 
                                ${path.pathname.includes('/user') ? 'bg-[#FFFFFF] text-[#29275F]' : 'text-[#EEEDF8] hover:bg-[#4B42A3] cursor-pointer'}`}
                                onClick={() => {
                                    path.pathname.includes('/user') ? null
                                        : dispatch(handlePagination(paginationData)), dispatch(handleCurrentPage(1)), navigate('/user-management/users'), dispatch(handleSidebarAnimation(false));
                                }}
                            >
                                {!path.pathname.includes('/user') ?
                                    <motion.img {...animationProps} src='/Images/users-icon.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                    <motion.img {...animationProps} src='/Images/users-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                                <motion.img {...animationProps} src='/Images/users-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                                User Management
                            </li>
                        }

                        {hasOrganisationalPrivilege && (
                            <li
                                className={
                                    `flex items-center text-sm font-normal mb-1 py-[13px] px-3 rounded-lg custom-li side-menu 
                                    ${path.pathname.includes('/organisational') || path.pathname.includes('/account-types') ? 'bg-[#FFFFFF] text-[#29275F]' : 'text-[#EEEDF8] hover:bg-[#4B42A3] cursor-pointer'}`}
                            // onClick={() => {
                            //     path.pathname.includes('/organisational') || path.pathname.includes('/account-types') ? null
                            //         : navigate('/organisational')
                            // }}
                            >
                                {!path.pathname.includes('/organisational') && !path.pathname.includes('/account-types') ?
                                    <motion.img {...animationProps} src='/Images/org-inactive.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                    <motion.img {...animationProps} src='/Images/organisational-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                                <motion.img {...animationProps} src='/Images/organisational-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                                Organisational
                            </li>
                        )}

                        {canViewMenu("employee_master") && (
                            <li
                                className={`flex items-center text-sm font-normal mb-1 py-[13px] px-3 rounded-lg custom-li side-menu
                                ${path.pathname.includes('/employee-master') ? 'bg-[#FFFFFF] text-[#29275F]' : 'text-[#EEEDF8] hover:bg-[#4B42A3] cursor-pointer'}`}
                            // onClick={() => { path.pathname.includes('/employee-master') ? null : navigate('/employee-master') }}
                            >
                                {!path.pathname.includes('/employee-master') ?
                                    <motion.img {...animationProps} src='/Images/Employee-master-icon.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                    <motion.img {...animationProps} src='/Images/emp-master-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                                <motion.img {...animationProps} src='/Images/emp-master-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                                Employee Master
                            </li>
                        )}

                        {(role === 'admin' || role === 'super admin') &&
                            <>
                                <li
                                    className={`flex items-center text-sm font-normal mb-1 py-[13px] px-3 rounded-lg custom-li side-menu 
                                ${path.pathname.includes('/transaction-code') ? 'bg-[#FFFFFF] text-[#29275F]' : 'text-[#EEEDF8] hover:bg-[#4B42A3] cursor-pointer'}`}
                                // onClick={() => {dispatch(handleSidebarAnimation(false));
                                //     path.pathname.includes('/transaction-code') ? null
                                //         : navigate('/transaction-code')
                                // }}
                                >
                                    {!path.pathname.includes('/transaction-code') ?
                                        <motion.img {...animationProps} src='/Images/transaction-code.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                        <motion.img {...animationProps} src='/Images/transaction-code.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                                    <motion.img {...animationProps} src='/Images/transaction-code.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                                    Transaction Codes
                                </li>

                                <li
                                    className={`flex items-center text-sm font-normal mb-1 py-[13px] px-3  rounded-lg custom-li side-menu 
                                ${path.pathname.includes('/reports') ? 'bg-[#FFFFFF] text-[#29275F]' : 'text-[#EEEDF8] hover:bg-[#4B42A3] cursor-pointer'}`}
                                // onClick={() => {dispatch(handleSidebarAnimation(false));
                                //     path.pathname.includes('/reports') ? null
                                //         : navigate('/reports')
                                // }}
                                >
                                    {!path.pathname.includes('/reports') ?
                                        <motion.img {...animationProps} src='/Images/reports.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                        <motion.img {...animationProps} src='/Images/reports.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                                    <motion.img {...animationProps} src='/Images/reports.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                                    Reports
                                </li>

                                <li
                                    className={`flex items-center text-sm font-normal mb-1 py-[13px] px-3 rounded-lg custom-li side-menu 
                                ${path.pathname.includes('/calculation-routine') ? 'bg-[#FFFFFF] text-[#29275F]' : 'text-[#EEEDF8] hover:bg-[#4B42A3] cursor-pointer'}`}
                                // onClick={() => {dispatch(handleSidebarAnimation(false));
                                //     path.pathname.includes('/calculation-routine') ? null
                                //         : navigate('/calculation-routines')
                                // }}
                                >
                                    {!path.pathname.includes('/calculation-routine') ?
                                        <motion.img {...animationProps} src='/Images/calculation-routine.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                        <motion.img {...animationProps} src='/Images/calculation-routine.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                                    <motion.img {...animationProps} src='/Images/calculation-routine.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                                    Calculation Routine
                                </li>

                            </>
                        }

                    </ul>

                    {(role === 'admin' || role === 'super admin') &&
                        <ul>
                            <li
                                className={`flex items-center text-sm font-normal mb-1 py-2 px-3 rounded-lg custom-li side-menu 
                            ${path.pathname == '/notifications' ? 'bg-[#FFFFFF] text-[#29275F]' : 'text-[#EEEDF8] hover:bg-[#4B42A3] cursor-pointer'}`}
                            // onClick={() => navigate('/notifications')}
                            >
                                {path.pathname !== '/notifications' ?
                                    <motion.img {...animationProps} src='/Images/notifications.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                    <motion.img {...animationProps} src='/Images/notifications.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                                <motion.img {...animationProps} src='/Images/notifications.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                                Notifications
                            </li>

                            <li
                                className={`flex items-center text-sm font-normal mb-1 py-2 px-3  rounded-lg custom-li side-menu 
                            ${path.pathname == '/payroll-settings' ? 'bg-[#FFFFFF] text-[#29275F]' : 'text-[#EEEDF8] hover:bg-[#4B42A3] cursor-pointer'}`}
                            // onClick={() => navigate('/payroll-settings')}
                            >
                                {path.pathname !== '/payroll-settings' ?
                                    <motion.img {...animationProps} src='/Images/payroll-setting.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                    <motion.img {...animationProps} src='/Images/payroll-setting.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                                <motion.img {...animationProps} src='/Images/payroll-setting.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                                Payroll Settings
                            </li>

                        </ul>
                    }
                </nav>
            </div>
        </AnimatePresence>
    );
}
