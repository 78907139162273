/* eslint-disable max-len */
/* eslint-disable security/detect-object-injection */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion'
import { RotatingLines } from 'react-loader-spinner';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { dataService } from '../../../services/dataService';
import GlobalContext from '../../../CommonComponent/context';
import Loader from '../../../CommonComponent/Loader';
import Debounce from '../../../CommonComponent/debounce';
import { ValidateEmailId } from '../../../CommonComponent/emailValidation';
import InputFieldwithTooptip from '../../../CommonComponent/InputFieldwithTooptip';
import Dropdown from '../../../CommonComponent/Dropdown'
import InputWithLabel from '../../../CommonComponent/inputField';
import { NoReseultFoundSmallScreen } from './emptyscreen';
import UserGroupCheckboxes from '../../../CommonComponent/UserGroupCheckBoxes';
import { Tab } from '@headlessui/react';
import NestedCheckboxes from '../../../CommonComponent/nestedCheckbox';
import NestedCheckboxesAccordion from '../../../CommonComponent/nestedCheckboxAccordion';
import FunctionCheckboxes from '../../../CommonComponent/FunctionCheckboxes';
import { payrolls, items, organizationalItems, functions } from '../../../CommonComponent/staticValues'
import { Tooltip as ReactTooltip } from "react-tooltip";
import { getValueTrim } from '../../../CommonComponent/InputValidation';
import endPoints from '../../../services/endpoints';


export default function AddUser() {
    const { setToastmessage, setToastErrorMessage, setToastWarningMessage } = useContext(GlobalContext)
    const [focused, setFocused] = useState(false);
    const [isFullAccess, setIsFullAccess] = useState(false)
    const initial_states = {
        first_name: "",
        last_name: "",
        email_address: "",
        password_expiry: "",
        role: "",
        groups: [],
        menu_items: [],
        payroll: [],
        payroll_functions: []
    }
    const [payrollsList, setpayrollsList] = useState([])
    const [formInput, setFormInput] = useState(initial_states);
    const [errorState, setErrorState] = useState({
        first_name: "",
        last_name: "",
        password_expiry: "",
        email_address: "",
        role: "",
    });
    const [options, setOptions] = useState([
        {
            id: 1,
            value: 'Administrator',
            dataTestId: 'admin'
        },
        {
            id: 2,
            value: 'User',
            dataTestId: 'user'

        }
    ])
    const [searchValue, setSearchValue] = useState('')
    const [radioButtonValue, setRadioButtonValue] = useState('user_group')
    const [selectedOption, setSelectedOption] = useState('')
    const [loader, setLoader] = useState(false)
    const [searchLoader, setSearchLoader] = useState(false)
    const location = useLocation();
    const previousUrl = location.state?.from?.pathname;
    const params = useParams()
    const navigate = useNavigate()
    const [noRecord, setNoRecord] = useState(false)
    const [isEditLoading, setEditLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(initial_states)
    const [showDropdown, setShowDropdown] = useState(false);
    const [userGroupsList, setUserGroupsList] = useState([])
    const [payrollLoader, setpayrollLoader] =  useState(false)

    let animationProps = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { duration: 0.6 } },
    };


    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        optimizedFn(value);
    };

    const optimizedFn = useCallback(
        Debounce((value) => {
            handleFetchUserGroups(value);
        }, 500), // Adjust the delay as needed
        []
    );

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ');
    };

    const handleFetchUserGroups = useCallback(async (search) => {
        setSearchLoader(true); // Set loader to true before starting the fetch
        try {
            let endpoint = `?limit=1500`;
            if (search) {
                endpoint += `&search=${encodeURIComponent(search)}`;
            }
            const data = await dataService.getUserGroup(endpoint);
            if (data.data.success_status === true) {
                setUserGroupsList(data.data.data);
            } else
                if (data.data.status === "NO_DATA") {
                    setNoRecord(true)
                    setUserGroupsList(data.data.data);

                } else {
                    setUserGroupsList([]);
                }
        } catch (error) {
            console.error("Error fetching user groups:", error);
            setUserGroupsList([]); // Clear the list in case of error
        } finally {
            setSearchLoader(false); // Set loader to false after the operation is complete
        }
    }, [radioButtonValue]);

    useEffect(() => {
        handleFetchUserGroups(searchValue);
    }, [handleFetchUserGroups]);

    const handleSelectAll = (checked) => {
        if (checked) {
            const allMenuItems = [...getAllMenuItems()];
            setFormInput(prevState => ({
                ...prevState,
                menu_items: allMenuItems,
            }));
        } else {
            setFormInput(prevState => ({
                ...prevState,
                menu_items: [],
            }));
        }
        setIsFullAccess(checked);
    };
    const getAllMenuItems = () => {
        const setupItems = items.map(item => `setup:${item.id}`);
        const orgItems = organizationalItems.map(item => `organisational:${item.id}`);
        return [...setupItems, ...orgItems, 'employee_master'];
    };

    const handleFetchPayrolls = async () => {
        setpayrollLoader(true)
        const response = await dataService.PayrollsForUserMangement()
        console.log(response, 'ddddd')
        if (response.error === false) {
            setpayrollsList(response.data.data)
            setpayrollLoader(false)
        } else {
            setpayrollLoader(false)
        }
    }

    useEffect(() => {
        if (params.id) {
            setEditLoading(true)
            handleFetch()
        }
        handleFetchPayrolls()
    }, [])

    const handleFetch = async () => {
        const data = await dataService.getAPI(`${endPoints.user}/${params.id}`)
        if (data.data.success_status == true) {
            // setIsEdit(data.data.data);
            const res = {
                first_name: data.data.data.first_name,
                last_name: data.data.data.last_name,
                email_address: data.data.data.email_address,
                role: data.data.data.user_role === 'admin' ? 'Administrator' : 'User',
                groups: data.data.data.user_privilege ? data.data.data.user_privilege : [],
                password_expiry: data.data.data.password_expiry ? data.data.data.password_expiry : '',
                menu_items: data?.data?.data?.user_privilege?.menu_items ? data.data.data.user_privilege.menu_items : [],
                payroll_functions: data?.data?.data?.user_privilege?.payroll_functions ? data.data.data.user_privilege.payroll_functions : [],
                payroll: data?.data?.data?.user_privilege?.payroll_ids ? data?.data?.data?.user_privilege?.payroll_ids : [],
                user_access_type: data.data.data.user_access_type === 'custom-access' ? 'menu' : 'user_group'

            }
            setIsEdit(prevState => ({
                ...prevState,
                ...res,  // Spread the new data correctly into the state
            }));
            setFormInput(res)
            setSelectedOption(data.data.data.user_role === 'admin' ? 'Administrator' : 'User')
            setRadioButtonValue(data.data.data.user_access_type === 'custom-access' ? 'menu' : 'user_group')
            // if(data.data.data.access_privillage[0]=='*'){
            //     setIsFullAccess(true)
            // }
            setEditLoading(false)
        }
        else if (data.data.message.includes('User not found')) {
            navigate('/page-not-found')
        }
    }

    const handleOptionClick = (option) => {
        setSelectedOption(option.value)
        setFormInput((prevState) => ({
            ...prevState,
            role: option.value,
            groups: [],
            menu_items: [],
            payroll_functions: []
        }));
        setSearchValue('')
        setShowDropdown(false)
        setRadioButtonValue('user_group')
        if (option.value == 'User') {
            handleFetchUserGroups()

        }
    }

    const handleInputClick = () => {
        setShowDropdown(!showDropdown);
        setErrorState((prevState) => ({
            ...prevState,
            role: "",
        }));
    };

    const handleCheckboxClick = (id) => {
        if (id === 'menu') {
            setRadioButtonValue('menu')
        } else {
            setRadioButtonValue('user_group')
        }
        setFormInput((prevState) => ({
            ...prevState,
            groups: [],
            menu_items: [],
            payroll_functions: [],
            payroll:[]
        }));
        setIsFullAccess(false)
        setSearchValue('')
    }

    const handleChange = (event) => {
        const { id, value } = event.target;
        let formValue = getValueTrim(id, value);
        setFormInput((prevState) => ({
            ...prevState,
            [id]: formValue,
        }));
        setErrorState((prevState) => ({
            ...prevState,
            [id]: "",
        }));
    };


    function validateForm() {
        let isValid = true;
        // Clear previous error states
        // setErrorState({});
        Object.keys(formInput).forEach((key) => {
            if (key === "last_name" || key === "password_expiry") {
                return; // Skip validation for the last_name field
            }
            if (formInput[key] === "") {
                setErrorState((prevState) => ({
                    ...prevState,
                    [key]: "This field is mandatory",
                }));
                isValid = false;
            } else if (key === "email_address" && !ValidateEmailId(formInput.email_address)) {
                setErrorState((prevState) => ({
                    ...prevState,
                    [key]: "Invalid email address",
                }));
                isValid = false;
            }
        });
        if ((formInput?.groups?.length === 0 || formInput?.groups === undefined) && radioButtonValue !== 'menu' && formInput.role === 'User') {
            setToastErrorMessage('Please select at least one menu option or user group')
            isValid = false;
        }
        return isValid;
    }


    const handleGoBack = () => {
        if (previousUrl === '/user-management/users') {
            navigate(-1); // Navigate to the previous URL in the history stack
        } else {
            navigate('/user-management/users'); // Navigate to /user-groups
        }
    };


    const handleAddUser = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoader(true)
            let payroll_functions = []
            if (!formInput.menu_items.includes('employee_master')) {
                ["create_payroll", "update_payroll"].forEach((item) => {
                    if (formInput.payroll_functions.includes(item)) {
                        payroll_functions.push(item)
                    }

                })
            } else {
                payroll_functions = formInput.payroll_functions
            }
            const body = {
                "first_name": formInput.first_name,
                "last_name": formInput.last_name,
                "email_address": formInput.email_address,
                "user_role": formInput.role === 'Administrator' ? 'admin' : 'user',
                "password_expiry": formInput.password_expiry,
                ...(formInput.role !== 'Administrator' && {
                    "user_access_type": radioButtonValue === 'menu' ? 'custom-access' : 'user-group',
                    ...(radioButtonValue === 'menu'
                        ? {
                            "custom_privileges": {
                                "menu_items": formInput.menu_items,
                                "payroll_functions": payroll_functions,
                                "payroll_ids": formInput.payroll
                            }
                        }
                        : {
                            "user_group_ids": formInput.groups
                        }
                    )
                })
            };
            try {
                let data;
                if (params.id) {
                    data = await dataService.patchAPI(`${endPoints.user}/${params.id}`, body)
                    if (data.error == false) {
                        setToastmessage('User updated successfully')
                        handleGoBack()
                    }
                    else {
                        if (data.data.message.includes('Email address already exists')) {
                            setErrorState((prevState) => ({
                                ...prevState,
                                ["email_address"]: 'Email address already exists'
                            }));
                        }
                        if (data.data.message.includes('Group description already exists')) {
                            setErrorState((prevState) => ({
                                ...prevState,
                                ["description"]: 'Description already exists'
                            }));
                        }
                        if (data.data.message.includes('Please select at least one user group for the user.') || data.data.message.includes('Please select at least one menu option for the user group.') || data.data.message.includes("At least one payroll ID must be provided.")) {
                            setToastErrorMessage('Please select at least one payroll and menu option for user')
                        }
                        if (data.data.message.includes('select at least one menu option') ||
                            data.data.message.includes('Privileges cannot be empty')) {
                            setToastErrorMessage('Please select at least one payroll and menu option for user')
                        }
                        if (data.data.message.includes("User not found")) {
                            setToastErrorMessage('User not found')
                        }
                    }
                    setLoader(false)
                }
                else {
                    data = await dataService.AddUser(body)
                    if (data.data.success_status == true) {
                        setToastmessage(data.data.message)
                        navigate('/user-management/users')
                    }
                    else {
                        if (data.data.message.includes('Email address already exists')) {
                            setErrorState((prevState) => ({
                                ...prevState,
                                ["email_address"]: 'Email address already exists'
                            }));
                        }
                        if (data.data.message.includes('Description already exists')) {
                            setErrorState((prevState) => ({
                                ...prevState,
                                ["description"]: 'Description already exists'
                            }));
                        }
                        if (data.data.message.includes('Please select at least one user group for the user.') || data.data.message.includes('Please select at least one menu option for the user group.')  || data.data.message.includes("At least one payroll ID must be provided.")) {
                            setToastErrorMessage('Please select at least one payroll and menu option for user')
                        }
                        if ((data.data.message.includes('User Groups cannot be empty.')) ||
                            data.data.message.includes('Privileges cannot be empty')) {
                            setToastErrorMessage('Please select at least one payroll and menu option for user')
                        }
                    }
                    setLoader(false)
                }
            } catch (error) {
                console.log(error)
            }
        }
    }
    const isFormInputUnchanged = useCallback(() => {
        const normalizedIsEdit = {
            first_name: isEdit.first_name,
            last_name: isEdit.last_name,
            email_address: isEdit.email_address,
            role: isEdit.role,
            groups: isEdit.groups || [],
            menu_items: isEdit.menu_items || [],
            password_expiry: isEdit.password_expiry,
            payroll_functions: isEdit.payroll_functions,
            payroll: isEdit.payroll,
            user_access_type: isEdit.user_access_type
        };
        const normalizedFormInput = {
            first_name: formInput.first_name,
            last_name: formInput.last_name,
            email_address: formInput.email_address,
            role: formInput.role,
            groups: formInput.groups || [],
            menu_items: formInput.menu_items || [],
            password_expiry: formInput.password_expiry,
            payroll_functions: formInput.payroll_functions,
            payroll: formInput.payroll,
            user_access_type: radioButtonValue
        };

        // Utility function to compare two objects including nested arrays
        const isEqual = (obj1, obj2) => {
            return JSON.stringify(obj1) === JSON.stringify(obj2);
        };

        return isEqual(normalizedIsEdit, normalizedFormInput);
    }, [isEdit, formInput]);

    useEffect(() => {
        const allItems = getAllMenuItems();
        setIsFullAccess(allItems?.every(item => formInput?.menu_items?.includes(item)));
    }, [formInput.menu_items]);


    return (
        <>
            {isEditLoading ?
                <div className='flex justify-center items-center bg-[#ceceda] rounded-tl-lg h-[calc(100vh-51px)]'>
                    <Loader />
                </div> :
                <motion.div {...animationProps}>
                    <div className='rounded-tl-lg bg-white h-[calc(100vh-51px)] pl-6'>
                        <div className='text-base font-normal'>
                            <button type='button' className='text-[#2F2E38] pt-7 pb-3.5'
                                onClick={() => navigate('/user-management/users')}>User Management</button>
                            {` > `}
                            {/* <button type='button' className='text-[#2F2E38] pt-7 pb-3.5'
                            onClick={() => navigate('/user-management/users')}>Users</button>
                        {` > `} */}
                            <span className='text-[#4B42A3] font-bold'>{params.id ? 'Update' : 'Add'} User</span>
                        </div>
                        <h1 className='text-[32px] text-[#2F2E38] font-bold pb-7'>{params.id ? 'Update' : 'Add'} User</h1>
                        <div className='flex'>
                            <div className='w-[309px] mr-6'>
                                <InputWithLabel
                                    id="first_name"
                                    label="First name"
                                    type="text"
                                    autocomplete="off"
                                    required={true}
                                    // handlePasswordFocus={null}
                                    handleChange={handleChange}
                                    formInput={formInput}
                                    errorState={errorState}
                                    showPassword={true}
                                    maxLength={30}
                                />
                            </div>
                            <div className='w-[309px] mr-6'>
                                <InputWithLabel
                                    id="last_name"
                                    label="Last name"
                                    type="text"
                                    autocomplete="off"
                                    required={false}
                                    handlePasswordFocus={null}
                                    handleChange={handleChange}
                                    formInput={formInput}
                                    errorState={errorState}
                                    showPassword={true}
                                    maxLength={30}
                                />
                            </div>
                            <div className='w-[309px] mr-6'>
                                <InputFieldwithTooptip
                                    id="email_address"
                                    label="Email address"
                                    type="text"
                                    autocomplete="off"
                                    required={true}
                                    handleChange={handleChange}
                                    formInput={formInput}
                                    errorState={errorState}
                                    maxLength={80}
                                    disabled={params.id}
                                    tooltip={params.id}
                                />
                            </div>
                        </div>
                        <div className='flex'>
                            <div className='w-[309px] mr-6 relative'>
                                <InputWithLabel
                                    id="password_expiry"
                                    label="Days until password expiry"
                                    type="text"
                                    autocomplete="off"
                                    required={false}
                                    handlePasswordFocus={null}
                                    handleChange={handleChange}
                                    formInput={formInput}
                                    errorState={errorState}
                                    showPassword={true}
                                    maxLength={3}
                                />
                                <div className='absolute top-[15px] right-0 z-[1]' data-tooltip-id="password_expiry">
                                    <img src='/Images/info.svg' alt='icon' loading='lazy'
                                        className='cursor-pointer mr-2.5  bg-white' />
                                    <ReactTooltip
                                        id="password_expiry"
                                        place="top-end"
                                        variant="info"
                                        content="If set, the user will be required to reset their password after the selected number of days."
                                        arrowColor="white"
                                        style={{
                                            backgroundColor: "#5E5C70",
                                            color: "#F1F1F4",
                                            height: '60px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            borderRadius: '4px',
                                            maxWidth: '309px',
                                            padding: '8px',
                                            whiteSpace: 'normal',
                                            overflowWrap: 'break-word',
                                            wordWrap: 'break-word',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='w-[309px] mr-6'>
                                <Dropdown
                                    id="role"
                                    label="Role"
                                    type="text"
                                    autocomplete="off"
                                    required={true}
                                    handleChange={handleChange}
                                    formInput={formInput}
                                    errorState={errorState}
                                    options={options}
                                    setSelectedOption={setSelectedOption}
                                    selectedOption={selectedOption}
                                    handleOptionClick={handleOptionClick}
                                    showDropdown={showDropdown}
                                    setShowDropdown={setShowDropdown}
                                    handleInputClick={handleInputClick}
                                />
                            </div>
                            <div className='w-[309px] mr-6'></div>

                        </div>
                        {selectedOption === 'User' &&
                            <>

                                <div className='flex items-center mb-[16px]'>
                                    <div className='radio-container p-[8px] mr-[24px] flex items-center' onClick={() => handleCheckboxClick('user_group')}>
                                        <input
                                            type="radio"
                                            id="user_group"
                                            name="option"
                                            value="User Group"
                                            data-testid='user_group'
                                            checked={radioButtonValue === 'user_group'}
                                            onClick={() => handleCheckboxClick('user_group')}
                                            className='cursor-pointer custom-radio'
                                        />
                                        <label for="html" className='text-[16px] leading-[24px] text-[#2F2E38] font-normal ml-[16px] cursor-pointer'>User Group</label>
                                    </div>
                                    <div className='radio-container p-[8px] flex items-center' onClick={() => handleCheckboxClick('menu')}>
                                        <input
                                            type="radio"
                                            id="menu"
                                            name="option"
                                            value="menu"
                                            data-testid='menu'
                                            checked={radioButtonValue === 'menu'}
                                            onClick={() => handleCheckboxClick('menu')}
                                            className='cursor-pointer custom-radio'
                                        />
                                        <label for="html" className='text-[16px] leading-[24px] text-[#2F2E38] font-normal ml-[16px] cursor-pointer'>Features</label>
                                    </div>
                                </div>
                                {radioButtonValue === 'menu' ?
                                    <Tab.Group>
                                        <Tab.List
                                            data-testid="payrolls"
                                            className="mb-[26px] flex list-none flex-row flex-wrap pl-0 w-full border-b-[0.5px] border-[#D6D5DD]"
                                            role="tablist"
                                            data-te-nav-ref
                                        >
                                            <Tab
                                                role="presentation"
                                                id="payrolls"
                                                className={({ selected }) =>
                                                    classNames(
                                                        "w-fit block text-center p-2.5 text-base",
                                                        "font-normal leading-normal hover:isolate hover:text-[#4B42A3] mr-12 border-b-[2px]",
                                                        "focus:isolate",
                                                        selected
                                                            ? 'bg-[#ffffff] border-[#29275F] text-[#29275F] hover:text-[#29275F]'
                                                            : 'text-[#2F2E38] border-transparent'
                                                    )
                                                }
                                            >
                                                Payrolls
                                            </Tab>
                                            <Tab
                                                role="presentation"
                                                data-testid="menus"
                                                id="menus"
                                                className={({ selected }) =>
                                                    classNames(
                                                        "w-fit block text-center p-2.5 text-base",
                                                        "font-normal leading-normal hover:isolate hover:text-[#4B42A3] mr-12 border-b-[2px]",
                                                        "focus:isolate",
                                                        selected
                                                            ? 'bg-[#ffffff] border-[#29275F] text-[#29275F] hover:text-[#29275F]'
                                                            : 'text-[#2F2E38] border-transparent'
                                                    )
                                                }
                                            >
                                                Menu
                                            </Tab>
                                            <Tab
                                                role="presentation"
                                                data-testid="functions"
                                                id="functions"
                                                className={({ selected }) =>
                                                    classNames(
                                                        "w-fit block text-center p-2.5 text-base",
                                                        "font-normal leading-normal hover:isolate hover:text-[#4B42A3] mr-12 border-b-[2px]",
                                                        "focus:isolate",
                                                        selected
                                                            ? 'bg-[#ffffff] border-[#29275F] text-[#29275F] hover:text-[#29275F]'
                                                            : 'text-[#2F2E38] border-transparent'
                                                    )
                                                }
                                            >
                                                Functions
                                            </Tab>
                                        </Tab.List>
                                        <Tab.Panels>
                                            <Tab.Panel>
                                                {payrollLoader ? (
                                                    <Loader height={'h-[calc(100vh-580px)]'} />
                                                ) : (
                                                    <NestedCheckboxes
                                                        items={payrollsList}
                                                        setFormInput={setFormInput}
                                                        formInput={formInput}
                                                        height={'h-[calc(100vh-580px)]'}
                                                    />
                                                )}
                                            </Tab.Panel>
                                            <Tab.Panel className="relative">
                                                <div className='sticky top-0 left-0 z-10 bg-[#FFFFFF]'>
                                                    <div className="checkbox relative border-b border-[#F1F1F4]">
                                                        <label htmlFor="select-all"
                                                            className="pl-[33px] cursor-pointer text-[#2F2E38] text-base font-normal 
                                    flex items-center p-2 w-max">
                                                            Select all
                                                            <input
                                                                data-testid="select-all"
                                                                className="checkbox-input custom-checkbox-input"
                                                                type="checkbox"
                                                                id="select-all"
                                                                checked={isFullAccess}
                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='overflow-auto h-[calc(100vh-650px)] customScrollbar pr-5'>
                                                    <NestedCheckboxesAccordion items={items} setFormInput={setFormInput} formInput={formInput}
                                                        organizationalItems={organizationalItems} isFullAccess={isFullAccess} params={params} />
                                                </div>
                                            </Tab.Panel>
                                            <Tab.Panel>
                                                <FunctionCheckboxes
                                                    items={functions}
                                                    setFormInput={setFormInput}
                                                    formInput={formInput}
                                                    isFullAccess={isFullAccess}
                                                    Increaseheight='true'
                                                />
                                            </Tab.Panel>
                                        </Tab.Panels>
                                    </Tab.Group> :
                                    <div className='px-2'>
                                        <div className={`flex border rounded ${focused || searchValue ? "border-[#29275F]" : "border-[#ACABBA]"} w-[309px] py-2.5 pr-3 mb-2`}>
                                            <label></label>
                                            {searchValue || focused ?
                                                <img src='/Images/search-hightight.svg' alt='icon' className='mr-2 ml-3 w-5 h-5' loading='lazy' /> :
                                                <img src='/Images/search.svg' alt='icon' className='mr-2 ml-3 w-5 h-5' loading='lazy' />}
                                            <input type='text'
                                                value={searchValue}
                                                onFocus={(e) => { setFocused(true) }}
                                                onBlur={() => { setFocused(false) }}
                                                placeholder='Search by user group'
                                                data-testid="search"
                                                className='w-full outline-0 placeholder:text-[#918FA3] text-[#2F2E38] font-normal text-sm'
                                                onChange={(e) => {
                                                    handleSearchChange(e)
                                                }}
                                            />
                                        </div>
                                        {userGroupsList.length > 0 ?
                                            <UserGroupCheckboxes
                                                items={userGroupsList}
                                                setFormInput={setFormInput}
                                                formInput={formInput}
                                                setToastWarningMessage={setToastWarningMessage}
                                            /> :
                                            <NoReseultFoundSmallScreen
                                                searchValue={searchValue}
                                                searchLoader={searchLoader}
                                                noRecord={noRecord}
                                            />
                                        }
                                    </div>
                                }
                            </>
                        }

                        <div className=" flex items-center mt-[-6px] absolute bottom-[26px] left-6">
                            <button
                                disabled={loader}
                                type="button" data-testid="cancel-btn"
                                className={`py-2.5 px-5 border border-[#29275F] 
                                hover:border-[#4B42A3] text-base text-[#29275F] hover:text-[#4B42A3] 
                                font-bold rounded-lg flex items-center w-[315px] h-12 mr-3.5 justify-center 
                                ${loader ? 'opacity-[0.5]' : 'opacity-100 cursor-pointer'}`}
                                onClick={() => handleGoBack()}
                            >
                                Cancel
                            </button>
                            <button
                                disabled={loader || isFormInputUnchanged()}
                                type="button"
                                data-testid={params.id ? 'update' : 'aadd'}
                                className='flex justify-center items-center py-2.5 px-5
                                    rounded-lg bg-[#29275F] text-base text-[#FCFBFE] font-bold 
                                    hover:bg-[#4B42A3] disabled:bg-[#CCC9E9] w-[315px] h-12'
                                onClick={(e) => handleAddUser(e)}
                            >
                                {params.id ? 'Update' : 'Add'}
                                {loader && (
                                    <>
                                        <span className='pr-2'></span>
                                        <RotatingLines
                                            strokeColor="#FFFFFF"
                                            strokeWidth="5"
                                            animationDuration="0.55"
                                            width="15"
                                            visible={true}
                                        />
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </motion.div >
            }
        </>
    );
}
