import React from 'react'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom';

export default function CardHeder({children, headerName, navigationPath, pathName1, pathName2}) {
    let animationProps = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { duration: 0.5 } },
    };
    const navigate = useNavigate();
    return (
        <div className='bg-white rounded-tl-lg h-[calc(100vh-51px)]'>
            <motion.div {...animationProps} className={`${pathName1 ? 'pt-6 px-6 relative h-auto ' : 'px-6 pt-[72px]'}`}>
                {pathName1 && <p className='pb-6 text-[#2F2E38] text-base font-normal flex items-center'>
                    <p>
                        <span
                            onClick={() => navigate(navigationPath)} 
                            className='cursor-pointer'>{pathName1}</span>{' > '}
                    </p>
                    <span className='text-[#4B42A3] font-bold pl-1.5'>{pathName2}</span>
                </p>}
                <h1 className="text-[#2F2E38] text-[32px] font-bold">{headerName}</h1>
                {children}
            </motion.div>
        </div>
    )
}
