import { createSlice } from "@reduxjs/toolkit";

const privilegesSlice = createSlice({
    name: 'privileges',

    initialState: {
        privileges: {
            menu_items: [],
            payroll_functions: [],
            payrolls: []
        },
        role: '',
        isFetched: false,
    },
    
    reducers: {
        setPrivileges: (state, action) => {
            {console.log('action', action)}
            state.privileges = {
                menu_items: action.payload.privileges.menu_items || [],
                payroll_functions: action.payload.privileges.payroll_functions || [],
                payrolls: action.payload.privileges.payrolls || []
            };
            state.role = action.payload.role || '';
            state.isFetched = true;
        },
    },
});

export const { setPrivileges } = privilegesSlice.actions;
export default privilegesSlice.reducer;