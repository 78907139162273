/* eslint-disable max-len */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const user = localStorage.getItem('IdToken')
const initial = user ? true : false;
const initialState = {
    isAnimating:true,
}
            
const CommonSlice = createSlice({
    name: 'CommonData',
    initialState,
    reducers: {
        handleSidebarAnimation:(state,action)=>{
            state.isAnimating=action.payload
        },
    }
})
export const {handleSidebarAnimation} = CommonSlice.actions;
export default CommonSlice.reducer