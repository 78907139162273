/* eslint-disable max-len */

import React, { useState } from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip";


const InputFieldwithTooptip = ({
    label,
    formInput,
    handleChange,
    errorState,
    disabled,
    required,
    maxLength,
    tooltip,
    ...rest
}) => {
    const [focused, setFocused] = useState(false);

    return (
        <>
            <div data-tooltip-id="email" className={`${label === "Password" ? "mb-2.5" : (label === "Group name" || label === "Description") ?
                "mb-[24px]" : "mb-[30px]"}`}>
                <div className="relative">
                    <label
                        className={`absolute transition-all font-normal bg-white left-2 ${errorState[rest.id] !== "" ? "text-[#EA3829]" : "text-[#2F2E38]"
                        } duration-300 ${focused || formInput[rest.id] ? "top-[-8px] text-xs px-[4px]" : "top-1/2 -translate-y-1/2 text-[14px] mx-[5px]"
                        }`}
                        htmlFor={rest.id}
                    >
                        {label}
                        {required == true &&
                            <span className={`${errorState[rest.id] !== "" ? "text-[#EA3829]" : 'text-[#2F2E38]'}`}>*</span>}
                    </label>
                    <input
                        {...rest}
                        data-testid={rest.id}
                        maxLength={maxLength}
                        value={formInput[rest.id] || ""}
                        disabled={disabled}
                        onChange={(e) => handleChange(e)}
                        onFocus={(e) => setFocused(true)}
                        onBlur={() => { setFocused(false) }}
                        className={`${disabled ? 'text-[#918FA3] bg-[#FFFFFF]' : 'text-[#2F2E38]'} border rounded text-[14px] font-normal 
                w-full py-3 px-3 outline-0 focus-visible:outline-0
                ${label == "Password" && 'pr-[70px]'} ${errorState[rest.id] !== ""
            ? "border-[#EA3829]"
            : (focused || formInput[rest.id] && !disabled)
                ? "border-[#5E5C70]"
                : "border-[#ACABBA]"
        }`}
                    />
                </div>
                <p className="text-[#EA3829] text-xs font-normal pt-[2px] pl-3">
                    {errorState[rest.id]}
                </p>
            </div>

            {tooltip &&
            <ReactTooltip
                id="email"
                place="bottom-right"
                variant="info"
                content="Non-editable"
                arrowColor="white"
                style={{
                    backgroundColor: "#5E5C70",
                    color: "#F1F1F4",
                    height: '26px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '2px',
                    transform: 'translateX(85%)' // Adjust to align the tooltip to the right

                }}
            />}
        </>

    );
}
export default InputFieldwithTooptip;