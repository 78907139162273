import React, { useState } from 'react';

export default function PageNavigation({handlePrevPage,handleNextPage, prevPageExist,nextPage, currentPage, loading}) {
    const [isHoveredPrev, setIsHoveredPrev] = useState(false);
    const [isHoveredNext, setIsHoveredNext] = useState(false);

    const handleMouseEnterPrev = () => setIsHoveredPrev(true);
    const handleMouseLeavePrev = () => setIsHoveredPrev(false);

    const handleMouseEnterNext = () => setIsHoveredNext(true);
    const handleMouseLeaveNext = () => setIsHoveredNext(false);

    return (
        <div className='bg-white flex justify-between items-center w-full border-t border-[#EAECF0] 
        w-[-webkit-fill-available] py-3 custom-pagination'>
            <div>
                <button type='button'
                    disabled={loading || (!prevPageExist && currentPage == 1)}
                    data-testid="previous"
                    className='flex items-center border border-[#F1F1F4] h-10 justify-center hover:text-[#4B42A3]
                text-[#29275F] text-[12px] font-bold px-3 disabled:text-[#BFBECF] w-[143px]'
                    onClick={() => handlePrevPage()}
                    onMouseEnter={handleMouseEnterPrev}
                    onMouseLeave={handleMouseLeavePrev}
                >
                    <div className='pr-3 h-full flex items-center'>
                        <img
                            src={isHoveredPrev && nextPage ? '/Images/arrow-left-hover.svg' :
                                (prevPageExist ? '/Images/arrow-left-active.svg' : '/Images/pagination-arrow-left-inactive.svg')}
                            alt='icon'
                            loading='lazy'
                        />
                    </div>
                    Previous
                </button>
            </div>
            <div>
                <button type='button'
                    disabled={loading || !nextPage}
                    data-testid="next"
                    className='flex items-center border border-[#F1F1F4] h-10 justify-center hover:text-[#4B42A3]
                text-[#29275F] text-[12px] font-bold px-3 disabled:text-[#BFBECF] w-[143px]'
                    onClick={() => handleNextPage()}
                    onMouseEnter={handleMouseEnterNext}
                    onMouseLeave={handleMouseLeaveNext}
                >
                    Next
                    <div className='pl-3 h-full flex items-center'>
                        <img
                            src={isHoveredNext && nextPage ? '/Images/arrow-right-hover.svg' :
                                (nextPage ? '/Images/arrow-right-active.svg' : '/Images/pagination-arrow-right-inactive.svg')}
                            alt='icon'
                            loading='lazy'
                        />
                    </div>
                </button>
            </div>
        </div>
    );
}
