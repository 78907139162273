
/* eslint-disable max-len */
/* eslint-disable security/detect-object-injection */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion'
import { RotatingLines } from 'react-loader-spinner';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InputWithLabel from '../../CommonComponent/inputField';
import CountrySelectDropdown from './countryDropdown';
import { dataService } from '../../services/dataService';
import DateField from './dateField';
import CommonDropdown from '../../CommonComponent/CommonDropdown';
import Debounce from '../../CommonComponent/debounce';
import GlobalContext from '../../CommonComponent/context';
import InnerPageCommonLoader from '../../CommonComponent/InnerPageCommonLoader';
import CommonLoader from '../../CommonComponent/commonLoader';
import { getValueTrim } from '../../CommonComponent/InputValidation';



export default function CreatePayroll() {
    const { setToastmessage, setToastErrorMessage, setToastWarningMessage } = useContext(GlobalContext)
    const location = useLocation();
    const params = useParams()
    const navigate = useNavigate()
    const currentYear = new Date().getFullYear();


    let animationProps = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { duration: 0.6 } },
    };

    const [loader, setLoader] = useState(false)
    const previousUrl = location.state?.from?.pathname;

    const [isCreateLoading, setCreateLoading] = useState(false)

    const [formInput, setFormInput] = useState({
        payroll_name: "",
        payroll_type: "period",
        country: "",
        country_name: "",
        no_of_periods: "12",
        start_date: "",
        tax_method: "",
        tax_method_name: "",
        currency: "",
        currency_id: ""
    });

    const [errorState, setErrorState] = useState({
        payroll_name: "",
        payroll_type: "",
        country: "",
        country_name: "",
        no_of_periods: "",
        start_date: "",
        tax_method: "",
        tax_method_name: "",
        currency: "",
        currency_id: ""
    });
    const [payrollTypeOptions, setPayrollTypeOptions] = useState([])
    const [currencyOptions, setCurrencyOptions] = useState([])
    const [noOfPeriodOption, setNoofPeriodOption] = useState([])
    const [taxmethodOptions, setTaxmethodOptions] = useState([])
    const [countryList, setCountryList] = useState([])
    const [showDropdown, setShowDropdown] = useState(false);
    const [defaultCountryId, setDefaultCountryId] = useState('')
    const [searchText, setSearchText] = useState('');
    const [taxmethodsearchText, setTaxMethodSearchText] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false)
    const [payrollCreationLoader, setPayrollCreationLoader] = useState(false)
    const [inputFieldLoader, setInputFieldLoader] = useState(false)
    const [renderkey, setRenderkey] = useState(false); // Start with a number (or a string like 'initial')


    // const [selectedOption,setSelectedOption] = useState('')

    const [dropdownStates, setDropdownStates] = useState({
        payroll_type: false,
        no_of_periods: false,
        start_date: false,
        tax_method: false,
        tax_method_name: false,
        currency: false,
    });

    const [selectedOption, setSelectedOption] = useState({
        payroll_type: 'Period',
        no_of_periods: '12',
        start_date: null,
        tax_method: null,
        tax_method_name: null,
        currency: null,
    });


    const getCountry = async () => {
        try {
            setRenderkey(true)
            // Set loading to true when API call starts
            setCreateLoading(true);

            const data = await dataService.CountriesListing();

            // Check if there's no error and success_status is true
            if (data?.error === false && data?.data?.success_status === true) {
                // Extract default_country_start_date and other necessary values from data
                const { default_country_name, default_country, default_country_start_date } = data?.data;

                // Update the default country and country list in state
                setDefaultCountryId(default_country);
                setFormInput(prevData => ({
                    ...prevData,
                    country_name: default_country_name,
                    country: default_country,
                    start_date: `${default_country_start_date}/${currentYear}` // use the extracted variable
                }));
                setCountryList(data.data.data);
            } else {
                console.error('Error in API response or success_status is false');
            }
        } catch (error) {
            // Handle any errors during the API call
            console.error('Error fetching country data:', error);
        }
    };


    // Handle the click to toggle dropdowns
    const handleInputClick = (id) => {
        setTaxMethodSearchText('')
        setSearchText('')
        setDropdownStates((prev) => {
            const newDropdownStates = { ...prev };
            // Toggle the selected dropdown and close others
            Object.keys(newDropdownStates).forEach((key) => {
                newDropdownStates[key] = key === id ? !prev[id] : false;
            });
            return newDropdownStates;
        });
        setErrorState((prevState) => ({
            ...prevState,
            [id]: "",
        }));
    };

    const handleGoBack = () => {
        if (previousUrl === '/payroll') {
            navigate(-1); // Navigate to the previous URL in the history stack
        } else {
            navigate('/payrolls'); // Navigate to /user-groups
        }
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        let formValue = getValueTrim(id, value);
        setFormInput(prevData => ({ ...prevData, [id]: formValue.trimStart().replace(/\s{2,}/g, ' ') }));
        setErrorState((prevState) => ({
            ...prevState,
            [id]: "",
        }));
    };

    const fetchStaticData = async () => {
        try {
            const noOfPeriodresponse = await dataService.StaticData('no_of_periods.json');
            const typeResponse = await dataService.StaticData('payroll_type.json');
            // Update state with API data
            if (!typeResponse.error) {
                // Capitalize the first letter of 'Name' for each item in the PayrollType array
                const updatedPayrollType = typeResponse.data.data.PayrollType.map(item => ({
                    ...item,
                    Name: item.Name.charAt(0).toUpperCase() + item.Name.slice(1)
                }));

                // Update state with the modified array
                setPayrollTypeOptions(updatedPayrollType);
            }
            if (noOfPeriodresponse.error === false) {
                setNoofPeriodOption(noOfPeriodresponse.data.data.NoofPeriods);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setTimeout(() => {
                setCreateLoading(false);
            }, 2000);
        }
    };


    const handleOptionClick = (option, id) => {
        // Update selected option (without currency_id logic)
        setSelectedOption(prevOptions => ({
            ...prevOptions,
            [id]: option.value || option.Name || option.currency_name || option.tax_method, // Adjust based on your option structure
        }));

        // Update form input (with currency_id logic if id is 'currency')
        setFormInput(prevInput => ({
            ...prevInput,
            [id]: option.value || option.Name || option.currency_name, // Adjust based on your option structure
            ...(id === 'currency' && { currency_id: option.currency_id }), // Conditionally add currency_id if the id is 'currency'
            ...(id === 'tax_method_name' && { tax_method_id: option.taxmethod_id, tax_method_name: option.tax_method, tax_method: option.taxmethod_id }) // Conditionally add tax_method_id if id is 'tax_method'
        }));

        // Close dropdown after selection
        setDropdownStates(prevStates => ({
            ...prevStates,
            [id]: false
        }));
    };


    // functions for currency 
    // Fetch currency list based on search input
    const fetchCurrencyList = useCallback(async (searchKey = '') => {
        try {
            // Only send the search key if it's not empty
            const queryParam = searchKey ? `?search=${encodeURIComponent(searchKey)}` : '';
            const currencyresponse = await dataService.ListCurrencyTypes(queryParam);
            setCurrencyOptions(currencyresponse.data.data);
        } catch (error) {
            console.error('Error fetching currency list:', error);
        }
    }, []); // No dependencies needed; it will be called directly

    // Handle search input change
    const handleSearchInputChange = (e) => {
        const value = e.target.value;
        setSearchText(value);
    };

    // Debounced fetch function
    const debouncedFetchCurrencyList = useCallback(
        Debounce((value) => fetchCurrencyList(value), 300), // Adjust debounce delay as needed
        [fetchCurrencyList]
    );

    useEffect(() => {
        debouncedFetchCurrencyList(searchText); // Trigger the fetch with the debounced input
    }, [searchText, debouncedFetchCurrencyList]);

    useEffect(() => {
        // Initial fetch without any search
        fetchCurrencyList();
    }, [fetchCurrencyList]);


    const fetchTaxMethodsofCountry = useCallback(async (searchKey = '') => {
        try {
            // Construct the query parameters dynamically
            const queryParam = searchKey ? `&search=${encodeURIComponent(searchKey)}` : ''; // Add search if it exists
            if (defaultCountryId === '') {
                return;
            }

            const response = await dataService.ListTaxMethodsofCountry(defaultCountryId, queryParam); // Pass both country_id and search
            const taxMethods = response.data.data;

            setTaxmethodOptions(taxMethods);

            if (taxMethods.length > 0) {
                // Find the tax method where is_default is true
                const defaultTaxMethod = taxMethods.find(method => method.is_default === true);

                // If no default is found, fall back to the first method
                const selectedTaxMethod = defaultTaxMethod || { tax_method_name: '', tax_method: '' };;

                // Set the form input values based on the selected tax method
                setFormInput(prevData => ({
                    ...prevData,
                    tax_method: selectedTaxMethod.taxmethod_id,
                    tax_method_name: selectedTaxMethod.tax_method
                }));

                setSelectedOption(prevOptions => ({
                    ...prevOptions,
                    tax_method_name: selectedTaxMethod.tax_method
                }));
            }
        } catch (error) {
            console.error('Error fetching tax methods:', error);
        }
    }, [defaultCountryId]);


    // Handle search input change
    const handleSearchInputChangeforTaxMethods = (e) => {
        const value = e.target.value;
        setTaxMethodSearchText(value);
    };

    // Debounced fetch function
    const debouncedFetchTaxMethodList = useCallback(
        Debounce((value) => fetchTaxMethodsofCountry(value), 300), // Adjust debounce delay as needed
        [fetchTaxMethodsofCountry]
    );

    useEffect(() => {
        debouncedFetchTaxMethodList(taxmethodsearchText); // Trigger the fetch with the debounced input
    }, [taxmethodsearchText, debouncedFetchTaxMethodList]);

    useEffect(() => {
        // Initial fetch without any search
        if (defaultCountryId !== '') {
            fetchTaxMethodsofCountry();
        }
    }, [fetchTaxMethodsofCountry]);



    function validateForm() {
        let isValid = true;
        Object.keys(formInput).forEach((key) => {
            switch (key) {
            case key:
                if (formInput[key] === "") {
                    setErrorState((prevState) => ({
                        ...prevState,
                        [key]: "This field is mandatory",
                    }));
                    isValid = false;
                }
                break;
            default:
                break;
            }
        });
        return isValid;
    }


    const handlecreatePayroll = async () => {

        if (validateForm()) {
            setButtonLoader(true)
            let body = {
                "payroll_name": formInput.payroll_name,
                "payroll_type": formInput.payroll_type.toLowerCase(),
                "country_id": formInput?.country,
                // "country_id": '00854ab6-677d-4f60-80c6-78ea4f383051',
                "number_of_periods": formInput.no_of_periods,
                "payroll_start_date": formInput.start_date,
                "tax_method_id": formInput.tax_method,
                "currency_id": formInput.currency_id
            }
            const response = await dataService.CreatePayroll(body)
            if (response.error === false && response?.data?.success_status === true) {
                setPayrollCreationLoader(true)
                setButtonLoader(false)
                setTimeout(() => {
                    setPayrollCreationLoader(false)
                    navigate('/payrolls')
                }, 6000);

            } else {
                if (response.error === true && response.data.message === 'This payroll name already exists') {
                    setErrorState((prevState) => ({
                        ...prevState,
                        ["payroll_name"]: 'Payroll name already exists'
                    }));
                    setButtonLoader(false)

                }
                if (response.error === true && response.data.message === 'Error while creating payroll') {
                    setToastErrorMessage('Error while creating payroll')
                }

            }
            setButtonLoader(false)
        }

    }

    useEffect(() => {
        getCountry()
        fetchStaticData()
        if (defaultCountryId !== '') {
            fetchTaxMethodsofCountry();
        }
    }, [])

    return (
        <>
            {payrollCreationLoader ?
                <div className='bg-white'>
                    <CommonLoader message={'We are in the process of creating your payroll. This may take some time.'} />
                </div>
                :
                <div>
                    {isCreateLoading ?
                        <div className='flex justify-center items-center bg-[#ceceda] rounded-tl-lg'>
                            <InnerPageCommonLoader />
                        </div> :
                        <motion.div {...animationProps}>
                            <div className='rounded-tl-lg bg-white h-[calc(100vh-51px)] pl-6'>
                                <div className='text-base font-normal'>
                                    <button type='button' className='text-[#2F2E38] pt-7 pb-3.5'
                                        onClick={() => navigate('/payrolls')}>Payrolls</button>
                                    {` > `}
                                    <span className='text-[#4B42A3] font-bold'>{params.id ? 'Update' : 'Add'} Payroll</span>
                                </div>
                                <div className='flex justify-between'>
                                    <h1 className='text-[32px] text-[#2F2E38] font-bold pb-7'>{params.id ? 'Update' : 'Add'} Payroll</h1>
                                    <button
                                        disabled={loader}
                                        type="button" data-testid="cancel-btn"
                                        className={`py-2.5 px-5 border border-[#29275F] mr-[30px]
                            hover:border-[#4B42A3] text-base text-[#29275F] hover:text-[#4B42A3] 
                            font-bold rounded-lg flex items-center w-[315px] h-12 mr-3.5 justify-center 
                            ${loader ? 'opacity-[0.5]' : 'opacity-100 cursor-pointer'}`}
                                    // onClick={() => handleGoBack()}
                                    >
                                        Copy from existing payroll
                                    </button>
                                </div>
                                <div>
                                    <div className='flex'>
                                        <div className='w-[306px] mr-6'>
                                            <InputWithLabel
                                                id="payroll_name"
                                                label="Payroll name"
                                                type="text"
                                                autocomplete="off"
                                                required={true}
                                                handlePasswordFocus={null}
                                                handleChange={handleChange}
                                                formInput={formInput}
                                                errorState={errorState}
                                                showPassword={true}
                                                maxLength={60}
                                            />
                                        </div>
                                        <div className='w-[306px] mr-6 ant-picker-dropdown'>
                                            <CommonDropdown
                                                id="payroll_type"
                                                label="Payroll Type"
                                                type="text"
                                                autocomplete="off"
                                                required={true}
                                                handleChange={handleChange}
                                                formInput={formInput}
                                                errorState={errorState}
                                                options={payrollTypeOptions}
                                                setSelectedOption={setSelectedOption}
                                                selectedOption={selectedOption}
                                                handleOptionClick={handleOptionClick}
                                                showDropdown={dropdownStates.payroll_type}
                                                setShowDropdown={setShowDropdown}
                                                setTaxMethodSearchText={setTaxMethodSearchText}
                                                setSearchText={setSearchText}
                                                handleInputClick={handleInputClick}
                                                displayKey={"Name"}
                                                dropdownStates={dropdownStates}
                                                setDropdownStates={setDropdownStates}
                                                searchbar={false}
                                                dropdwonHeight={"h-[130px]"}
                                            />
                                        </div>
                                        <div className='w-[306px] mr-6'>
                                            <CountrySelectDropdown
                                                id={'country'}
                                                setFormInput={setFormInput}
                                                formInput={formInput}
                                                errorState={errorState}
                                                setErrorState={setErrorState}
                                                isBankCountry={true}
                                                isTaxCountry={false}
                                                countryList={countryList}
                                                setDefaultCountryId={setDefaultCountryId}
                                                setCreateLoading={setCreateLoading}
                                                isCreateLoading={isCreateLoading}
                                                setInputFieldLoader={setInputFieldLoader}
                                                setSelectedOption={setSelectedOption}
                                                setRenderkey={setRenderkey}
                                                renderkey={renderkey}

                                            />
                                        </div>
                                    </div>
                                    <div className='flex'>
                                        <div className='w-[306px] mr-6'>
                                            <CommonDropdown
                                                id="no_of_periods"
                                                label="No of Periods"
                                                type="text"
                                                autocomplete="off"
                                                required={true}
                                                handleChange={handleChange}
                                                formInput={formInput}
                                                errorState={errorState}
                                                options={noOfPeriodOption}
                                                setSelectedOption={setSelectedOption}
                                                selectedOption={selectedOption}
                                                handleOptionClick={handleOptionClick}
                                                showDropdown={showDropdown.no_of_periods}
                                                setShowDropdown={setShowDropdown}
                                                setTaxMethodSearchText={setTaxMethodSearchText}
                                                setSearchText={setSearchText}
                                                handleInputClick={handleInputClick}
                                                displayKey={"Name"}
                                                dropdownStates={dropdownStates}
                                                setDropdownStates={setDropdownStates}
                                                searchbar={false}
                                                dropdwonHeight={"h-[130px]"}

                                            />
                                        </div>
                                        <div className='w-[306px] mr-6'>
                                            <DateField
                                                id="start_date"
                                                label="Start Date"
                                                type="text"
                                                required={true}
                                                handleChange={handleChange}
                                                formInput={formInput}
                                                errorState={errorState}
                                                setErrorState={setErrorState}
                                                setFormInput={setFormInput}
                                                loading={loader}
                                                setRenderkey={setRenderkey}
                                                renderkey={renderkey}
                                            />
                                        </div>
                                        <div className='w-[306px] mr-6'>
                                            <CommonDropdown
                                                id="tax_method_name"
                                                label="Tax Method"
                                                type="text"
                                                autocomplete="off"
                                                required={true}
                                                handleChange={handleChange}
                                                formInput={formInput}
                                                errorState={errorState}
                                                options={taxmethodOptions}
                                                setSelectedOption={setSelectedOption}
                                                selectedOption={selectedOption}
                                                handleOptionClick={handleOptionClick}
                                                showDropdown={showDropdown.tax_method}
                                                setShowDropdown={setShowDropdown}
                                                setDropdownStates={setDropdownStates}
                                                handleInputClick={handleInputClick}
                                                displayKey={"tax_method"}
                                                dropdownStates={dropdownStates}
                                                setTaxMethodSearchText={setTaxMethodSearchText}
                                                setSearchText={setSearchText}
                                                handleSearchInputChange={handleSearchInputChangeforTaxMethods}
                                                searchText={taxmethodsearchText}
                                                searchbar={true}
                                                dropdwonHeight={"h-[199px]"}
                                                inputFieldLoader={inputFieldLoader}
                                                message={taxmethodsearchText === '' ? 'No Tax Methods Found' : 'We could not find any matches for your search.' }

                                            />
                                        </div>
                                    </div>
                                    <div className='flex'>
                                        <div className='w-[306px] mr-6'>
                                            <CommonDropdown
                                                id="currency"
                                                label="Currency"
                                                type="text"
                                                autocomplete="off"
                                                required={true}
                                                handleChange={handleChange}
                                                formInput={formInput}
                                                errorState={errorState}
                                                options={currencyOptions}
                                                setSelectedOption={setSelectedOption}
                                                selectedOption={selectedOption}
                                                handleOptionClick={handleOptionClick}
                                                showDropdown={showDropdown.currency}
                                                setShowDropdown={setShowDropdown}
                                                setDropdownStates={setDropdownStates}
                                                handleInputClick={handleInputClick}
                                                setTaxMethodSearchText={setTaxMethodSearchText}
                                                setSearchText={setSearchText}
                                                displayKey={"currency_name"}
                                                dropdownStates={dropdownStates}
                                                handleSearchInputChange={handleSearchInputChange}
                                                searchText={searchText}
                                                searchbar={true}
                                                dropdwonHeight={"h-[199px]"}
                                                message={searchText === '' ? 'No Currencies Found' : 'We could not find any matches for your search.' }
                                            />
                                        </div>
                                        <div className='w-[306px] mr-6'></div>
                                        <div className='w-[306px] mr-6'></div>
                                    </div>
                                    <div className=" flex items-center mt-[-6px] absolute bottom-[26px] left-6">
                                        <button
                                            disabled={loader}
                                            type="button" data-testid="cancel-btn"
                                            className={`py-2.5 px-5 border border-[#29275F] 
                            hover:border-[#4B42A3] text-base text-[#29275F] hover:text-[#4B42A3] 
                            font-bold rounded-lg flex items-center w-[315px] h-12 mr-3.5 justify-center 
                            ${loader ? 'opacity-[0.5]' : 'opacity-100 cursor-pointer'}`}
                                            onClick={() => handleGoBack()}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            disabled={buttonLoader}
                                            type="button"
                                            data-testid={params.id ? 'update' : 'aadd'}
                                            className='flex justify-center items-center py-2.5 px-5
                                rounded-lg bg-[#29275F] text-base text-[#FCFBFE] font-bold 
                                hover:bg-[#4B42A3] disabled:bg-[#CCC9E9] w-[315px] h-12'
                                            onClick={(e) => handlecreatePayroll(e)}
                                        >
                                            {params.id ? 'Update' : 'Add'}
                                            {buttonLoader && (
                                                <>
                                                    <span className='pr-2'></span>
                                                    <RotatingLines
                                                        strokeColor="#FFFFFF"
                                                        strokeWidth="5"
                                                        animationDuration="0.55"
                                                        width="15"
                                                        visible={true}
                                                    />
                                                </>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </motion.div >
                    }
                </div>
            }
        </>
    );
}
