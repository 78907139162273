/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import Toast from './CommonComponent/toastMeasseage';
import NavigationRoutes from './Routes'
import GlobalContext from './CommonComponent/context';
import { dataService } from './services/dataService';
import configureAmplify from './components/AmplyfyConfig';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { setPrivileges } from './Redux/privilageSlice';
import { useDispatch, useSelector } from 'react-redux';



function App(props) {
    const dispatch = useDispatch()
    const [awsConfig, setAwsConfig] = useState(false);
    const navigationPath = NavigationRoutes(props, awsConfig)
    const [Toastmessage, setToastmessage] = useState('');
    const [ToastErrorMessage, setToastErrorMessage] = useState('')
    const [toastWarningMessage, setToastWarningMessage] = useState('')
    const [token, setToken] = useState('')
    const [countryList, setCountryList] = useState([])
    const { privileges, role, isFetched } = useSelector((state) => state.privileges);

    const [currentSelectedpayroll, setCurrentSelectedPayroll] = useState(null);

    const [isSwitchPayroll, setIsSwitchPayroll] = useState(false);
    useEffect(() => {
        configureAmplify(setAwsConfig);
    }, [])

    /* The `useEffect` hook in this code is used to perform side effects in a functional component. In
        this case, it is used to check if there is a token parameter in the URL query string. */
    useEffect(() => {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const token = params.get('token');
        setToken(params.get('token'))

        // if (token===null || token==undefined) {
        //     navigate('/')
        // }
    }, [])


    const handleFetchPrivileges = async () => {
        try {
            const userAttributes = await fetchUserAttributes();
            const userRole = userAttributes['custom:user_type'];

            let userPrivileges = {};

            if (userRole.toLowerCase() === 'admin' || userRole.toLowerCase() === 'super admin') {
                userPrivileges = {
                    menu_items: ['*'],
                    payroll_functions: ['*'],
                    payrolls: ['*']
                };
            } else {
                const response = await dataService.getAPI('user-management/privileges/');
                if (response.error === false &&
                    response.data &&
                    response.data.data &&
                    response.data.data.user_privilege) {
                    userPrivileges = response.data.data.user_privilege;
                } else {
                    console.error('Invalid response structure:', response);
                    throw new Error('Invalid response structure');
                }
            }
            dispatch(setPrivileges({ privileges: userPrivileges, role: userRole }));
        } catch (error) {
            console.error('Error in handleFetchPrivileges:', error);
            // You might want to dispatch an error action here or show an error message to the user
        }
    };

    const fetchPayrolls = async (search) => {
        let endpoint = `?limit=100`;
        if (search) {
            endpoint += `&search=${search}`;
        }
        const data = await dataService.ListPayrolls(endpoint);
        if (data.error === false) {
            localStorage.setItem('selectedOption', JSON.stringify(data.data.data[0]));
            setCurrentSelectedPayroll(data.data.data[0])
        }
    };

    useEffect(() => {
        handleFetchPrivileges();
        if(awsConfig){
            fetchPayrolls()
        }
    }, [awsConfig]);

    return (
        <GlobalContext.Provider
            value={{
                setToastmessage, Toastmessage, setToastErrorMessage, toastWarningMessage,
                setToastWarningMessage, awsConfig, countryList,handleFetchPrivileges,currentSelectedpayroll,setCurrentSelectedPayroll, isSwitchPayroll, setIsSwitchPayroll
            }}>
            <div>
                {Toastmessage !== '' && <Toast
                    message={Toastmessage}
                    setToastmessage={setToastmessage}
                    type="success"
                />}
                {ToastErrorMessage !== '' && <Toast
                    message={ToastErrorMessage}
                    setToastmessage={setToastErrorMessage}
                    type="error"
                />}
                {toastWarningMessage !== '' && (
                    <Toast
                        message={toastWarningMessage}
                        setToastmessage={setToastWarningMessage}
                        type="warning"
                    />
                )}
                {navigationPath}
            </div>
        </GlobalContext.Provider>
    )
}

export default App
