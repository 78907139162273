/* eslint-disable max-len */
import React, { useState } from "react";
import { motion } from 'framer-motion';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import StatusBadge from "../../CommonComponent/StatusBadge";

export const PayrollsTableBody = ({ data, handleActionModal }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [isHoverSwitch, setIsHoverSwitch] = useState({
        index: '',
        hover: false
    })
    const [isHoverEdit, setIsHoverEdit] = useState({
        index: '',
        hover: false
    })
    const animations = {
        initial: { opacity: 0, x: -10 },
        animate: { opacity: 2, x: 0 },
        exit: { opacity: 0, x: 100 }
    }
    return (
        <tbody className='border-l border-r border-[#F1F1F4]'>
            {data.length > 0 && data.map((row, rowIndex) => (
                <motion.tr key={rowIndex} variants={animations}
                    id="list-items"
                    initial={{ opacity: 0, y: 0 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    className="border-b border-[#F1F1F4] h-[45px]">
                    <td data-testid="admin_user_table" id={`group_name${rowIndex}`} className="px-6 text-[14px] leading-5 text-[#2F2E38] font-normal break-all max-w-[180px] sticky left-0 bg-white z-[1]">{row.payroll_name ? row.payroll_name : '-'}</td>
                    <td className="px-6 text-[14px] leading-5 text-[#2F2E38] font-normal break-all max-w-[180px]">  {row.payroll_type.charAt(0).toUpperCase() + row.payroll_type.slice(1)}</td>
                    <td className="px-6 text-[14px] leading-5 text-[#2F2E38] font-normal break-all max-w-[350px]">{row.number_of_periods}</td>
                    <td className="px-6 text-[14px] leading-5 text-[#2F2E38] font-normal break-all max-w-[180px]">{row.current_period}
                    </td>
                    <td className="px-6 text-[14px] leading-5 text-[#2F2E38] font-normal break-all max-w-[180px]">
                        {row.country_name}
                    </td>
                    <td className="px-6">
                        <StatusBadge status={row.payroll_status}/>
                    </td>
                    <td className="px-6 text-[14px] leading-5 text-[#2F2E38] font-normal min-w-[200px] sticky right-0 bg-white">
                        <div className='flex items-center justify-center'>
                            <div className='w-[25px] mr-5'
                                onMouseOver={() => setIsHoverEdit({
                                    index: rowIndex,
                                    hover: true
                                })}
                                onMouseLeave={() => setIsHoverEdit({
                                    index: rowIndex,
                                    hover: false
                                })}>
                                <img
                                    src={`${isHoverEdit.index == rowIndex &&
                                        isHoverEdit.hover ? '/Images/edit-action.svg' : '/Images/Icon.svg'}`}
                                    alt='icon' loading='lazy' data-tooltip-id={`edit-${rowIndex}`}
                                    className={'cursor-pointer'}
                                    data-testid={`edit-btn-${rowIndex}`}
                                    // onClick={() =>
                                    //     ((row.user_role !== 'super-admin') && (!row.current_user))? 
                                    //         navigate(`/user-management/users/edit/${row.user_id}`, { state: { from: location } })
                                    //         : handleActionModal(row, 'edit')
                                    // } 
                                />
                                <ReactTooltip
                                    id={`edit-${rowIndex}`}
                                    place="left"
                                    effect="solid"
                                    backgroundColor="#5E5C70"
                                    textColor="#F1F1F4"
                                    arrowColor="white"
                                    style={{
                                        backgroundColor: "#5E5C70",
                                        color: "#F1F1F4",
                                        height: '26px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderRadius: '2px'
                                    }}
                                >
                                    Update
                                </ReactTooltip>
                            </div>
                            <div className='w-[25px]'
                                onMouseOver={() => setIsHoverSwitch({
                                    index: rowIndex,
                                    hover: true
                                })}
                                onMouseLeave={() => setIsHoverSwitch({
                                    index: rowIndex,
                                    hover: false
                                })}>
                                <img
                                    src={`${isHoverSwitch.index == rowIndex &&
                                        isHoverSwitch.hover ? '/Images/hover-switch.svg' : '/Images/switch.svg'}`}
                                    alt='icon' loading='lazy' data-tooltip-id={`clone-${rowIndex}`}
                                    data-testid={`reset-password-${rowIndex}`}
                                    className={'cursor-pointer'}
                                    // onClick={() =>handleActionModal(row, 'password')}

                                />
                                <ReactTooltip
                                    id={`clone-${rowIndex}`}
                                    place="left"
                                    effect="solid"
                                    backgroundColor="#5E5C70"
                                    textColor="#F1F1F4"
                                    arrowColor="white"
                                    style={{
                                        backgroundColor: "#5E5C70",
                                        color: "#F1F1F4",
                                        height: '26px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderRadius: '2px'
                                    }}
                                >

                                    Switch
                                </ReactTooltip>
                            </div>
                        </div>
                    </td>
                </motion.tr>
            ))}
        </tbody>
    );
};
