import React from 'react'

export default function SearchInputLoadingShimmer({length}) {
    return (
        <div className="shimmer-container h-full flex flex-col items-center justify-center">
            {Array.from({ length: length }).map((_, index) => (
                <div
                    key={index}
                    className="w-[90%] h-[40px] bg-[#dbd9d9f3] rounded mb-2 animate-pulse"
                ></div>
            ))}
        </div>
    )
}
