import React from 'react'
import { Tab } from '@headlessui/react'
import { useLocation } from 'react-router-dom';

export default function TableTabs({handleTabSwitch, tabs, children}) {
    const path = useLocation()

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    return (
        <Tab.Group>
            <Tab.List
                className="mb-[26px] flex pt-6 list-none flex-row flex-wrap pl-0 w-full border-b-[0.5px] border-[#D6D5DD]"
                role="tablist"
                data-te-nav-ref>
                {tabs.map((item) => (
                    <Tab 
                        key={item} // Ensure each element has a unique key
                        role="presentation" 
                        id={item} // Assign the id dynamically based on the item
                        className={({ selected }) =>
                            classNames("w-fit block text-center p-2.5 text-base capitalize",
                                "font-normal leading-normal hover:isolate hover:border-[#29275F] mr-12 border-b-[2px]", 
                                "focus:isolate", 
                                path.pathname.includes(`/${item}`) 
                                    ? 'bg-[#ffffff] border-[#29275F] text-[#29275F]' 
                                    : 'text-[#2F2E38] border-transparent')
                        }
                        onClick={() => handleTabSwitch(item)}
                    >
                        {item.replaceAll('-', ' ')}
                    </Tab>
                ))}
            </Tab.List>
            <Tab.Panels>
                {children}
            </Tab.Panels>
        </Tab.Group>
    )
}
