import React, { useEffect } from 'react'
import CardHeder from '../../../CommonComponent/CardHeder'
import TableTabs from '../../../CommonComponent/Tables/TableTabs'
import { useLocation, useNavigate } from 'react-router-dom'
import CurrencyConversionList from './UnitsListing';
import EmptyScreen from '../../../CommonComponent/EmptyScreen';
import NotFoundPage from '../../../CommonComponent/404Page';
import UnitsListing from './UnitsListing';

export default function Units() {
    const navigate = useNavigate();
    const path = useLocation()


    return (
        <CardHeder headerName={'Units'} navigationPath={'/setup'} pathName1={'Setup'} pathName2={'Units'}>
            <UnitsListing />
        </CardHeder>
    )
}
