/* eslint-disable max-len */
import React, { Fragment, useState } from "react";
import { motion } from 'framer-motion';
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import StatusBadge from "../StatusBadge";
import HoveringTooltip from "../HoveringTooltip";

export const TableBody = ({ data, handleAction, tableHederData }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [isHover, setIsHover] = useState({})
    const animations = {
        initial: { opacity: 0, x: -10 },
        animate: { opacity: 2, x: 0 },
        exit: { opacity: 0, x: 100 }
    }
    const getItem = (key, item) => {
        switch (item) {
        case 'last_logged_in':
            return key ? moment.unix(key).format('YYYY-MM-DD HH:mm') : '-';
        case 'conversion_rate' :
            return parseFloat(key).toFixed(2);
        case 'user_status' :
            return <StatusBadge status={key}/>
        case 'applies_to':
            return key === null ? '-' : key
        default:
            return key
        }
    }
    const getActionsImage = (key, active) => {
        switch (key) {
        case 'Delete':
            if(active) {
                return 'delete-action.svg'
            } else {
                return 'trash-2.svg'
            }    
        case 'Update':
            if(active) {
                return 'edit-action.svg'
            } else {
                return 'Icon.svg'
            } 
        case 'Clone':
            if(active) {
                return 'clone.svg'
            } else {
                return 'copy.svg'
            }
        case 'Reset password':
            if(active) {
                return 'reset-password-active.svg'
            } else {
                return 'reset-password.svg'
            }
        default:
            break;
        }

    }
    return (
        <tbody className='border-l border-r border-[#F1F1F4]'>
            {data.length > 0 && data.map((row, rowIndex) => (
                <motion.tr key={rowIndex} variants={animations}
                    id="list-items"
                    initial={{ opacity: 0, y: 0 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    className="border-b border-[#F1F1F4] h-[45px]">
                    {
                        Object.keys(tableHederData).map((item) => (
                            <Fragment key={item}>
                                {item === 'actions' ?
                                    <td className={`px-6 text-[14px] leading-5 text-[#2F2E38] font-normal min-w-[200px] right-0 bg-white ${tableHederData[item].sticky ? 'sticky' : ''}`}>
                                        <div className='flex items-center justify-center'>
                                            {tableHederData.actions.actionsList.map((actionItem, actionIndex) => (
                                                <div
                                                    className={`w-[25px] ${actionIndex === 0 ? '' : 'ml-5'}`} 
                                                    key={actionItem}
                                                    onMouseOver={() => setIsHover((prevState) => ({
                                                        ...prevState,
                                                        [actionItem]: {
                                                            index: rowIndex,
                                                            hover: true
                                                        },
                                                    }))}
                                                    onMouseLeave={() => setIsHover((prevState) => ({
                                                        ...prevState,
                                                        [actionItem]: {
                                                            index: rowIndex,
                                                            hover: false
                                                        },
                                                    }))}
                                                >
                                                    <img
                                                        src={`/Images/${getActionsImage(actionItem, (isHover[actionItem]?.index == rowIndex &&
                                                            isHover[actionItem]?.hover))}`}
                                                        alt='icon' loading='lazy' 
                                                        data-tooltip-id={`${actionItem}-${rowIndex}-tooltip`}
                                                        data-testid={`${actionItem}-${rowIndex}`}
                                                        className={'cursor-pointer'}
                                                        onClick={() =>handleAction(row, actionItem)}

                                                    />
                                                    <HoveringTooltip place={"left"} Name={actionItem} id={`${actionItem}-${rowIndex}-tooltip`}/>
                                                </div>))}
                                        </div>
                                    </td> 
                                    :
                                    <td
                                        data-testid={`${item}-${rowIndex}`}
                                        id={`group_name${rowIndex}`} className="px-6 text-[14px] leading-5 text-[#2F2E38] font-normal break-all max-w-[180px]">
                                        {getItem(row[item], item)}
                                    </td>
                                }
                            </Fragment>
                        ))
                    }
                    
                </motion.tr>
            ))}
        </tbody>
    );
};
