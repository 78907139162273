/* eslint-disable max-len */
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { RotatingLines } from "react-loader-spinner";

export default function ActionModal({
    isOpen,
    setIsOpen,
    mainHeading,
    message,
    ctaButton,
    cancelButton,
    handleClickFunction,
    loader,
    secondaryMessage,
}) {
    const handleClose = () => {
        if (!loader) {
            setIsOpen('');
        }
    };

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={handleClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/60" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    className="w-[400px] transform overflow-hidden rounded-lg 
                                bg-white text-left align-middle shadow-xl transition-all relative">
                                    <div className='bg-[#F1F1F4] py-[8px] px-[12px] relative'>
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-semibold leading-6 text-[#2F2E38] text-center"
                                        >
                                            {mainHeading}
                                        </Dialog.Title>
                                        <img
                                            src="/Images/close-icon.svg"
                                            alt="icon"
                                            loading="lazy"
                                            className="cursor-pointer absolute top-1/2 -translate-y-1/2 right-3.5 w-5 h-5"
                                            style={{
                                                opacity: loader ? 0.5 : 1,
                                                pointerEvents: loader ? "none" : "auto",
                                            }}
                                            onClick={() => !loader && setIsOpen('')}
                                        />
                                    </div>
                                    <div className='mb-[20px]'>
                                        <div className="p-[12px]">
                                            <p className="text-base text-[#2F2E38] font-normal text-center">
                                                {message}
                                            </p>
                                            <p className="text-base text-[#2F2E38] font-normal text-center">
                                                {secondaryMessage}
                                            </p>
                                        </div>
                                        <div className=" flex justify-center items-center">
                                            {cancelButton && <button
                                                type="button"
                                                data-testid="cancel-btn"
                                                className="py-2 px-[16px] h-[37px] border border-[#29275F] 
                                        hover:border-[#4B42A3] text-base text-[#29275F] hover:text-[#4B42A3] 
                                        font-bold rounded-lg flex items-center mr-3.5 justify-center"
                                                onClick={() => setIsOpen('')}
                                                disabled={loader}
                                            >
                                                {cancelButton}
                                            </button>}
                                            <button
                                                disabled={loader}
                                                type="button"
                                                data-testid="confirm-btn"
                                                className="flex justify-center items-center py-2 px-[16px] h-[37px]
                                            rounded-lg bg-[#29275F] text-base text-[#FCFBFE] font-bold 
                                            hover:bg-[#4B42A3] disabled:bg-[#CCC9E9]"
                                                onClick={() => handleClickFunction()}
                                            >
                                                {ctaButton}
                                                {loader && (
                                                    <>
                                                        <span className="pr-2"></span>
                                                        <RotatingLines
                                                            strokeColor="#FFFFFF"
                                                            strokeWidth="5"
                                                            animationDuration="0.55"
                                                            width="15"
                                                            visible={true}
                                                        />
                                                    </>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
