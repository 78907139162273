import React, { useEffect, useMemo } from 'react';
import CardHeder from '../../../CommonComponent/CardHeder';
import TableTabs from '../../../CommonComponent/Tables/TableTabs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CurrencyConversionList from './CurrencyConversionList';
import EmptyScreen from '../../../CommonComponent/EmptyScreen';
import CurrencyTypeList from './CurrencyTypeList';
import { useDispatch, useSelector } from 'react-redux';
import { handleCurrentPage, handlePagination } from '../../../Redux/paginationSlice';
import { handleSidebarAnimation } from '../../../Redux/commonSlice';

export default function Currencies() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    
    const { privileges = {}, role = '' } = useSelector((state) => state.privileges || {});
    const params = useParams();
    
    const isAdmin = role === 'admin' || role === 'super admin';

    const tabPrivileges = {
        'currency-types': 'setup:currency_conversion',
        'currency-conversion': 'setup:currency_conversion',
        'coinage': 'setup:coinage',
    };

    const availableTabs = useMemo(() => {
        return Object.entries(tabPrivileges)
            .filter(([tab, privilege]) =>
                isAdmin || privileges?.menu_items?.includes(privilege)
            )
            .map(([tab]) => tab);
    }, [privileges, isAdmin]);

    useEffect(() => {
        if (availableTabs.length > 0 && !availableTabs.includes(params.tab)) {
            navigate('/page-not-found');
        }
    }, [availableTabs, location.pathname, navigate]);

    const renderContent = () => {
        if (!availableTabs.includes(params.tab)) {
            navigate('/page-not-found');
            return;
        }
        switch (params.tab) {
        case 'currency-conversion':
            return <CurrencyConversionList />;
        case 'currency-types':
            return <CurrencyTypeList />
        case 'coinage':
            return <EmptyScreen Tittle="coinage" handleAdd={() => { }} />;
        default:
            return navigate('/page-not-found');
        }
    };

    const handleTabSwitch = (item) => {
        dispatch(handleSidebarAnimation(false))
        dispatch(handleCurrentPage(1));
        dispatch(handlePagination({}));
        navigate(`/setup/currencies/${item}`);
    }

    return (
        <CardHeder headerName={'Currencies'} navigationPath={'/setup'} pathName1={'Setup'} pathName2={'Currencies'}>
            <TableTabs handleTabSwitch={handleTabSwitch} tabs={availableTabs} />
            {renderContent()}
        </CardHeder>
    );
}
