import React, { useState } from "react";

const PasswordInputWithLabel = ({
    label,
    passwordError,
    handlePasswordChange,
    password,
    errorState,
    showPassword,
    setShowPassword,
    ...rest
}) => {
    const [focused, setFocused] = useState(false);
    return (
        <div>
            <div className="relative">
                <label
                    className={`absolute transition-all left-2 text-[14px] font-normal bg-white ${
                        passwordError !== null || errorState ? "text-[#EA3829]" : "text-[#2F2E38]"
                    } duration-300 ${
                        focused || password ? "top-[-8px] text-xs px-[4px] " : "top-[50%] translate-y-[-50%] text-sm mx-[4px] "
                    }`}
                    htmlFor={rest.id}
                >
                    {label}
                    <span className={`${errorState ? 'text-[#EA3829]' : 'text-[#2F2E38]'}`}>*</span>
                </label>
                <input
                    {...rest}
                    data-testid={rest.id}
                    value={password || ""}
                    onChange={(e) => handlePasswordChange(e)}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    className={`text-[#2F2E38] border h-[50px] rounded-[4px] font-normal outline-0 w-full py-[14px] px-[12px]
                focus-visible:outline-0 pr-[40px]
                ${showPassword?'text-[14px] leading-5 ':'text-[24px] leading-[14px]'}
                ${label == "Password" && 'pr-[70px]'} ${
            passwordError !== null || errorState
                ? "border-[#EA3829]"
                : focused || password
                    ? "border-[#29275F]"
                    : "border-[#D6D5DD]"
        }`}
                />
                <img
                    src={
                        password.length == 0 ?
                            (showPassword ? '/Images/closed-eye-inactive.svg' : '/Images/eye-open-inactive.svg') :
                            showPassword
                                ? "/Images/eye-off-active.svg"
                                : "/Images/eye-open-active.svg"
                    }
                    alt="icon"
                    loading='lazy'
                    className="absolute top-[50%] translate-y-[-50%] right-2.5 cursor-pointer w-5"
                    onClick={() => setShowPassword(!showPassword)} />
            </div>
            {passwordError!==null && <p className="text-[#EA3829] text-xs font-normal pt-[2px] pl-3">
                {passwordError}
            </p>}
        </div>
    );
};

export default PasswordInputWithLabel;
