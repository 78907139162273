/* eslint-disable max-len */
/* eslint-disable security/detect-object-injection */
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatePicker, Space } from 'antd';
dayjs.extend(customParseFormat);

// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS

const CustomDatePicker = ({
    formInput,
    setFormInput,
    setErrorState,
    errorState,
    label,
    disabled,
    focused,
    setFocused,
    disableYearSelection,
    loading,
    setRenderkey,
    renderkey,
    dateKey // Key to be used from formInput
}) => {
    const currentYear = new Date().getFullYear();
    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const disabledDate = (current) => {
        if (disableYearSelection) {
            return current && current.year() !== currentYear;
        }
        return false;
    };

    const setDate = (date) => {
        if (date) {
            const formattedDay = date.date() < 10 ? `0${date.date()}` : date.date();
            const formattedMonth = (date.month() + 1) < 10 ? `0${date.month() + 1}` : date.month() + 1;
            const formattedYear = date.year();
            const formattedDate = `${formattedDay}/${formattedMonth}/${formattedYear}`;
            setFormInput((prevState) => ({
                ...prevState,
                [dateKey]: formattedDate === '01/01/1970' ? '' : formattedDate,
            }));
            setErrorState((prevState) => ({
                ...prevState,
                [dateKey]: "",
            }));
        }
    };


    return (
        <Space direction="vertical" size={12}>
            <DatePicker
                disabled={loading}
                data-testid="date"
                defaultValue={renderkey && formInput[dateKey] ? dayjs(formInput[dateKey], dateFormatList[0]) : ''}
                format={dateFormatList}
                onChange={(date) => setDate(date)}
                allowClear={false}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                placeholder=''
                suffixIcon={<img src='/Images/calendar.svg' alt='icon' loading='lazy' />}
                className={`px-3 ${disabled ? 'disabled' : ''} ${errorState[dateKey] !== "" ? "error" : (focused || formInput[dateKey] && !disabled) ? "active" : ""}`}
                disabledDate={disabledDate}
            />

        </Space>
    );
};

export default CustomDatePicker;