export const FelidValidation = (array, setErrorState, formInput) => {
    let count = 0
    array.forEach(element => {
        if(formInput[element] === '') {
            setErrorState((prevState) => ({
                ...prevState,
                [element] : "This field is mandatory",
            }));
            count += 1
        } else {
            switch (element) {
            case 'conversion_rate':
                if(parseFloat(formInput[element]) <= 0) {
                    setErrorState((prevState) => ({
                        ...prevState,
                        [element] : "Conversion rate must be greater than 0",
                    }));
                    count += 1
                }
                break;
            default:
                   
                break;
            }
        }
    });
    return count === 0 ? false : true
}