/* eslint-disable max-len */
import React from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip";

const Userfilter = ({ filterRef, setIsfilteropen, isFilterOpen, isFilterApplied, clearFilters, handleFilter, selectedFilterParameter, setSelectedFilterParameter, filterData, setFilterData }) => {

    const handleCheckboxChange = (e) => {
        const { name, value, checked } = e.target;

        setFilterData((prevState) => {
            let updatedValues = prevState[name] ? prevState[name].split(',') : [];

            if (checked) {
                updatedValues.push(value);
            } else {
                updatedValues = updatedValues.filter((item) => item !== value);
            }
            return {
                ...prevState,
                [name]: updatedValues.join(',')
            };
        });
    };
    const isCheckboxChecked = (name, value) => {
        return filterData[name] ? filterData[name].split(',').includes(value) : false;
    };

    return (
        <div ref={filterRef}>
            <button type='button' data-testid="filter-btn" data-tooltip-id="filter"
                className={`w-10 h-10 justify-center rounded-full flex items-center hover:bg-[#CCC9E9] mr-6 relative
                                ${isFilterOpen ? 'bg-[#CCC9E9]' : ''}`}
                onClick={() => { setIsfilteropen(!isFilterOpen) }}>
                <img src='/Images/filter-list.svg' alt='icon' loading='lazy' />
                {isFilterApplied &&
                    <span className='w-2 h-2 rounded-full bg-[#655CBD] absolute top-2 right-1.5'></span>}
            </button>
            <ReactTooltip
                id="filter"
                place="top"
                variant="info"
                content="Filter"
                arrowColor="white"
                style={{
                    backgroundColor: "#5E5C70",
                    color: "#F1F1F4",
                    height: '28px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '2px'
                }}
            />
            <div className={`dropdown-container absolute z-20 w-[485px] top-[50px] h-[150px] mr-[12px] rounded-lg py-3.5 px-4 bg-white 
                        right-2.5 shadow-s4 border 
                        border-[#CCC9E9] ${isFilterOpen ? 'open opacity-100' : 'closed opacity-0 max-h-0 overflow-hidden'}`}>
                <div className='flex'>
                    <div className={`border-r border-[#57A8DC] mr-4 pr-10 text-base text-[#29275F]`}>
                        <div>
                            <p className={`cursor-pointer mb-4  ${selectedFilterParameter === 'role' ? 'font-bold' : 'font-normal'}`} onClick={() => setSelectedFilterParameter('role')} data-testid="role">Role</p>
                        </div>
                        <div>
                            <p className={`cursor-pointer pt-[5px] ${selectedFilterParameter === 'status' ? 'font-bold' : 'font-normal'}`} onClick={() => setSelectedFilterParameter('status')} data-testid="status" >Status</p>
                        </div>
                    </div>
                    <div className='w-full mt-[-5px] pb-1'>
                        {selectedFilterParameter === 'role' &&
                            <><div className='flex'>
                                <div className='checkbox relative'>
                                    <label htmlFor="adminCheckbox" className="pl-[33px] cursor-pointer text-[#2F2E38] text-base font-normal flex items-center p-2 mr-3 w-fit">
                                        Administrator
                                        <input
                                            className="checkbox-input custom-checkbox-input"
                                            type="checkbox"
                                            id="adminCheckbox"
                                            name="role"
                                            value="admin"
                                            data-testid='Administrator'
                                            checked={isCheckboxChecked('role', 'admin')}
                                            onChange={handleCheckboxChange} />
                                    </label>
                                </div>
                                <div className='checkbox relative'>
                                    <label htmlFor="userCheckbox" className="pl-[33px] cursor-pointer text-[#2F2E38] text-base font-normal flex items-center p-2 w-fit">
                                        User
                                        <input
                                            className="checkbox-input custom-checkbox-input"
                                            type="checkbox"
                                            id="userCheckbox"
                                            name="role"
                                            value="user"
                                            data-testid='User'
                                            checked={isCheckboxChecked('role', 'user')}
                                            onChange={handleCheckboxChange} />
                                    </label>
                                </div>

                            </div><div className='checkbox relative'>
                                <label htmlFor="superAdminCheckbox" className="pl-[33px] cursor-pointer text-[#2F2E38] text-base font-normal flex items-center p-2 w-fit">
                                    Super Admin
                                    <input
                                        className="checkbox-input custom-checkbox-input"
                                        type="checkbox"
                                        id="superAdminCheckbox"
                                        name="role"
                                        value="super-admin"
                                        data-testid='Super Admin'
                                        checked={isCheckboxChecked('role', 'super-admin')}
                                        onChange={handleCheckboxChange} />
                                </label>
                            </div></>}
                        {selectedFilterParameter === 'status' &&
                            <><div className='flex'>
                                <div className='checkbox relative'>
                                    <label htmlFor="activeCheckbox" className="pl-[33px] cursor-pointer text-[#2F2E38] text-base font-normal flex items-center p-2 mr-3 w-fit">
                                        Active
                                        <input
                                            className="checkbox-input custom-checkbox-input"
                                            type="checkbox"
                                            id="activeCheckbox"
                                            name="status"
                                            value="active"
                                            data-testid='Active'
                                            checked={isCheckboxChecked('status', 'active')}
                                            onChange={handleCheckboxChange} />
                                    </label>
                                </div>
                                <div className='checkbox relative'>
                                    <label htmlFor="inactiveCheckbox" className="pl-[33px] cursor-pointer text-[#2F2E38] text-base font-normal flex items-center p-2 w-fit">
                                        Inactive
                                        <input
                                            className="checkbox-input custom-checkbox-input"
                                            type="checkbox"
                                            id="inactiveCheckbox"
                                            name="status"
                                            value="inactive"
                                            data-testid='Inactive'
                                            checked={isCheckboxChecked('status', 'inactive')}
                                            onChange={handleCheckboxChange} />
                                    </label>
                                </div>
                            </div><div className="pl-[33px] cursor-pointer text-[#2F2E38] text-base font-normal flex items-center p-2 mr-3 w-fit mb-10"></div></>
                        }
                        <div className='flex mt-[15px]'>
                            <button type='button' data-testid="reset-btn"
                                className='py-2.5 px-5 border border-[#29275F] h-[37px]
                                        hover:border-[#4B42A3] text-base text-[#29275F] hover:text-[#4B42A3] 
                                        font-bold rounded-lg flex items-center w-[100px] mr-3.5 justify-center'
                                onClick={() => clearFilters()}>
                                Reset
                            </button>
                            <button
                                className='flex justify-center items-center py-2.5 px-5 w-[100px] h-[37px]
                    rounded-lg bg-[#29275F] text-base text-[#FCFBFE] font-bold hover:bg-[#4B42A3]' data-testid="apply-btn"
                                onClick={() => handleFilter()}>
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Userfilter;
