import { configureStore } from '@reduxjs/toolkit';
import authSlice from '../Pages/auth/authSlice';
import commonSlice from './commonSlice';
import { persistReducer,persistStore } from "redux-persist"
import storage from 'localforage'
import paginationReducer from './paginationSlice';
import privilegesReducer from './privilageSlice'
import payrollReducer from './payrollSlice'



const persistConfig = {
    key: 'root', // key for the storage
    storage, // storage engine to use
};
const persistedReducer = persistReducer(persistConfig, paginationReducer);

export const store = configureStore({
    reducer: {
        auth: authSlice,
        commonData: commonSlice,
        paginationConfig:persistedReducer,
        privileges: privilegesReducer,
        payrolls: payrollReducer,
    },
});

export const persistor = persistStore(store);