import { createSlice } from "@reduxjs/toolkit";

const payrollSlice = createSlice({
    name: 'payrolls',

    initialState: {
        privileges: {
            payrolls: []
        },
        isFetched: false,
    },
    
    reducers: {
        setPayroll: (state, action) => {
            {console.log('action', action)}
            state.privileges = {
                payrolls: action.payload.privileges.payrolls || []
            };
            state.isFetched = true;
        },
    },
});

export const { setPayroll } = payrollSlice.actions;
export default payrollSlice.reducer;