/* eslint-disable max-len */
import React, {useCallback, useState, useContext, useEffect} from 'react'
import DrawerSideBar from '../../../CommonComponent/DrawerSideBar'
import CommonDropdown from '../../../CommonComponent/CommonDropdown'
import { getValueTrim } from '../../../CommonComponent/InputValidation';
import { dataService } from '../../../services/dataService';
import Debounce from '../../../CommonComponent/debounce';
import InputWithLabel from '../../../CommonComponent/inputField';
import Button from '../../../CommonComponent/Button';
import { FelidValidation } from '../../../CommonComponent/FelidValidation';
import GlobalContext from '../../../CommonComponent/context';
import endPoints from '../../../services/endpoints';

export default function AddOrUpdateCurrencyConversion({formInput,payRollData,
    setFormInput, isSideModelOpen, setIsSideModelOpen, getUserList, isEdit, editData}) {
    const { setToastmessage,setToastErrorMessage } = useContext(GlobalContext)
    
    const InputItems = {
        symbol : {
            label : 'Symbol',
            required : true,
            disabled: true,
            maxLength: 3
        },
        currency_code: {
            label : 'Currency code',
            required : true,
            disabled : true,
            maxLength : 4
        },
        conversion_rate: {
            label : 'Conversion rate',
            required : true,
            maxLength: 13
        }

    }
    const [errorState, setErrorState] = useState({
        currency_name: "",
        symbol: '',
        currency_code: '',
        conversion_rate: '',
        period_text: ''
    });
    const [searchText, setSearchText] = useState('');
    const [loader, setLoader] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [currencyOptions, setCurrencyOptions] = useState({currency_name : [], period_text : []});
    const [loadingData, setLoadingData] = useState(true);
    const [isAPICalled, setIsAPICalled] = useState({currency_name : false, period_text : false});

    const [showDropdown, setShowDropdown] = useState(false);

    const [dropdownStates, setDropdownStates] = useState({
        period_text: false,
        currency_name: false,
    });
    // Handle search input change
    const handleSearchInputChange = (e) => {
        const value = e.target.value;
        setSearchText(value);
        setIsAPICalled({currency_name: false, period_text: false});
        debouncedFetchCurrencyList(e.target.id, value, payRollData?.payroll_id);
    };

    // Debounced fetch function
    const debouncedFetchCurrencyList = useCallback(
        Debounce((id, value, payrollID) => getCurrencyList('currency_name',payrollID, value, ), 300),
        []
    );

    const getCurrencyList = async (id,payrollID, value) => {
        setLoadingData(true);
        try {
            const res = await dataService.getAPI(
                `currency-management/${id === 'currency_name' ? 'currency-type' : 'pay-period'}/${payrollID ? payrollID : payRollData?.payroll_id}${value ? `?search=${encodeURIComponent(value)}` : ''}`
            );
            setCurrencyOptions((prevState) => ({
                ...prevState,
                [id]: res.data.data,
            }));
        } catch (error) {
            console.error("Error fetching currency list:", error);
        } finally {
            setLoadingData(false);
        }
    };
    useEffect(() => {
        if(searchText !== '') {
            return () => {
                debouncedFetchCurrencyList.cancel(); // Cleanup the debounce on unmount
            };
        }
    }, [debouncedFetchCurrencyList]);

    const handleInputClick = async(id) => {
        setSearchText('')
        if(!isAPICalled[id]) {
            setIsAPICalled((prevState) => ({
                ...prevState,
                [id]: true,
            }));
            getCurrencyList(id, payRollData?.payroll_id);
        }
        setDropdownStates((prev) => {
            const newDropdownStates = { ...prev };
            // Toggle the selected dropdown and close others
            Object.keys(newDropdownStates).forEach((key) => {
                newDropdownStates[key] = key === id ? !prev[id] : false;
            });
            return newDropdownStates;
        });
        setErrorState((prevState) => ({
            ...prevState,
            [id]: "",
        }));
    };
    const handleOptionClick = (option, id) => {

        // Update form input (with currency_id logic if id is 'currency')
        setFormInput(prevInput => ({
            ...prevInput,
            [id]: option.value || option.Name || option.currency_name, // Adjust based on your option structure
            ...(id === 'currency_name' && { currency_code: option.currency_code, symbol: option.symbol, currency_id: option.currency_id }), // Conditionally add currency_id if the id is 'currency'
            ...(id === 'period_text' && { period_text: option.period_text, pay_period_id: option.pay_period_id}) // Conditionally add tax_method_id if id is 'tax_method'
        }));
        if(id === 'currency_name') {
            setErrorState({
                currency_name: "",
                symbol: '',
                currency_code: '',
                conversion_rate: '',
                period_text: ''
            });
            setErrorMessage('')
        }

        // Close dropdown after selection
        setDropdownStates(prevStates => ({
            ...prevStates,
            [id]: false
        }));
    };
    const handleChange = (e) => {
        const { id, value } = e.target;
    
        // Check if the input field is 'conversion_rate'
        if (id === 'conversion_rate') {
            // Allow only numbers and decimals, and restrict before the decimal to 10 digits
            if (!/^\d{0,10}(\.\d{0,2})?$/.test(value)) return;
    
            // Update form input without stripping decimal when typing
            setFormInput(prevData => ({ 
                ...prevData, 
                [id]: value // Allow value with decimal points
            }));
        } else {
            // For other input fields, handle the value normally
            let formValue = getValueTrim(id, value);
            setFormInput(prevData => ({ 
                ...prevData, 
                [id]: formValue 
            }));
        }
    
        // Clear any previous error for this field
        setErrorState((prevState) => ({
            ...prevState,
            [id]: "",
        }));
    };
    
    
    const getButtonDisabled = () => {
        return (!loader && editData.conversion_rate !== formInput.conversion_rate) ? 
            false : true
    }
    const handleCloseModel = () => {
        setIsSideModelOpen(false)
        setFormInput({
            currency_name: "",
            symbol: '',
            currency_code: '',
            conversion_rate: '',
            period_text: payRollData.period_text,
            currency_id:'',
            pay_period_id: payRollData.pay_period_id
        })
        setErrorState({
            currency_name: "",
            symbol: '',
            currency_code: '',
            conversion_rate: '',
            period_text: ''
        })
    }
    const handleUpdateButton = async () => {
        setLoader(true)
        if(FelidValidation(['currency_name','symbol','currency_code','conversion_rate', 'period_text'], setErrorState, formInput)) {
            setLoader(false)
            return
        }
        try {
            const body = {
                "payroll_id": payRollData.payroll_id,
                "currency_id": formInput.currency_id,
                "conversion_rate": parseFloat(formInput.conversion_rate),
                "pay_period_id": formInput.pay_period_id
            }
            let res = {}
            if(isEdit) {
                res = await dataService.patchAPI(`${endPoints.CurrencyConversion}/${payRollData?.payroll_id}/${editData?.currency_conversion_id}`, 
                    {"conversion_rate": parseFloat(formInput.conversion_rate)})
            } else {
                res = await dataService.postAPI(`${endPoints.CurrencyConversion}`, body)

            }
            setLoader(false)
            if(isEdit) {
                getUserList();
                setToastmessage('Currency conversion updated successfully');
                handleCloseModel()
            } else {
                if(res.data.success_status) {
                    getUserList();
                    setToastmessage(res.data.message)
                    handleCloseModel()
                } else {
                    switch (res.data.message) {
                    case 'Currency conversion already exists':
                        setErrorState({
                            currency_name: "   ",
                            symbol: '    ',
                            currency_code: '    ',
                            conversion_rate: '   ',
                            period_text: '   '
                        })
                        setErrorMessage(res.data.message)
                        break;
                    case 'Conversion rate must be greater than 0.':
                        setErrorState((prevState) => ({
                            ...prevState,
                            'conversion_rate' : "Conversion rate must be greater than 0",
                        }));
                        break;
                    default:
                        setToastErrorMessage(res.data.message || 'Something went wrong.')
                        handleCloseModel()
                        break;
                    }
                }
            }
            
        } catch (error) {
            setLoader(false)
            handleCloseModel()
            setToastErrorMessage(error.message || 'Something went wrong.')
        }
    }
    
    return (
        <DrawerSideBar 
            tittle={`${isEdit ? 'Update' : 'Add'} Currency Conversion`} 
            isOpen={isSideModelOpen} 
            closeDrawerRight={handleCloseModel}
        >   
            {/* max-h-[calc(75vh-100px)] overflow-auto overflow-x-hidden customScrollbar */}
            <div className=''>
                <div className='w-[306px] mr-6'>
                    <CommonDropdown
                        id="currency_name"
                        label="Currency name"
                        type="text"
                        autocomplete="off"
                        required={true}
                        handleChange={handleChange}
                        formInput={formInput}
                        errorState={errorState}
                        options={currencyOptions.currency_name}
                        handleOptionClick={handleOptionClick}
                        showDropdown={showDropdown.currency_name}
                        setShowDropdown={setShowDropdown}
                        setDropdownStates={setDropdownStates}
                        handleInputClick={handleInputClick}
                        setSearchText={setSearchText}
                        displayKey={"currency_name"}
                        dropdownStates={dropdownStates}
                        handleSearchInputChange={handleSearchInputChange}
                        searchText={searchText}
                        searchbar={true}
                        dropdwonHeight = {"h-[199px]"}
                        loadingData={loadingData}
                        dropdownDisable={isEdit && isSideModelOpen}
                    />
                </div>
                {Object.keys(InputItems).map((item) => (
                    <div className='w-[309px] mr-6'key={item}>
                        <InputWithLabel
                            id={item}
                            label={InputItems[item].label}
                            type="text"
                            autocomplete="off"
                            required={InputItems[item].required}
                            // handlePasswordFocus={null}
                            handleChange={handleChange}
                            formInput={formInput}
                            errorState={errorState}
                            showPassword={true}
                            maxLength={InputItems[item].maxLength || 30}
                            inputDisable={InputItems[item].disabled}
                        />
                    </div>))}
                <div className='w-[306px] mr-6'>
                    <CommonDropdown
                        id="period_text"
                        label="Period"
                        type="text"
                        autocomplete="off"
                        required={true}
                        handleChange={handleChange}
                        formInput={formInput}
                        errorState={errorState}
                        options={currencyOptions.period_text}
                        handleOptionClick={handleOptionClick}
                        showDropdown={showDropdown.period_text}
                        setShowDropdown={setShowDropdown}
                        setDropdownStates={setDropdownStates}
                        handleInputClick={handleInputClick}
                        setSearchText={setSearchText}
                        displayKey={"period_text"}
                        dropdownStates={dropdownStates}
                        handleSearchInputChange={handleSearchInputChange}
                        searchText={searchText}
                        searchbar={false}
                        dropdwonHeight = {"h-[199px]"}
                        loadingData={loadingData}
                        lastElement
                        dropdownDataTestId={'dropdown-period-text-model'}
                        dropdownDisable={isEdit && isSideModelOpen}
                    />
                </div>
                <p className="text-[#EA3829] text-xs font-normal py-4">
                    {errorMessage}
                </p>
            </div>
            <Button
                dataTestID={isEdit ? 'Update' : 'Add'}
                disabled={getButtonDisabled()}
                handleButtonClick={handleUpdateButton}
                ButtonName={isEdit? 'Update' : 'Add'}
                loader={loader}
            />
        </DrawerSideBar>
    )
}
