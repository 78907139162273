import React from 'react';
import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';
import blockImage from '../../../CommonComponent/search_not_found.json'
import { motion } from 'framer-motion';
import Loader from '../../../CommonComponent/Loader';

export default function EmptyScreen({
    navigateurl
}) {
    const navigate = useNavigate()

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{
                opacity: 1,
            }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            className='flex flex-col items-center justify-start pt-[38px]'>
            <img src='/Images/recordNotFound.svg' alt='icon' loading='lazy' />
            <p className='text-[#2F2E38] text-[18px] font-semibold pb-1 pt-[30px]'>
                Sorry, there are no users available at the moment.</p>
            <p className='text-[#2F2E38] text-[18px] font-semibold pb-6'>
                To include a new user, click the button below.</p>
            <button type='button' onClick={() => navigate(navigateurl)}
                className='bg-[#29275F] rounded-lg px-5 py-2.5 text-[#FCFBFE] 
                text-base font-bold w-[110px] hover:bg-[#4B42A3] disabled:bg-[#CCC9E9] flex justify-center items-center'
                data-testid="add-btn">
                <img src='/Images/plus-add-icon.svg' alt='icon' className='mr-2.5' loading='lazy'/>
                Add</button>
        </motion.div>
    );
}
export function NoReseultFound({ searchValue }) {
    return (
        <div className="error-message">
            <Lottie
                animationData={blockImage}
                loop={true}
                className="w-[240px] mx-auto mt-8"
            />
            <p data-testid="empty_screen" className='text-[18px] font-semibold text-[#2F2E38] text-center pb-5 pt-12'>
                {searchValue !== '' &&
                    'We could not find any matches for your search.'}</p>
        </div>
    )
}

export function NoReseultFoundSmallScreen({ searchValue, searchLoader, setLoader, noRecord }) {
    const navigate = useNavigate()

    return (
        <>
            {searchLoader ? (
                <Loader
                    height={'h-[calc(100vh-555px)]'}/>
            ) : noRecord ? (
                <div className="error-message h-[calc(100vh - 556px)]">
                    <div className='flex flex-col items-center justify-start pt-[38px]'>
                        <p className='text-[#2F2E38] text-[18px] font-semibold pb-1 pt-[30px]'>
                            Sorry, there are no user groups available at the moment.</p>
                        <p className='text-[#2F2E38] text-[18px] font-semibold pb-6'>
                            To include a new user group, click the button below.</p>
                        <button type='button' onClick={() => navigate("/user-group/add")}
                            className='bg-[#29275F] rounded-lg px-5 py-2.5 text-[#FCFBFE] 
                                text-base font-bold w-auto hover:bg-[#4B42A3] disabled:bg-[#CCC9E9] 
                                flex justify-center items-center'
                            data-testid="add-btn">
                            <img src='/Images/plus-add-icon.svg' alt='icon' className='mr-2.5' loading='lazy'/>
                            Add </button>
                    </div>
                </div>
            ) : (
                <div className="error-message h-[calc(100vh - 556px)]">
                    <Lottie
                        animationData={blockImage}
                        loop={true}
                        className="2xl:h-auto h-[18vh] w-[240px] mx-auto"
                    />
                    <p
                        data-testid="empty_screen"
                        className="text-[18px] font-semibold text-[#2F2E38] text-center pb-5 pt-2"
                    >
                        {searchValue !== '' && 'We could not find any matches for your search.'}
                    </p>
                </div>)}
        </>
    );
}

