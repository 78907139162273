/* eslint-disable max-len */
import React from 'react';

export default function ViewItemMapping({ obj, mainClassName, loading }) {
    const renderArray = (item, array) => (
        <>
            <p className="text-[#2F2E38] text-base font-normal mb-1.5">{item || ''}</p>
            <div className="flex flex-wrap">
                {array.map((subItem, index) => (
                    <div className="w-1/3 mb-1.5" key={index}>
                        <p className="text-base font-semibold text-[#2F2E38] break-all" data-test-id={subItem}>
                            {subItem || ''}
                        </p>
                    </div>
                ))}
            </div>
        </>
    );

    const renderString = (item, value) => (
        <div className="mr-6">

            <p className="text-[#2F2E38] text-base font-normal mb-2">{item || ''}</p>
            {loading ? (
                <p className="bg-[#dbd9d9f3] h-[27px] animate-pulse w-[250px] rounded-md"></p>
            ) : (
                <p className="text-base font-semibold text-[#2F2E38] break-all" data-test-id={item}>{value || ''}</p>
            )}
        </div>
    );

    const renderNestedObject = (item, nestedObj) => (
        <>
            {item!="null" &&  <p className="text-[#2F2E38] text-base font-normal mb-2">{item}</p> }
            {Object.keys(nestedObj).map((subItem, index) => (
                <div className="bg-[#E5E4E8] p-3 justify-items-center rounded-md mb-3" key={index}>
                    <div  className={index === 0 ? '' : ""}>
                        <p className={`text-[#2F2E38] text-base font-normal ${subItem == "Employee Master" ? 'pt-[2px]' :"mb-2 "}`}>
                            {subItem}</p>
                        {Array.isArray(nestedObj[subItem]) && (
                            <div className="flex flex-wrap">
                                {nestedObj[subItem].map((subArrayItem, subIndex) => (
                                    <div className="w-1/3 mb-1.5" key={subIndex}>
                                        <p className="text-base font-semibold text-[#2F2E38] break-all" 
                                            data-test-id={subArrayItem}>
                                            {subArrayItem == "Nec grades" ?"NEC grades" : subArrayItem || ''}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

            ))}
        </>
    );

    return (
        <div className="bg-[#F1F1F4] p-3 rounded-md">
            <div className={`w-full ${mainClassName || ''}`}>
                {Array.isArray(obj) ? 
                    renderArray('', obj)
                    :
                    Object.keys(obj).map((item) => (
                        <div  key={item} 
                            className="w-full">
                            {Array.isArray(obj[item]) && renderArray(item, obj[item])}
                            {typeof obj[item] === 'string' && renderString(item, obj[item])}
                            {typeof obj[item] === 'object' && obj[item] !== null && !Array.isArray(obj[item]) &&
                            renderNestedObject(item, obj[item])}
                        </div>
                    ))}
            </div>
        </div>
    );
}
